import React, { useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import DOMPurify from "dompurify";
import { ClickButtonCDP } from "../../utils/cdp/cdpHelper";
import useAuth from "../../utils/hooks/use-auth";
import useTranslation from "../../utils/hooks/use-translation";
import Surprise_me_modal from "../modals/surprise_me_modal";
import SuccessModel from "../../components/modals/api_response_model";
import useLoader from "../../utils/hooks/use-loader";
import { getHomePopupInfo, getJokesByArtist } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { setJokeData } from "../../store/slices/jokedata-slice";
import {
  displayJokeOfTheDay,
  displaySuprise,
  displayWelcome,
} from "../../store/slices/suprise-me";
import { setGenereID } from "../../store/slices/common-slice";
import { useLocation } from "react-router-dom";
import {
  setContestPoolPrize,
  setJokeFeaturedPopup,
  setReferralDone,
  setShowRewardModal,
} from "../../store/slices/home-popup";
import { useViewport } from "../../utils/context/viewport-context";
import { MetaPixel, TTDPixel } from "../../utils/helpers";

const SupriseMe = () => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuth();
  const { toggleLoader } = useLoader();
  const location = useLocation();
  const dispatch = useDispatch();
  const [welcomeMessage, setWelcomeMessage] = useState(false);
  const [reactToJokePopup, setReactToJokePopup] = useState(false);
  const surpriseMe = useSelector((state) => state?.supriseMe?.supriseMe);
  const jokeOfTheDay = useSelector((state) => state?.supriseMe?.jokeOfTheDay);
  const currentLanguageID = useSelector(
    (state) => state.common.currentLanguageID
  );
  const tourGuideShow = useSelector((state) => state.common.tourGuide);

  const { isMobile } = useViewport();

  // useEffect(() => {
  //   // const welcome = localStorage.getItem("welcome");

  //   // if (!welcome) {
  //   //   setWelcomeMessage(true);
  //   //   // localStorage.setItem("welcome", "welcome");
  //   // }
  // }, []);

  const surpriseMeClickHandle = () => {
    TTDPixel("bqpkfp5");
    MetaPixel(`Mood_Surpriseme`);
    dispatch(displayJokeOfTheDay(false));
    dispatch(displaySuprise(!surpriseMe));
  };

  const getNotifyPopup = () => {
    if (isLoggedIn) {
      try {
        getHomePopupInfo(currentLanguageID || "1").then((res) => {
          if (res?.data?.data) {
            if (
              res?.data?.data?.length > 0 &&
              res?.data?.data?.[0].ugc?.length > 0
            ) {
              dispatch(setJokeFeaturedPopup(true));
            }
            if (
              res?.data?.data?.length > 0 &&
              res?.data?.data?.[0].referralsAccepted?.length > 0
            ) {
              dispatch(setReferralDone(true));
            }
            if (
              res?.data?.data?.length > 0 &&
              res?.data?.data?.[0].rewards?.length > 0 &&
              res?.data?.data?.[0].rewards[0]?.contestRewardsPools.length > 0
            ) {
              dispatch(setShowRewardModal(true));
              dispatch(
                setContestPoolPrize(
                  res?.data?.data?.[0].rewards[0]?.contestRewardsPools[0]
                )
              );
            }
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const closeSurpriseMe = () => {
    dispatch(displaySuprise(false));
    dispatch(setGenereID(0));
    fetchArtistJokes();
    if (jokeOfTheDay) {
      dispatch(displayJokeOfTheDay(false));
      if (isMobile) {
        setTimeout(() => {
          getNotifyPopup();
        }, 18000);
      } else {
        setTimeout(() => {
          getNotifyPopup();
        }, 14000);
      }
    }
    if (tourGuideShow.showTourGuide && isLoggedIn) {
      dispatch(displayWelcome(true));
      // localStorage.setItem("welcome", "complete");
    }
    if (!localStorage.getItem("visitTime") && isLoggedIn) {
      let currentTime = new Date(Date.now());
      localStorage.setItem(
        "visitTime",
        new Date(currentTime.getTime() + 24 * 60 * 60 * 1000)
      );
    }
    // Reset the Surprise Me button state
    setReactToJokePopup(false);
  };

  const notExitPopup = () => {
    setReactToJokePopup(false);
    // dispatch(displaySuprise(true));
  };

  const contentType = {
    image: "/assets/img/modal/mood-icon.svg",
    heading: t.model_messages.did_that_make_you_heading,
    subHeading: t.model_messages.forget_to_react_subheading,
    alertDescription: t.model_messages.want_to_exit,
    ctaYes: t.model_messages.yes,
    ctaNo: t.model_messages.no,
    // description: "Your points will be credited on successfull signup using the invite code",
    id: 20,
  };
  const fetchArtistJokes = async () => {
    if (isLoggedIn) {
      toggleLoader(true);
      try {
        const resTwo = await getJokesByArtist();
        if (resTwo.status === 200) {
          dispatch(setJokeData(resTwo?.data?.data?.jokesServed));
        }
      } catch (err) {
      } finally {
        toggleLoader(false);
      }
    }
  };

  return (
    <Container>
      {!["/ugc_upload", "/my_profile/1", "/notifications"].includes(
        location.pathname
      ) && !location.pathname.includes("claim_form") ? (
        <div className="btn-suprise  ">
          <Button
            variant="warning"
            size="sm"
            id="SpriteSurpriseMe"
            className="d-flex align-items-center justify-content-center SpriteSurpriseMe"
            onClick={surpriseMeClickHandle}
            // Disable the button if surpriseMe is true
            disabled={surpriseMe}
          >
            <div>
              <img
                src="/assets/img/animation/surprise-me.gif"
                alt="icon"
                className="surprise-img"
              />
            </div>

            <div
              className="pl-1 text-primary text"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(t?.common?.surprise_me),
              }}
            ></div>
          </Button>
        </div>
      ) : (
        <></>
      )}
      {surpriseMe ? (
        <Surprise_me_modal
          show={surpriseMe}
          surpriseLogo="/assets/img/modal/surprise-me-logo.svg"
          jokeLogo="/assets/img/modal/joke-of-the-day.svg"
          image="/assets/img/modal/surpriseme_mobile.png"
          iconType="/assets/img/icons/video-icon.svg"
          icon="/assets/img/modal/lock.svg"
          welcomeMessage={welcomeMessage}
          isJokeOfTheDay={jokeOfTheDay}
          footercontent1="Hello there!  You’ve entered Sprite® Joke In A "
          footercontent2="Bottle. Find your LOL-ter ego here!🤓"
          isLoggedIn={isLoggedIn}
          onClose={closeSurpriseMe}
          setReactToJokePopup={setReactToJokePopup}
          // showAnimation={true}
        />
      ) : null}
      {reactToJokePopup && (
        <SuccessModel
          show={reactToJokePopup}
          {...contentType}
          onClose={() => setReactToJokePopup(false)}
          notExitPopup={notExitPopup}
          yesExitPop={() => {
            setReactToJokePopup(false);
            closeSurpriseMe();
          }}
        />
      )}
    </Container>
  );
};

export default SupriseMe;
