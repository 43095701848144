import React from "react";
import AppModal from "./index";
import useTranslation from "../../utils/hooks/use-translation";
import { useSelector, useDispatch } from "react-redux";
import { displayContactUs } from "../../store/slices/pop-up.js";

const ContactUsModal = () => {
  const { t } = useTranslation();
  const contactUs = useSelector((state) => state.popUp.contactUs);
  const dispatch = useDispatch();
  return (
    <AppModal
      show={contactUs}
      onClose={() => dispatch(displayContactUs(!contactUs))}
      bodyClass="px-6 pt-2 pb-5"
      size=""
    >
      <div className="text-center">
        <h4 className="modal-heading pb-md-3 pb-2">{t.header.contact_us}</h4>
        <img
          src="/assets/img/modal/email.svg"
          alt="Icon"
          className="modal-icon mb-md-4 mb-2"
        />
        <h4 className="modal-heading pb-md-3 pb-2">{t.header.email_us}</h4>
        <p className=" pb-md-3 pb-2 modal-description">
          <a
            href="mailto:indiahelpline@coca-cola.com"
            className=" pb-md-3 pb-2 text-dark"
          >
            indiahelpline@coca-cola.com
          </a>
        </p>
        <img
          src="/assets/img/modal/contact.svg"
          alt="Icon"
          className="modal-icon mb-md-4 mb-2"
        />
        <h6 className="modal-heading  pb-md-3 pb-2">{t.header.call_us}</h6>
        <p className="modal-description mb-0">
          <a
            href="tel:18002082653"
            target="_blank"
            rel="noreferrer"
            className="modal-description text-dark"
          >
            1800 208 2653
          </a>
        </p>
        <p className="modal-description mb-0">9:00am-9:00pm (Mon-Sat)</p>
        <p className="modal-description mb-0">9:00am-6:00pm (Sun)</p>
      </div>
    </AppModal>
  );
};

export default ContactUsModal;
