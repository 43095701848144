import React from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import NavLinks from "./nav-links";
import useAuth from "../../utils/hooks/use-auth";
import { logoutUser } from "../../store/slices/auth-slice";
import { useDispatch, useSelector } from "react-redux";
import { menuData, menuData2 } from "../../utils/constants";
import useTranslation from "../../utils/hooks/use-translation";
import { setRedirectUrl } from "../../store/slices/redirection-slice";
import { CounterFloodLight, MetaPixel, TTDPixel } from "../../utils/helpers";
import { setAssuredWinsData } from "../../store/slices/common-slice";

const Sidebar = (props) => {
  const { t } = useTranslation();
  let allMenuOptions = menuData(t);
  let menuDataOptions = menuData2(t);
  const { navigateTo } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn, userDetails } = useAuth();
  const homePageData = useSelector((state) => state?.common?.homepageDetails);

  const windowLayerPush = () => {
    window.dataLayer = window.dataLayer || [];

    let indexToUpdate = -1;
    for (let i = 0; i < window.dataLayer.length; i++) {
      if (window.dataLayer[i].loginEvent) {
        indexToUpdate = i;
        break;
      }
    }

    if (indexToUpdate !== -1) {
      // Update the existing shareContent entry
      window.dataLayer[indexToUpdate].loginEvent = {
        loggedInEvent: false,
      };
    } else {
      // Add a new shareContent entry
      window.dataLayer.push({
        loginEvent: {
          loggedInEvent: false,
        },
      });
    }
  };


  const logoutUserAction = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("token");
    localStorage.removeItem("persist:root");
    dispatch(logoutUser());
    windowLayerPush();
    dispatch(setAssuredWinsData([]));
    navigate("/");
    TTDPixel("3z343xi");
  };

  const redirectToMyProfile = () => {
    dispatch(setRedirectUrl("/my_profile"));
    MetaPixel("Login");
    CounterFloodLight("DC-12665261/sprit0/sprit01a+unique");
    navigateTo("/login");
  };

  return (
    <>
      <div className="w-100">
        <Button
          size="lg"
          style={{ borderRadius: "1rem" }}
          className="btn-icon btn-block"
          onClick={() =>
            isLoggedIn ? navigateTo("/my_profile") : redirectToMyProfile()
          }
        >
          {isLoggedIn ? (
            <>
              <div className="user-card">
                <img
                  className="user-pic"
                  src={
                    userDetails && userDetails?.avatarURL
                      ? userDetails?.avatarURL
                      : "/assets/img/icons/profile-image.svg"
                  }
                  loading="lazy"
                  alt="avatar"
                />
                <div className="user-details">
                  <h6 className="user-title">
                    {(userDetails && userDetails?.name) ||
                      userDetails?.phoneNumber}
                  </h6>
                  <div className="d-flex align-items-center coin-wrapper">
                <h3 className="pr-1">{homePageData?.coinCount}</h3>
                <picture>
                        <source
                          srcset="/assets/img/contest/comic_coin@1x.webp 1x, /assets/img/contest/comic_coin@2x.webp 2x"
                          type="image/webp"
                        />

                        <img
                          src="/assets/img/contest/comic_coin@1x.png"
                          srcset="/assets/img/contest/comic_coin@1x.png 1x, /assets/img/contest/comic_coin@2x.png 2x"
                          alt="unique code"
                          className="mr-1"
                          width={25}
                          height={25}
                        />
                      </picture>
              </div>
                </div>
              </div>
              <span className="icon icon-right-arrow icon-Right-arrow-01"></span>
            </>
          ) : (
            <>
              <span>{t.header.login}</span>
              <span className="icon icon-right-arrow icon-Right-arrow-01"></span>
            </>
          )}
        </Button>
        <NavLinks data={allMenuOptions} navigateTo={navigateTo} />
      </div>

      <div className="wp-wrapper d-flex justify-content-between align-items-center w-100">
        <span className="text-dark">{t.common.text_me_maybe}</span>

        <Button
          onClick={(e) => {
            MetaPixel("Chat");
            CounterFloodLight("DC-12665261/sprit0/sprit01o+unique");
            e.preventDefault();
            window.open(
              `https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_WP_NUM}&text=Hi,+I+am+here+for+Sprite+Joke-In-A-Bottle!+By+pressing+Send+on+this,+I+give+my+consent+to+sharing+my+mobile+number+and+display+name+with+Sprite.`,
              "_blank" // Opens the link in a new tab
            );
          }}
          variant="primary"
          size="sm"
        >
          <img src="/assets/img/icons/whatsapp.svg" alt="whatsapp-icon" className="mr-1" />
          Chat
        </Button>
      </div>
      <div className="w-100">
        <NavLinks data={menuDataOptions} navigateTo={navigateTo} />
        {isLoggedIn && (
          <Link
            to=""
            className="btn-link btn-link-lg d-inline-block text-primary mt-12"
            onClick={() => logoutUserAction()}
          >
            {t.header.logout}
          </Link>
        )}
      </div>
    </>
  );
};

export default Sidebar;
