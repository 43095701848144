import { createSlice } from "@reduxjs/toolkit";

// Define the initial state for the authentication slice
const initialState = {
  redirectionUrl: null,
};

// Create the authentication slice using `createSlice`
export const redirectSlice = createSlice({
  name: "redirect", // Name of the slice
  initialState,
  reducers: {
    setRedirectUrl: (state, actions) => {
      state.redirectionUrl = actions.payload;
    },
  },
});

// Extract the action creators generated by `createSlice`
export const { setRedirectUrl } = redirectSlice.actions;

// Export the reducer function generated by `createSlice`
export default redirectSlice.reducer;
