import React from "react";
import { Card } from "react-bootstrap";
import { useViewport } from "../../utils/context/viewport-context";

const RewardShareCard = ({imgUrl, title, description,...props}) => {
  const {
    cardClassName = "",
  } = props;

  return (
    <div className="reward-share  col-12 mx-auto">
      <Card className={`${cardClassName} card-reward card-share  `}>
        <div className={`card-top-img-wrapper position-relative`}>
          <div className="d-flex justify-content-end align-items-start w-100">
            <Card.Img src={imgUrl||'/assets/img/reward-share.png'} />
          </div>
        </div>
        <Card.Body className="d-flex justify-content-between flex-column">
          <div>
            <Card.Title as={"h4"}>{title || 'Can’t keep Thand!'}</Card.Title>
            <Card.Text className={`pb-3 pb-md-6`}>
              {description || `Just won a cool vacay with this voucher worth Rs.50000! You can
              win too, grab a Sprite® & participate in the Joke-in-a-Bottle
              promotion!`}
            </Card.Text>
          </div>
          <a href="/terms_conditions" className="link">
            *T&C Apply
          </a>
        </Card.Body>
      </Card>
    </div>
  );
};

export default RewardShareCard;
