import React from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import useTranslation from "../../utils/hooks/use-translation";
import usePopup from "../../utils/hooks/use-popup";

const ContestUniqueCode = (props) => {
  const { t } = useTranslation();
  const { openUniqueCodePopup } = usePopup();
  const handleUniqueCodeClick = (e) => {
    openUniqueCodePopup();
  };
  return (
    <>
      {/* <div className="tour-code left-wrapper"> */}
      <div className="contest-banner  mb-3 mt-1 d-flex tour-code">
        <div>
          <picture>
            <source
              srcset="/assets/img/animation/bottle@1x.webp 1x, /assets/img/animation/bottle@2x.webp 2x"
              type="image/webp"
            />

            <img
              src="/assets/img/animation/bottle@1x.jpg"
              srcset="/assets/img/animation/bottle@1x.jpg 1x, /assets/img/animation/bottle@2x.jpg 2x"
              alt="unique code"
              className="contest-bottle"
            />
          </picture>
        </div>
        <div className=" d-flex flex-column align-items-center justify-content-center">
          <h3
            className="text-uppercase text-warning my-1 text-center"
            dangerouslySetInnerHTML={{
              __html: t.common.drink_more_collect_more,
            }}
          ></h3>
          <Button
            className="btn-sm mb-1 mb-md-4"
            variant="primary"
            onClick={handleUniqueCodeClick}
          >
            {t.contest.enter_unique_code}
          </Button>
          <p
            className="text-light text-center contest-para mb-0"
            dangerouslySetInnerHTML={{
              __html: t.common.get_comic_coin_every_time,
            }}
          />
        </div>
      </div>
      {/* <div className=" d-flex flex-column align-items-center justify-content-center">
        <h3
          className="text-uppercase text-warning my-1 text-center"
          dangerouslySetInnerHTML={{ __html: t.common.drink_more_collect_more }}
        ></h3>
        <Button
          className="btn-sm mb-1 mb-md-4 btn-unique-code"
          variant="primary"
          onClick={handleUniqueCodeClick}
        >
          {t.contest.enter_unique_code}
        </Button>
        <p
          className="text-light text-center contest-para mb-0"
          dangerouslySetInnerHTML={{
            __html: t.common.get_comic_coin_every_time,
          }}
        />
      </div> */}
    </>
  );
};

export default ContestUniqueCode;
