import React, { useState } from "react";
import { Link } from "react-router-dom";
import VideoPlayer from "../common/VedioPlayer";
import AppModal from "../modals";
import useAuth from "../../utils/hooks/use-auth";
import { useNavigate } from "react-router-dom";
import { likeVedioById } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import useLoader from "../../utils/hooks/use-loader";
import { getAllHomepageDetailsALogin } from "../../utils/axios-helper/api-hander";
import { setPlayVedio } from "../../store/slices/common-slice";
import SuccessModel from "../modals/api_response_model";
import useTranslation from "../../utils/hooks/use-translation";
import { IMAGE_BASE_URL } from "../../config";
import { useViewport } from "../../utils/context/viewport-context";

const VedioCard = (props) => {
  const {
    image = "",
    altText = "",
    likes,
    views,
    title,
    showVoteButton,
    subTitle,
    imgClass = "",
    blockClass = "",
    iconClass = "",
    videoRef,
    index,
    setSelectedIndex,
    videoURL = "",
    actionOnTop = true,
    fullWidthVideo = false,
    actions = false,
    isCoinWinFeatureAllowed,
    id,
    isExclusive = false,
    vedioType,
    gaClass = "",
    videoLock = false,
    iconBlockTop = "",
    videoId = "",
    activeEmoji = "",
    setActiveEmoji = "",
    viewsCount = 0,
    isDetailPage = false,
    setCanReact,
    viewCountHandler,
    data = [],
    reactionsState = "",
    setReactionsState = "",
    handleAudioPause = () => {},
    handleProfileReaction = () => {},
    imageType = "",
    isVideo = false,
    isAutoPlay = true,
    controls = true,
    muted = false,
  } = props;

  const { isLoggedIn } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toggleLoader } = useLoader();
  const playVedio = useSelector((state) => state.common.playVedio);
  const currentLanguageID = useSelector(
    (state) => state.common.currentLanguageID
  );
  const { isMobile } = useViewport();
  let imagebaseURL = isMobile
    ? `${IMAGE_BASE_URL}/${imageType}/Mobile/`
    : `${IMAGE_BASE_URL}/${imageType}/Desktop/`;

  const fullVideoURL = imageType ? `${imagebaseURL}${videoURL}` : videoURL;

  const defaultVedio =
    "https://017526-content-prod.s3.ap-south-1.amazonaws.com/DefaultVideo/5-+Hindi_Pollution+2.mp4";

  // const [playVedio, setPlayVedio] = useState(false);
  const [opnModel, setShowModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isReacted, setIsReacted] = useState(false);
  const [reactToJokePopup, setReactToJokePopup] = useState(false);
  const uniqueId = `${index}${vedioType}`;
  // const showVedioBlock = playVedio?.showVedio && playVedio.id === uniqueId;
  const [showVedioBlock, SetShowVedioBlock] = useState(false);
  const animationShow = useSelector((state) => state.common.animationShow);

  //handle play vedio
  const handleCardClick = () => {
    if (isExclusive && !isLoggedIn) {
      navigate("/login");
    } else if (vedioType !== "coke_studio_treasures") {
      if (isVideo) {
        SetShowVedioBlock(true);
      } else {
        setShowModel(true);
      }
      handleAudioPause();
      dispatch(setPlayVedio({ showVedio: false, id: uniqueId }));
      if (isDetailPage) {
        setCanReact(true);
      }
    } else {
      dispatch(setPlayVedio({ showVedio: true, id: uniqueId }));
      setSelectedIndex(uniqueId);
    }
  };

  //get all home page after login
  const likeunLikeVedioById = async () => {
    if (isLoggedIn) {
      try {
        let reqData = { isLiked: true, id: id, hfSession: "" };
        const res = await likeVedioById(reqData);
        if (res.status === 200 && res.data.succeeded) {
          getAllHomepageDetailsALogin(
            dispatch,
            toggleLoader,
            currentLanguageID
          );
        }
      } catch (err) {}
    } else {
      navigate("/login");
    }
  };

  //render image block
  const renderImageBlock = () => {
    return (
      <>
        <div
          className={
            playVedio?.showVedio && playVedio.id === uniqueId
              ? "img-block"
              : `img-block  ${blockClass}`
          }
        >
          {image ? (
            <img className={`img-card ${imgClass}`} src={image} alt={altText} />
          ) : (
            ""
          )}
          <Link to="#" onClick={handleCardClick}>
            <img
              className={`icon icon-play`}
              src={"/assets/img/icons/play.svg"}
              alt="Play Button"
            />
          </Link>

          {videoLock && actionOnTop ? (
            <div
              className={`icon-block icon-block-top ${iconClass} ${iconBlockTop}`}
            >
              {likes && (
                <Link
                  to="#"
                  className="icon icon-like icon-Lock"
                  onClick={() => likeunLikeVedioById()}
                ></Link>
              )}
            </div>
          ) : (
            ""
          )}

          {(likes || views) && actionOnTop && (
            <div className={`icon-block ${iconClass}`}>
              {likes && (
                <Link
                  to="#"
                  className="icon icon-like icon-like-01"
                  onClick={() => likeunLikeVedioById()}
                >
                  <span className="icon-num">{likes}</span>
                </Link>
              )}
              {views && (
                <Link to="#" className="icon icon-view icon-views-01 d-none">
                  <span className="icon-num">{views}</span>
                </Link>
              )}
            </div>
          )}
        </div>
        {actions && (
          <div className="list-actions">
            <Link>
              <span className="icon icon-like-01"></span>
              <span className="count">2</span>
            </Link>
            <Link>
              <span className="icon icon-share-01"></span>
              <span className="count">3</span>
            </Link>
            <Link>
              <span className="icon icon-views-01"></span>
              <span className="count">4</span>
            </Link>
          </div>
        )}
        {(title || subTitle) && (
          <div className="card-body">
            {title && <h5 className="card-title">{title}</h5>}
            {subTitle && <h6 className="card-text">{subTitle}</h6>}
          </div>
        )}
      </>
    );
  };

  //Load vedio
  const LoaderContent = () => {
    return (
      <div className="loader-container">
        <div className="loader-content">
          <div className="loading-text">Loading...</div>
          <div className="spinner"></div>
        </div>
      </div>
    );
  };

  //render vedio play block
  const renderVedioBlock = () => {
    return (
      <div
        className={
          // playVedio?.showVedio && playVedio.id === uniqueId
          isVideo
            ? `img-block ${showVedioBlock ? gaClass : ""}`
            : `img-block ${blockClass} ${showVedioBlock ? gaClass : ""}`
        }
      >
        {loading && <LoaderContent />}
        <VideoPlayer
          videoURL={videoURL ? fullVideoURL : defaultVedio}
          videoRef={videoRef}
          isCoinWinFeatureAllowed={isCoinWinFeatureAllowed}
          id={id}
          fullWidthVideo={fullWidthVideo}
          // onReady={handleVideoLoad}
          gaClass={gaClass}
          // reactions={reactions}
          height="auto"
          width="100%"
          isAutoPlay={isAutoPlay}
          videoId={videoId}
          activeEmoji={activeEmoji}
          showVoteButton={showVoteButton}
          setActiveEmoji={setActiveEmoji}
          viewsCount={viewsCount}
          isDetailPage={isDetailPage}
          reactionsState={reactionsState}
          setReactionsState={setReactionsState}
          viewCountHandler={viewCountHandler}
          animationShow={animationShow}
          handleProfileReaction={handleProfileReaction}
          controls={controls}
          muted={muted}
        />
      </div>
    );
  };

  const vedioModelClose = () => {
    if (!isReacted) {
      setReactToJokePopup(true);
      setShowModel(true);
    } else {
      dispatch(setPlayVedio({ showVedio: false, id: "" }));
      setCanReact(true);
      setShowModel(false);
    }
  };

  //show selected vedio in model
  const ShowVedioInModel = () => {
    return (
      <AppModal
        show={opnModel}
        onClose={vedioModelClose}
        bodyClass=""
        headerClass={""}
        dialogClassName="modal-video-wrapper"
        contentClassName={""}
        size={"lg"}
        gaClass={gaClass}
        animationShow={animationShow}
      >
        {loading && <LoaderContent />}
        <VideoPlayer
          videoURL={videoURL ? videoURL : defaultVedio}
          videoRef={videoRef}
          isCoinWinFeatureAllowed={isCoinWinFeatureAllowed}
          id={id}
          gaClass={gaClass}
          // reactions={reactions}
          videoId={videoId}
          activeEmoji={activeEmoji}
          setActiveEmoji={setActiveEmoji}
          viewsCount={viewsCount}
          isDetailPage={isDetailPage}
          data={data}
          isLoggedIn={isLoggedIn}
          reactionsState={reactionsState}
          setReactionsState={setReactionsState}
          animationShow={animationShow}
          showVoteButton={showVoteButton}
          viewCountHandler={viewCountHandler}
          setIsReacted={setIsReacted}
          handleProfileReaction={handleProfileReaction}
          // onReady={handleVideoLoad}
        />
      </AppModal>
    );
  };

  const contentType = {
    image: "/assets/img/modal/mood-icon.svg",
    heading: t.model_messages.did_that_make_you_heading,
    subHeading: t.model_messages.forget_to_react_subheading,
    alertDescription: t.model_messages.want_to_exit,
    ctaYes: t.model_messages.yes,
    ctaNo: t.model_messages.no,
    // description: "Your points will be credited on successfull signup using the invite code",
    id: 20,
  };

  const notExitPopup = () => {
    setReactToJokePopup(false);
    // dispatch(displaySuprise(true));
  };
  const renderContent = () => {
    if (opnModel) {
      return ShowVedioInModel();
    } else {
      return isVideo ? renderVedioBlock() : renderImageBlock();
    }
  };

  return (
    <>
      {renderContent()}
      {reactToJokePopup && (
        <SuccessModel
          show={reactToJokePopup}
          {...contentType}
          onClose={() => setReactToJokePopup(false)}
          notExitPopup={notExitPopup}
          yesExitPop={() => {
            setReactToJokePopup(false);
            setShowModel(false);
          }}
        />
      )}
    </>
  );
};

export default VedioCard;
