import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setGenereID } from "../../store/slices/common-slice";
import { useLocation } from "react-router-dom";
import { displaySuprise } from "../../store/slices/suprise-me";
import { MetaPixel, TTDPixel, CounterFloodLight } from "../../utils/helpers";
import { ClickButtonCDP } from "../../utils/cdp/cdpHelper";

function TickleCard(props) {
  const {
    cardImage,
    enTitle,
    otherLangTitle,
    cardAlt,
    genereID,
    wrapperClassName,
    handleCategorySelect = "",
  } = props;
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const common = useSelector((state) => state.common);
  const [cardName, setCardName] = useState();

  const TTDapplyFn = (genre) => {
    let nameKey = "";
    if (genre === "Observation") {
      nameKey = "fzlqf1n";
    } else if (genre === "Adulting") {
      nameKey = "rkvjzgs";
    } else if (genre === "Relationships") {
      nameKey = "3ygbn9x";
    } else if (genre === "Self") {
      nameKey = "szg1bh0";
    } else if (genre === "Childhood") {
      nameKey = "w44rrox";
    } else if (genre === "Finance") {
      nameKey = "fxh1hj9";
    } else if (genre === "Family") {
      nameKey = "9tl1oal";
    } else if (genre === "Office") {
      nameKey = "38ctv8a";
    } else if (genre === "College") {
      nameKey = "gj8qp1n";
    } else if (genre === "Weddings") {
      nameKey = "p6zegc7";
    } else if (genre === "Food") {
      nameKey = "yt1l5rh";
    } else if (genre === "Animals") {
      nameKey = "xqhaycx";
    } else if (genre === "Cricket") {
      nameKey = "ayhc2ry";
    }

    TTDPixel(nameKey);
  };

  // functionality to deselect category
  function handleClick() {
    if (common.genereID === genereID) {
      if (handleCategorySelect) {
        handleCategorySelect(0);
      }
      dispatch(setGenereID(0));
    } else {
      if (handleCategorySelect) {
        handleCategorySelect(genereID);
      }
      if (pathname === "/") {
        dispatch(displaySuprise(true));
        ClickButtonCDP(`Pull_Joke_${props.genre}`)
      }
      MetaPixel(`Mood_${props.genre}`);
      TTDapplyFn(props.genre);
      CounterFloodLight(`DC-12665261/sprit0/sprit01i+unique`,
        {
          u45:props.genre
        }
      );
      dispatch(setGenereID(genereID));
    }
  }

  useEffect(() => {
    const titleCard = otherLangTitle?.filter(
      (item) => item.languageId === common.currentLanguageID
    );
    setCardName(
      titleCard[0]?.languageScript ? titleCard[0]?.languageScript : enTitle
    );
  }, []);

  return (
    <>
      <div
        className={`align-items-center ${wrapperClassName} ${
          wrapperClassName === "btn-category" && common.genereID === genereID
            ? "tickle-active"
            : common.genereID === 0
            ? "border-0"
            : ""
        }`}
        onClick={handleClick}
      >
        <div className="card-tickle text-center">
          <div
            className={
              wrapperClassName !== "btn-category" &&
              common.genereID === genereID
                ? "tickle-active"
                : ""
            }
          >
            <img src={cardImage} alt={cardAlt} />
          </div>
          <h6 className="">{cardName}</h6>
        </div>
      </div>
    </>
  );
}

export default TickleCard;
