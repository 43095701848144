import React, { useState, useEffect, useRef, lazy } from "react";
import { useSelector } from "react-redux";
import useLoader from "../utils/hooks/use-loader";
import { getContestData } from "../services";
import RewardPool from "../components/common/Carousel";
import { Button, Container } from "react-bootstrap";
import SpritePoints from "./contestPage/SpritePoints";
import useTranslation from "../utils/hooks/use-translation";
import { useViewport } from "../utils/context/viewport-context";
import { IMAGE_BASE_URL } from "../config";
import { TTDPixel } from "../utils/helpers";
import CountDown from "../components/common/CountDown";
import { useNavigate, useLocation } from "react-router-dom";
import ContestOnboarding from "../components/common/TourGuideContest";
import { useDispatch } from "react-redux";
import useAuth from "../utils/hooks/use-auth";
import { getAllRewards } from "../services";
import { setHomePageDetails } from "../store/slices/common-slice";
import { getCDSPixelId } from "../utils/helpers";
const HomeBanner = lazy(() => import("../components/common/Carousel2"));

const Contest = () => {
  const { isMobile } = useViewport();
  const dispatch = useDispatch();
  const { isLoggedIn } = useAuth();

  const location = useLocation();

  const navigate = useNavigate();
  const [contestData, setContestData] = useState([]);
  const { toggleLoader } = useLoader();
  const [showCoin, setShowCoin] = useState(false);

  const [coinPosition, setCoinPosition] = useState({ x: 0, y: 0 });
  const [moveToDropdownUser, setMoveToDropdownUser] = useState(false);
  const coinRef = useRef(null);
  const constestTour = useSelector((state) => state?.supriseMe?.constestTour);
  const currentLanguageID = useSelector(
    (state) => state.common.currentLanguageID
  );

  const windowLayerPush = (event) => {
    window.dataLayer = window.dataLayer || [];

    let indexToUpdate = -1;
    for (let i = 0; i < window.dataLayer.length; i++) {
      if (window.dataLayer[i].loginEvent) {
        indexToUpdate = i;
        break;
      }
    }

    if (indexToUpdate !== -1) {
      // Update the existing shareContent entry
      window.dataLayer[indexToUpdate].loginEvent = {
        loggedInEvent: event,
      };
    } else {
      // Add a new shareContent entry
      window.dataLayer.push({
        loginEvent: {
          loggedInEvent: event,
        },
      });
    }
  };

  useEffect(() => {
    // Check if the location hash exists and matches the id
    if (location.hash === "#tour-gather-cominc" && contestData) {
      const scrollToElement = () => {
        const element = document.getElementsByClassName("tour-gather-cominc");
        if (element[0]) {
          element[0].scrollIntoView({ behavior: "smooth" });
          return true;
        }
        return false;
      };

      if (!scrollToElement()) {
        const observer = new MutationObserver(() => {
          if (scrollToElement()) {
            observer.disconnect();
          }
        });

        observer.observe(document.body, { childList: true, subtree: true });

        return () => {
          observer.disconnect();
        };
      }
    }
  }, [location, contestData]);

  let iconImagebaseURL = isMobile
    ? `${IMAGE_BASE_URL}/Contest/Mobile/`
    : `${IMAGE_BASE_URL}/Contest/Desktop/`;

  const getAllRewardsData = async () => {
    const hfSessionKey = await getCDSPixelId();

    const rewardsRes = await getAllRewards({
      languageId: currentLanguageID || "1",
      hfSession: hfSessionKey,
    });

    dispatch(
      setHomePageDetails({
        coinCount: rewardsRes?.data?.data?.currentBalance ?? 0,
      })
    );
  };

  const { t } = useTranslation();
  const getContestDataList = async () => {
    toggleLoader(true);
    try {
      let reqData = { languageId: currentLanguageID || "1" };
      const res = await getContestData(reqData);
      if (res.status === 200) {
        let contestResData = res.data && res.data.data;
        let RewardPoolData = contestResData.find(
          (obj) => obj.sectionName === "ContestRewardsPool"
        );
        let PromoData = contestResData.find(
          (obj) => obj.sectionName === "Promo"
        );
        let PointsPoolData = contestResData.find(
          (obj) => obj.sectionName === "PointsPool"
        );
        let data = {
          RewardPoolData: RewardPoolData,
          PromoData: PromoData,
          PointsPoolData: PointsPoolData,
        };
        setContestData(data);
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  };

  useEffect(() => {
    getContestDataList();
    TTDPixel("k7yrzw3");
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      getAllRewardsData();
    }
    windowLayerPush(isLoggedIn);
  }, [isLoggedIn]);

  const customHeadingProps = {
    heading1: t.home.bring_funny,
    heading2: t.home.fablous_prizes,
    imgSrc: "/assets/img/bgs/comingsoon-bg.png",
  };
  const rewardPool = {
    title: t.header.reward_pool,
    // subTitle: rewardPooldata && rewardPooldata.desc,
    linkText: "",
    // titleClass: "tour-reward",
  };

  const handleClick = () => {
    const dropdownUserElement = document.getElementById("dropdown-user");
    if (dropdownUserElement) {
      const { left, top, width, height } =
        dropdownUserElement.getBoundingClientRect();
      const centerX = window.innerWidth / 2;
      const centerY = window.innerHeight / 2;
      const offsetX = left + width / 2 - centerX;
      const offsetY = top + height / 2 - centerY;

      setCoinPosition({ x: offsetX, y: offsetY });
      setMoveToDropdownUser(true);
      setShowCoin(true);
    }
  };

  useEffect(() => {
    if (moveToDropdownUser) {
      moveCoinToDropdownUser();
    }
  }, [moveToDropdownUser]);

  const moveCoinToDropdownUser = () => {
    const dropdownUserElement = document.getElementById("dropdown-user");
    const coinElement = coinRef.current;

    if (dropdownUserElement && coinElement) {
      const { left, top, height, width } =
        dropdownUserElement.getBoundingClientRect();
      setTimeout(() => {
        coinElement.style.transition = "left 1s, top 1s";
        coinElement.style.left = `${left + width / 2}px`;
        coinElement.style.top = `${top + height / 2}px`;
      }, 500);

      setTimeout(() => {
        coinElement.style.left = `50%`;
        coinElement.style.top = `50%`;
        setShowCoin(false);
        setMoveToDropdownUser(false);
      }, 1500);
    }
  };

  return (
    <div className="contest">
      {constestTour && <ContestOnboarding isOpen={constestTour} />}

      {/* learderboard coutdown commented  */}
      {/* <CountDown /> */}   
      
      {/* <div
        ref={coinRef}
        className={`coin ${showCoin ? "moveCoin" : ""}`}
        style={{
          left: coinPosition.x || coinPosition.x !== 0 ? coinPosition.x : "50%",
          top: coinPosition.y || coinPosition.y !== 0 ? coinPosition.y : "50%",
        }}
      ></div> */}

      <Container fluid>
        <HomeBanner
          data={contestData && contestData?.PromoData?.contestBanners}
          isHeader={false}
          timer={true}
          sliderType={1}
          SliderContainerClass={`px-0 `}
          sectionClass={"mt-1 mt-md-4 how-participate"}
          cardType={"image"}
          id={"hero_banner"}
          imageType={"Contest"}
          isVideo={true}
          gaClass={"CSBharatHomeBanner"}
        />
      </Container>

      {/* <Container fluid>
         <div className="how-participate left-wrapper"></div> 
        <ComingSoonSection
          sectionClass={"mt-3 mb-3 mt-md-10 mb-md-10 s-shareJokes s-fab"}
          {...customHeadingProps}
          headingClass="s-font"
        />
        <HowToParticipateSection
          imageUrl={
            iconImagebaseURL +
              contestData?.PromoData?.contestBanners[0]?.imageUrl ||
            "PromoBanner_english.svg"
          }
        />
      </Container> */}
      {/* <div className="tour-reward"></div> */}
      {contestData?.RewardPoolData?.contestRewardsPools.length ? (
        <RewardPool
          data={contestData?.RewardPoolData?.contestRewardsPools}
          headerContent={rewardPool}
          clickAnimation={handleClick}
          sliderType={3}
          cardType={"reward"}
          SliderContainerClass={`pr-0 pr-md-4`}
          id={"wallet_reward_pool"}
          sectionClass={"s-reward-pool contest-pool tour-reward"}
          imageType={""}
        />
      ) : null}
      <Container>
        <div className="d-flex justify-content-between btn-contest">
          <div>
            <Button
              className="tour-leaderboard "
              variant="primary"
              onClick={() => {
                isLoggedIn ? navigate("/leaderboard") : navigate("/login");
              }}
            >
              <img
                src="/assets/img/icons/leaderboard-icon.svg"
                alt="icon"
                className="mr-1 mr-md-3 "
                width={"25"}
                height={"25"}
              />
              {t.header.leaderboard}
            </Button>
          </div>
          <div className="tour-comic ">
            <Button
              variant="primary"
              onClick={() => navigate("/my_wallet")}
              className=""
            >
              {/* <span></span> */}
              <picture>
                <source
                  srcset="/assets/img/contest/comic_coin@1x.webp 1x, /assets/img/contest/comic_coin@2x.webp 2x"
                  type="image/webp"
                />

                <img
                  src="/assets/img/contest/comic_coin@1x.png"
                  srcset="/assets/img/contest/comic_coin@1x.png 1x, /assets/img/contest/comic_coin@2x.png 2x"
                  alt="unique code"
                  className="mr-1"
                  width={25}
                  height={25}
                />
              </picture>

              {t.wallet.total_coins}
            </Button>
          </div>
        </div>
      </Container>
      {/* <HorizontalRewardPool
        data={contestData[0]?.contestRewardsPools}
        headerContent={rewardPool}
        clickAnimation={handleClick}
        sliderType={4}
        cardType={"reward"}
        SliderContainerClass={`pr-0 pr-md-4`}
        id={"wallet_reward_pool"}
        sectionClass={"s-reward-pool"}
        imageType={""}
      /> */}
      {/* <PreviousWinner
        className="contest-winner" // Pass the className as a prop
        headerContent={{
          title: t.header.previous_winners,
          subTitle: t.home.previous_winner_subheading,
          buttonText: t.header.leaderboard,
        }}
      /> */}

      {contestData?.PointsPoolData?.pointsPools.length > 0 && (
        <SpritePoints
          data={contestData?.PointsPoolData?.pointsPools}
          spritePointsHeader={{
            title: t.contest.how_gather_sprite_points,
          }}
          sliderType={5}
          cardType={"spritepoints"}
          SliderContainerClass={`pr-0 pr-md-4`}
          id={"wallet_sprite_points "}
          sectionClass={"s-reward-pool"}
          imageType={""}
        />
      )}

      {/* {rewardsData.length > 0 && (
        <HorizontalRewardPool
          data={rewardsData}
          headerContent={rewardPool}
          sliderType={4}
          cardType={"reward"}
          SliderContainerClass={`pr-0 pr-md-4`}
          id={"reward_pool"}
          sectionClass={"s-reward-pool"}
          imageType={""}
        />
      )}
      {rewardsData.length > 0 && (
        <RewardPool
          data={rewardsData}
          headerContent={rewardPool}
          sliderType={4}
          cardType={"reward"}
          SliderContainerClass={`pr-0 pr-md-4`}
          id={"reward_pool"}
          sectionClass={"s-reward-pool"}
          imageType={""}
        />
      )}
      {rewardsData.length > 0 && (
        <HorizontalRewardPool
          data={rewardsData}
          headerContent={rewardPool}
          sliderType={4}
          cardType={"reward"}
          SliderContainerClass={`pr-0 pr-md-4`}
          id={"reward_pool"}
          sectionClass={"s-reward-pool"}
          imageType={""}
        />
      )} */}

      {/* <CokeCoins
        data={contestData[1]?.pointsPools}
        headerContent={cokeCoinsHeader}
        sliderType={2}
        cardType={"reward"}
        SliderContainerClass={`pr-0 pr-md-4`}
        id={"wallet_coins"}
        sectionClass={"s-reward-pool"}
        imageType={""}
      /> */}
    </div>
  );
};

export default Contest;
