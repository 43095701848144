import { createContext, useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "../../../utils/hooks/use-translation";
import UniqueCodeModal from "../appForm/UniqueCode";
import ReferAFriendForm from "../appForm/ReferFriendForm";
import ErrorModel from "../../modals/api_response_model";
import {
  displayUniqueCodeSuccess,
  displayVoucherUniqueSuccess,
  displayShareWithFriends,
  setVoucherData,
  displayUniqueCode,
} from "../../../store/slices/pop-up";
import useAuth from "../../../utils/hooks/use-auth";
import VoucherModal from "../../modals/voucher_modal";
import ShareWithFriendsModal from "../../modals/share_with_friends";
import { useLocation } from "react-router-dom";
import { showContestTour } from "../../../store/slices/common-slice";
import { displayContestTour, displayContestTourTrigger } from "../../../store/slices/suprise-me";

export const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showReferPopup, setShowReferPopup] = useState(false);
  const [showReferFriendPopup, setShowReferFriendPopup] = useState(false);
  const contestTourTrigger = useSelector(
    (state) => state?.supriseMe?.contestTourTrigger
  );

  const showUniqueCodePopup = useSelector(
    (state) => state?.popUp?.showUniqueCodePopup
  );
  const uniqueCodeSuccess = useSelector(
    (state) => state?.popUp?.uniqueCodeSuccess
  );
  const voucherUniqueSuccess = useSelector(
    (state) => state?.popUp?.voucherUniqueSuccess
  );
  const showShareWithFriends = useSelector(
    (state) => state?.popUp?.showShareWithFriends
  );

  const openUniqueCodePopup = (action) => {
    dispatch(displayUniqueCode(true));
  };

  const openReferPopup = useCallback((action) => {
    setShowReferPopup((prevLoading) =>
      action !== undefined && typeof action === "boolean"
        ? action
        : !prevLoading
    );
  }, []);

  const openReferFriendPopup = useCallback((action) => {
    setShowReferFriendPopup((prevLoading) =>
      action !== undefined && typeof action === "boolean"
        ? action
        : !prevLoading
    );
  }, []);

  const closeUniqueCodePopup = (closed) => {
    dispatch(displayUniqueCode(false));
    runContestOnboard(closed);
  };

  useEffect(() => {
    if (
      pathname === "/contest" &&
      isLoggedIn &&
      contestTourTrigger &&
      !showUniqueCodePopup &&
      !voucherUniqueSuccess
    ) {
      // localStorage.setItem("contest-onboard", "completed");
      dispatch(displayContestTour(true));
      dispatch(displayContestTourTrigger(false));
    }
  }, [showUniqueCodePopup, voucherUniqueSuccess]);

  const runContestOnboard = (closed) => {
    if (!localStorage.getItem("visitTime") && isLoggedIn) {
      let currentTime = new Date(Date.now());
      localStorage.setItem(
        "visitTime",
        new Date(currentTime.getTime() + 24 * 60 * 60 * 1000)
      );
    }
    if (
      pathname === "/contest" &&
      isLoggedIn &&
      contestTourTrigger &&
      !showUniqueCodePopup &&
      closed &&
      !voucherUniqueSuccess
    ) {
      // localStorage.setItem("contest-onboard", "completed");
      dispatch(displayContestTour(true));
      dispatch(displayContestTourTrigger(false));
    }
  };

  const closeReferPopup = useCallback(() => {
    setShowReferPopup(false);
    setShowReferFriendPopup(false);
  }, []);

  const modelType = {
    image: "/assets/img/contest/sprite-coin.svg",
    heading: t.model_messages.that_was_quick,
    subHeading: t.model_messages.unique_code_win_points,
    timer: false,
    timerContent: t.common.phonePe_vouchers_are_already_won,
    cta: t.model_messages.got_it,
    id: 8,
  };

  return (
    <>
      <PopupContext.Provider
        value={{
          openUniqueCodePopup,
          openReferPopup,
          openReferFriendPopup,
          closeUniqueCodePopup,
        }}
      >
        {children}
        <UniqueCodeModal
          show={showUniqueCodePopup}
          onClose={closeUniqueCodePopup}
          type={"model"}
        />
        <ErrorModel
          show={uniqueCodeSuccess}
          {...modelType}
          jokeSubmit={false}
          onClose={() => {
            dispatch(displayUniqueCodeSuccess(false));
            runContestOnboard(true);
          }}
        />
        <VoucherModal
          show={voucherUniqueSuccess}
          onClose={() => {
            dispatch(displayVoucherUniqueSuccess(false));
            dispatch(setVoucherData({}));
            runContestOnboard(true);
          }}
        />
        <ShareWithFriendsModal
          show={showShareWithFriends?.show}
          onClose={() => dispatch(displayShareWithFriends(false))}
        />
        <ReferAFriendForm
          show={showReferPopup}
          onClose={closeReferPopup}
          type={"refer_code"}
        />
        <ReferAFriendForm
          show={showReferFriendPopup}
          onClose={closeReferPopup}
          type={"refer_number"}
        />
      </PopupContext.Provider>
    </>
  );
};
