export const VALIDATION_MSG = {
  required: {
    name: "Please enter a valid name.",
    mobile: "Please enter a valid 10-digit mobile number.",
    otp: "Please enter OTP.",
    email: "Please enter a valid email id.",
    profileImage: "Please choose avatar.",
    Code: "Please enter a valid unique code.",
    AddressLine1: "Please enter address.",
    State: "Please select state.",
    City: "Please select city.",
    PinCode: "Please enter pin code.",
    Date: "Please enter Date.",
    Govid:"Please enter a valid PAN Card number.",
    Passport:"Please enter valid passport number.",
    refer_code:'Please enter refer code.'
  
  },
  valid: {
    mobile: "Please enter a valid 10-digit mobile number.",
    otp: "Please enter a valid OTP.",
    email: "Please enter a valid email id.",
    Code: "You have entered an invalid unique code",
    PinCode: "Please enter a valid pin code.",
    name: "Please enter a valid name.",
    Date: "Please enter a valid date.",
    Passport:"Please enter valid passport number.",
    Govid:"Please enter a valid PAN Card number."
  },
  length:{
    maxC:'Min length must be 14 characters.',
    minC: 'Min length must be 14 characters.',
  }
};

export const MESSAGES = {
  REMINDER_SENT_SUCCESS: 'Reminder sent successfully',
  REFER_CODE_SUCCESS:'Refer code verified successfully',
  CLAIM_SUCCESS:'You have successfully claimed!',
  REFER_SUCCESS:'You have successfully referred!',
  ADD_ONE_ADDRESS:'Please add atleast one address',
  NOT_ELIGIBLE_TO_CLAIM:'You are not eligible to claim coins',
  CANT_FETCH_MOBILE:"Can't fetch your mobile number"
}


