import { createSlice } from "@reduxjs/toolkit";

// Define the initial state for the authentication slice
const initialState = {
  supriseMe: false,
  constestTour: false,
  contestTourTrigger: false,
  jokeOfTheDay: true,
};

// Create the authentication slice using `createSlice`
export const popUpSlice = createSlice({
  name: "supriseMe", // Name of the slice
  initialState,
  reducers: {
    displaySuprise: (state, action) => {
      const { payload } = action; // Payload contains the user details
      state.supriseMe = payload; // Set isLoggedIn to true if payload is not empty
    },
    displayWelcome: (state, action) => {
      const { payload } = action; // Payload contains the user details
      state.welcomeJourney = payload; // Set isLoggedIn to true if payload is not empty
    },
    displayContestTourTrigger: (state, action) => {
      const { payload } = action; // Payload contains the user details
      state.contestTourTrigger = payload; // Set isLoggedIn to true if payload is not empty
    },
    displayContestTour: (state, action) => {
      const { payload } = action; // Payload contains the user details
      state.constestTour = payload; // Set isLoggedIn to true if payload is not empty
    },
    displayJokeOfTheDay: (state, action) => {
      const { payload } = action; // Payload contains the user details
      state.jokeOfTheDay = payload; // Set isLoggedIn to true if payload is not empty
    },
  },
});

// Extract the action creators generated by `createSlice`
export const {
  displaySuprise,
  displayWelcome,
  displayJokeOfTheDay,
  displayContestTour,
  displayContestTourTrigger
} = popUpSlice.actions;

// Export the reducer function generated by `createSlice`
export default popUpSlice.reducer;
