import React from "react";
import useTranslation from "../../../utils/hooks/use-translation";
import { Card, Col } from "react-bootstrap";
import TitleShowCase from "../TitleShowCase";
import { useNavigate } from "react-router";

function UgcUploadPreview() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const jokeGenre = "Daily Humour";
  const jokeLang = "English";
  const jokeTitle = "Funny Joke";
  const author = "John Doe";
  const isLoggedIn = true;
  const onPopUpClass = false;

  return (
    <Col xs={12} md={6} className="form-preview px-0 pt-15 mx-auto ">
      <Card>
        <Card.Header>
          <TitleShowCase
            jokeGenre={jokeGenre}
            jokeLang={jokeLang}
            jokeTitle={jokeTitle}
            author={author}
            isLoggedIn={isLoggedIn}
            onPopUpClass={onPopUpClass}
          />
        </Card.Header>
        <Card.Body className="p-0 border-0">
          <img src="/assets/img/modal/UGC_listing.png" alt="UGC Preview" />
          <label className="form-preview-overlay">
            Preview your submission
          </label>
        </Card.Body>
      </Card>
      <div className="d-block d-md-flex justify-content-md-around">
        <button
          className="btn btn-block border mt-md-4 mr-md-4"
          onClick={() => navigate("/ugc_upload")}
        >
          <img src="/assets/img/icons/retry.svg" alt="retry-icon" className="mr-1 retry" />
          {t.common.retry}
        </button>
        <button
          className="btn btn-block btn-primary ml-md-4"
          onClick={() => navigate("/ugc")}
        >
          {t.common.submit}
        </button>
      </div>
    </Col>
  );
}

export default UgcUploadPreview;
