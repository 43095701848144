const cdpPixelId = process.env.REACT_APP_CDS_TRAKING_ID;

export const HomeCDP = async (ipDetails) => {
  // const date = new Date();
  // const formattedDate = date.toISOString().slice(0, 10); // Gets the date part in YYYY-MM-DD format

  const data = {
    event_type: "Page_view",
    event_sub_type: "Homepage_load",
    brand_name: "Sprite",
    geo_country_code: ipDetails["countryCode"] || "",
    geo_state_province_code: ipDetails["region"] || "",
    geo_city_name: ipDetails["city"] || "",
    geo_postal_code: ipDetails["zip"] || "",
    // 'ip_address' : ipDetails['query'],
    // 'created_dt' : formattedDate
  };
  window.cds_pixel(cdpPixelId, data);
};

export const RegisterCDP = async ({ ipDetails, phone, email, first_name }) => {
  const date = new Date();
  const formattedDate = date.toISOString().slice(0, 10); // Gets the date part in YYYY-MM-DD format

  const data = {
    event_type: "Click",
    event_sub_type: "Submit_Reg",
    brand_name: "Sprite",
    created_dt: formattedDate,
    geo_country_code: ipDetails["countryCode"] || "",
    geo_state_province_code: ipDetails["region"] || "",
    geo_city_name: ipDetails["city"] || "",
    geo_postal_code: ipDetails["zip"] || "",
    phone_with_countrycode: phone,
    email: email,
    first_name: first_name,
    targeting_age_from: 18,
    "communication_preferences/sms_message": 1,
    "communication_preferences/phone_call": 1,
    "communication_preferences/email": 1,
    "communication_preferences/email": 1,
  };
  window.cds_pixel(cdpPixelId, data);
};

export const LoginCDP = async ({ ipDetails, phone }) => {
  const date = new Date();
  const formattedDate = date.toISOString().slice(0, 10); // Gets the date part in YYYY-MM-DD format

  const data = {
    event_type: "Click",
    event_sub_type: "Login",
    brand_name: "Sprite",
    created_dt: formattedDate,
    geo_country_code: ipDetails["countryCode"] || "",
    geo_state_province_code: ipDetails["region"] || "",
    geo_city_name: ipDetails["city"] || "",
    geo_postal_code: ipDetails["zip"] || "",
    phone_with_countrycode: phone,
  };
  window.cds_pixel(cdpPixelId, data);
};

export const ClickButtonCDP = async (eventSubType) => {
  // const CdpCookie = await GetUserIdentifier();
  const data = {
    event_type: "Click",
    event_sub_type: `${eventSubType}`,
    brand_name: "Sprite",
  };
  window.cds_pixel(cdpPixelId, data);
};

export const onLoadCdp = async (eventSubType) => {
  // const CdpCookie = await GetUserIdentifier();
  const data = {
    event_type: "On load",
    event_sub_type: `${eventSubType}`,
    brand_name: "Sprite",
  };

  window.cds_pixel(cdpPixelId, data);
};

export const ClickButtonCDPWithData = async (eventSubType, payload) => {
  // const CdpCookie = await GetUserIdentifier();
  const data = {
    event_type: "Click",
    event_sub_type: `${eventSubType}`,
    brand_name: "Sprite",
    ...payload,
  };
  window.cds_pixel(cdpPixelId, data);
};

export const ClickLanguageChangeCDP = async (lang) => {
  const data = {
    event_type: "Select",
    event_sub_type: `Change_Language`,
    brand_name: "Sprite",
    language_code: `${lang}`,
  };
  window.cds_pixel(cdpPixelId, data);
};

// export const ClickEmoji = async (emoji) => {
//   let eventSubtype;

//   if (emoji === "laugh") {
//     eventSubtype = "Haha_Reaction_joke-id";
//   } else if (emoji === "neutral") {
//     eventSubtype = "Meh_Reaction_joke-id";
//   } else if (emoji === "sad") {
//     eventSubtype = "Grr_Reaction_joke-id";
//   }

//   const data = {
//     event_type: "Select",
//     event_sub_type: `${eventSubtype}`,
//     brand_name: "Sprite",
//   };
//   window.cds_pixel(cdpPixelId, data);
// };
