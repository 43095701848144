import React, { useState, useEffect, useRef } from "react";
import CommonSection from "../components/common/CommonSection";
import SectionHeader from "../components/common/SectionHeader";
import DatePicker from "react-datepicker"; // Importing the date picker component
import "react-datepicker/dist/react-datepicker.css"; // Importing the styles for the date picker

import { getAllreferralAPI } from "../services";
import useTranslation from "../utils/hooks/use-translation";
import TickleBoard from "../components/TickleBoard";
import gluedin from "gluedin";

const HallLame = (props) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [pageSize] = useState(5);
  const [referralList, setReferralList] = useState([]);
  const [allreferralData, setAllReferralData] = useState([]);
  const [jokeLeaderboarddata, setJokeLeaderboardData] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false); // State variable to control the visibility of the date picker

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const datepickerRef = useRef(null);
  const isDesktop = window.innerWidth >= 992;
  const headerContent = {
    title: t.header.hall_of_lame,
    subTitle: t.header.look_on_top_subtitle,
    titleClass: "text-left text-md-center",
  };
  useEffect(() => {
    getReferralList(page);
    getJokeLeaderBoardData();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [page, pageSize]);

  const getReferralList = async (page) => {
    try {
      let reqData = {
        PageNumber: page,
        Pagesize: 5,
      };
      const res = await getAllreferralAPI(reqData);
      if (res.status === 200) {
        let data =
          res.data &&
          res.data.data &&
          Array.isArray(res.data.data.data) &&
          res.data.data.data.length
            ? res.data.data.data
            : [];
        setAllReferralData(res.data.data);
        setReferralList(data);
      }
    } catch (err) {
      console.error("Error fetching referral list:", err);
    }
  };

  const getJokeLeaderBoardData = async () => {
    try {
      const userModule = new gluedin.GluedInUserModule();
      const jokeLeaderBoardResponse = await userModule.getJokeLeaderBoard({
        fromDate: startDate.getTime(), //Week start date timestamp ,
        toDate: endDate.getTime(), //Week end date timestamp
      });
      setJokeLeaderboardData(jokeLeaderBoardResponse?.data?.result);
    } catch (err) {
      console.error("Error fetching leaderboard data:", err);
    }
  };

  // const loadMore = async () => {
  //   setPage(page + 1);
  //   try {
  //     let reqData = {
  //       PageNumber: page + 1,
  //       Pagesize: 5,
  //     };
  //     const res = await getAllreferralAPI(reqData);
  //     if (res.status === 200) {
  //       let data =
  //         res.data &&
  //         res.data.data &&
  //         Array.isArray(res.data.data.data) &&
  //         res.data.data.data.length
  //           ? res.data.data.data
  //           : [];
  //       setAllReferralData(res.data.data);
  //       setReferralList([...referralList, ...data]);
  //     }
  //   } catch (err) {
  //     console.error("Error loading more referrals:", err);
  //   }
  // };

  const handleFilterClick = () => {
    setShowDatePicker(!showDatePicker); // Toggle the state variable to show/hide the date picker
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      setShowDatePicker(false);
    }
    // Here you can perform filtering based on the selected date range
  };

  useEffect(() => {
    if (startDate && endDate) {
      getJokeLeaderBoardData();
    }
  }, [endDate]);

  const handleClickOutside = (event) => {
    if (
      datepickerRef.current &&
      !datepickerRef.current.contains(event.target)
    ) {
      setShowDatePicker(false);
    }
  };

  return (
    <div className="leaderboard">
      <CommonSection
        headerContent={headerContent}
        headerType={3}
        containerClass="pt-6 mt-md-15 mt-12 "
        sectionClass=" px-0"
      >
        {isDesktop && (
          <SectionHeader
            {...headerContent}
            headerClass={`pt-6 pb-5 pt-md-10 pb-md-6 text-md-center text-left`}
          />
        )}
      </CommonSection>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12">
            <div
              className="filter position-relative pt-2 pb-1"
              onClick={handleFilterClick}
            >
              <img src="/assets/img/icons/calender.svg" alt="calendar" className="mr-2" />
              <span>{t.common.weekly_top_jokes}</span>
            </div>
            {/* Conditionally render the date picker */}
            {showDatePicker && (
              <div className="date-picker-overlay">
                <div ref={datepickerRef} className="date-picker-container">
                  <DatePicker
                    selected={startDate}
                    onChange={handleDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline
                    showWeekNumbers
                    placeholderText="Select Week Range"
                    dateFormat="MM-dd"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="col-12">
            <TickleBoard
              data={jokeLeaderboarddata}
              setPage={setPage}
              page={page}
              pageSize={pageSize}
              totalCount={jokeLeaderboarddata.length}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default HallLame;
