import html2canvas from "html2canvas";

export const exportAsImage = async (el) => {
const canvas = await html2canvas(el);
const imageBlob = canvas.toDataURL("image/png", 1.0);
return imageBlob;
};

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mimeType = arr[0].match(/:(.*?);/)[1],
    decodedData = atob(arr[1]),
    lengthOfDecodedData = decodedData.length,
    u8array = new Uint8Array(lengthOfDecodedData);
  while (lengthOfDecodedData--) {
    u8array[lengthOfDecodedData] = decodedData.charCodeAt(lengthOfDecodedData);
  }
  return new File([u8array], filename, { type: mimeType });
};


export const shareFile = ({file, ...data}) => {
  if (navigator.canShare && navigator.canShare({ files: [file] })) {
    navigator
      .share({
        files: [file],
        ...data,
      })
      .then(() => console.log("Share was successful."))
      .catch((error) => console.log("Sharing failed", error));
  } else {
    console.log(`Your system doesn't support sharing files.`);
  }
};
export const getShareAbleImage = async (componentRef, filename="image.png")=>{
    const blob = await exportAsImage(componentRef.current);
    const file = dataURLtoFile(blob, filename);
    return file;
}