import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  displayReportJoke,
  reportedVideoId,
} from "../../store/slices/report-joke";
function TitleShowCase({
  jokeGenre,
  jokeLang,
  jokeTitle,
  author,
  onClose,
  showCardReport = true,
  isLoggedIn,
  onPopUpClass = false,
  videoId,
}) {
  const dispatch = useDispatch();

  const genreBaseUrl =
    "https://017526-content-prod.s3.ap-south-1.amazonaws.com/GenreImages/";
  const getGenreImage = () => {
    switch (jokeGenre) {
      case "Daily Humour":
        return (
          <img
            src={genreBaseUrl + "Daily_Humour.svg"}
            alt="card icon"
            className="title-icon"
          />
        );
      case "Dating":
        return (
          <img
            src={genreBaseUrl + "Dating.svg"}
            alt="card icon"
            className="title-icon"
          />
        );
      case "Family":
        return (
          <img
            src={genreBaseUrl + "Family.svg"}
            alt="card icon"
            className="title-icon"
          />
        );
      case "Finance":
        return (
          <img
            src={genreBaseUrl + "Finance.svg"}
            alt="card icon"
            className="title-icon"
          />
        );
      case "Food":
        return (
          <img
            src={genreBaseUrl + "Food.svg"}
            alt="card icon"
            className="title-icon"
          />
        );
      case "Friends":
        return (
          <img
            src={genreBaseUrl + "Friends.svg"}
            alt="card icon"
            className="title-icon"
          />
        );
      case "Internet":
        return (
          <img
            src={genreBaseUrl + "Internet.svg"}
            alt="card icon"
            className="title-icon"
          />
        );
      case "Movies":
        return (
          <img
            src={genreBaseUrl + "Movies.svg"}
            alt="card icon"
            className="title-icon"
          />
        );
      case "Office":
        return (
          <img
            src={genreBaseUrl + "Office.svg"}
            alt="card icon"
            className="title-icon"
          />
        );
      case "OTT":
        return (
          <img
            src={genreBaseUrl + "OTT.svg"}
            alt="card icon"
            className="title-icon"
          />
        );
      case "Pollution":
        return (
          <img
            src={genreBaseUrl + "Pollution.svg"}
            alt="card icon"
            className="title-icon"
          />
        );
      case "Traffic":
        return (
          <img
            src={genreBaseUrl + "Traffic.svg"}
            alt="card icon"
            className="title-icon"
          />
        );
      case "Travel":
        return (
          <img
            src={genreBaseUrl + "Travel.svg"}
            alt="card icon"
            className="title-icon"
          />
        );
      case "Animals":
        return (
          <img
            src={genreBaseUrl + "Animal.svg"}
            alt="card icon"
            className="title-icon"
          />
        );
      case "Cricket":
        return (
          <img
            src={genreBaseUrl + "Cricket.svg"}
            alt="card icon"
            className="title-icon"
          />
        );
      case "Wedding":
        return (
          <img
            src={genreBaseUrl + "Wedding.svg"}
            alt="card icon"
            className="title-icon"
          />
        );
      case "College":
        return (
          <img
            src={genreBaseUrl + "College.svg"}
            alt="card icon"
            className="title-icon"
          />
        );
      case "Childhood":
        return (
          <img
            src={genreBaseUrl + "Childhood.svg"}
            alt="card icon"
            className="title-icon"
          />
        );
      case "Observation":
        return (
          <img
            src={genreBaseUrl + "Observation.svg"}
            alt="card icon"
            className="title-icon"
          />
        );
      case "Relationships":
        return (
          <img
            src={genreBaseUrl + "Relationships.svg"}
            alt="card icon"
            className="title-icon"
          />
        );
      case "Self":
        return (
          <img
            src={genreBaseUrl + "Self.svg"}
            alt="card icon"
            className="title-icon"
          />
        );
      case "Adulting":
        return (
          <img
            src={genreBaseUrl + "Adulting.svg"}
            alt="card icon"
            className="title-icon"
          />
        );
      // Add additional cases as needed for other joke genres
      default:
        return (
          <img
            src={genreBaseUrl + "Traffic.svg"}
            alt="card icon"
            className="title-icon"
          />
        ); // Default return value
    }
  };

  const handleClose = () => {
    onClose();
  };
  return (
    <div
      className={`d-flex justify-content-between ${
        onPopUpClass && "title-show-popup "
      }`}
    >
      {isLoggedIn && (
        <>
          <div className="d-flex wrapper card-heading align-items-center">
            <div>{getGenreImage()}</div>
            <div className="ml-2 giggle-text">
              <h6 className="title">{jokeTitle || ""}</h6>
              <p className="mb-1 subtitle">
                {author ? author + ", " : "" + jokeLang ? jokeLang : "" || ""}
              </p>
            </div>
          </div>
          {showCardReport && ( // Conditionally render card-report based on showCardReport prop
            <div
              className="card-report d-flex align-items-center justify-content-center "
              onClick={() => {
                dispatch(displayReportJoke(true));
                dispatch(reportedVideoId(videoId));
              }}
            >
              <span className="text-danger mr-1">Report</span>
              <img src="/assets/img/icons/info.svg" alt="card icon" />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default TitleShowCase;
