import React, { useRef, useState } from "react";
import { format } from "date-fns";
import { Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ProfileImageSelector from "../components/common/profile/profile-image-selector";
import ResendOTPTimer from "./helpers/Timer";
import AddressForm from "../components/common/appForm/AddressForm";
import useTranslation from "./hooks/use-translation";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useAuth from "./hooks/use-auth";

const CommonFormikForm = ({
  initialValues,
  validationSchema,
  onSubmit,
  renderInputs,
  profileState,
  formClass,
  submitBtnClass = "",
  formFooterClass = "",
  ButtonText = "",
  renderSeparator,
  inputStyle,
  isDisplayEditIcon = true,
  gaClass,
  referConditionsText,
  buttonDisabled = false,
  referError = "",
  setReferError=()=>{}
}) => {
  const { userDetails } = useAuth();
  const inputRef = useRef(null);

  function truncateWhiteSpaceEnd(value) {
    value = value.replace(/\s{2,}/g, " ");
    // Remove trailing spaces
    value = value.trimEnd();
    return value;
  }

  const [dateSelected, setDateSelected] = useState(initialValues?.dob);

  const handleButtonClick = (err) => {
    const error =
      err?.mobile === "Please enter a valid 10-digit mobile number.";
    if (ButtonText === t.common.refer_now && referConditionsText) {
      if (error || (error && inputRef?.current?.value === 0)) {
        referConditionsText("Not a valid number");
      }
      if (userDetails?.mobileNo === inputRef?.current?.value) {
        referConditionsText("Same user");
      }
    }
  };

  const { t } = useTranslation();
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        setFieldValue,
        touched,
        getFieldProps,
        handleBlur,
        setFieldError,
      }) => (
        <Form autoComplete="off" className={formClass}>
          {renderInputs.map((inputProps, index) => (
            <div
              key={index}
              className={`form-group ${inputProps.formGroupClass || ""} ${
                inputProps.type === "image" ? "position-relative  " : ""
              }`}
            >
              {inputProps.label && (
                <>
                  <label htmlFor={inputProps.name}>{inputProps.label}</label>
                  <p className="modal-description px-4 text-center">
                    {inputProps.info}
                  </p>
                </>
              )}
              {inputProps.type === "text" && (
                <Field
                  type={inputProps.type}
                  name={inputProps.name}
                  innerRef={inputRef}
                  maxLength={inputProps.maxlength ? inputProps.maxlength : ""}
                  className={`form-control ${
                    touched[inputProps.name] && errors[inputProps.name]
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder={inputProps.placeHolder}
                  disabled={inputProps.disabled ? true : false}
                  onChange={(e) => {
                    if(referError)
                      {
                        setReferError("")
                      }
                    let value = e.target.value;

                    if (inputProps.name === "mobile") {
                      value = value.replace(/\D/g, "");
                      value = value.slice(0, 10);
                    }
                    if (value.length > 40) {
                      value = value.slice(0, 40); // Truncate to 40 characters
                    }

                    if (ButtonText === "Refer Now" && referConditionsText) {
                      if (value.length === 10) {
                        referConditionsText("Valid");
                      }
                    }

                    setFieldValue(inputProps.name, value);
                  }}
                  onBlur={(e) => {
                    const trimmedValue = truncateWhiteSpaceEnd(e.target.value);
                    setFieldValue(inputProps.name, trimmedValue);
                    handleBlur(e);
                  }}
                />
              )}

              {inputProps.type === "email" && (
                <Field
                  type={inputProps.type}
                  name={inputProps.name}
                  className={`form-control ${
                    touched[inputProps.name] && errors[inputProps.name]
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder={inputProps.placeHolder}
                  disabled={inputProps.disabled ? true : false}
                />
              )}
              {inputProps.type === "password" && (
                <Field
                  type="password"
                  name={inputProps.name}
                  className={`form-control ${
                    touched[inputProps.name] && errors[inputProps.name]
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder={inputProps.placeHolder}
                  disabled={inputProps.disabled ? true : false}
                />
              )}
              {inputProps.type === "date" && (
                <Field
                  name={inputProps.name}
                  disabled={inputProps.disabled ? true : false}
                >
                  {({ field, form }) => (
                    <DatePicker
                      id="date"
                      {...field}
                      selected={dateSelected || null}
                      wrapperClassName={`d-block custom-date-picker`}
                      className={`form-control w-100 form-date ${
                        touched[inputProps.name] && errors[inputProps.name]
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholderText={inputProps.placeHolder}
                      onChange={(date) => {
                        // If a date is selected, convert it to a string in the desired format
                        const selectedDate = date
                          ? format(date, "yyyy-MM-dd")
                          : null;
                        setDateSelected(selectedDate); // Update state
                        form.setFieldValue(field.name, selectedDate); // Update Formik field value
                      }}
                      // dateFormat="YYYY/MM/DD"
                      maxDate={new Date()}
                      icon={"/assets/img/icons/calender-icon.svg"}
                      dropdownMode="select"
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      onChangeRaw={(e) => e.preventDefault()}
                      isClearable
                    />
                  )}
                </Field>
              )}
              {inputProps.type === "select" && (
                <Field
                  as="select"
                  name={inputProps.name}
                  className={`form-select form-custom-select ${
                    touched[inputProps.name] && errors[inputProps.name]
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder={inputProps.placeHolder}
                  value={values[inputProps.name]}
                  disabled={inputProps.disabled ? true : false}
                >
                  <option value="">{inputProps.placeHolder}</option>
                  {inputProps.options.map((el, optionIndex) => (
                    <option key={optionIndex} value={el.value}>
                      {el.label}
                    </option>
                  ))}
                </Field>
              )}
              {inputProps.type === "image" && (
                <ProfileImageSelector
                  selected={values?.profileImage}
                  setFieldValue={setFieldValue}
                  error={values?.profileImage && profileState?.error?.message}
                  values={values}
                  isDisplayEditIcon={isDisplayEditIcon}
                  getFieldProps={getFieldProps}
                />
              )}

              {inputProps.type === "address" && (
                <AddressForm
                  selected={values?.profileImage}
                  setFieldValue={setFieldValue}
                  getFieldProps={getFieldProps}
                  source={"profile"}
                />
              )}

              {inputProps.type === "checkbox" && (
                <>
                  <div className="d-flex">
                    <Field
                      id="tnc"
                      type="checkbox"
                      name={inputProps.name}
                      className="form-group form-check mr-2"
                      placeholder={inputProps.placeHolder}
                    />
                    <label
                      className="small"
                      htmlFor="tnc"
                      dangerouslySetInnerHTML={{
                        __html: inputProps.heading,
                      }}
                    ></label>
                  </div>
                </>
              )}
              {inputProps.type === "react-otp-input" && (
                <>
                  <Field
                    value={values.otp}
                    name="otp"
                    autoComplete="off"
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const numericValue = inputValue.replace(/\D/g, "");
                      const truncatedValue = numericValue.slice(0, 6);
                      setFieldValue(inputProps.name, truncatedValue);
                    }}
                    numInputs={6}
                    className="form-control"
                    renderSeparator={renderSeparator}
                    renderInput={(props) => (
                      <input
                        {...props}
                        name="otp"
                        className={`form-control form-square ${
                          touched[inputProps.name] && errors[inputProps.name]
                            ? "is-invalid"
                            : ""
                        }`}
                        maxLength={6} // Set maximum length to 6
                      />
                    )}
                    inputType="tel"
                  />
                </>
              )}

              <ErrorMessage
                name={inputProps.name}
                component="div"
                className={
                  touched[inputProps.name] && errors[inputProps.name]
                    ? "text-danger"
                    : "text-danger"
                }
              />
              {inputProps.type === "react-otp-input" && (
                <div className="text-center mt-5">
                  <ResendOTPTimer onSuccess={() => setFieldValue("otp", "")} />
                </div>
              )}
            </div>
          ))}
          {errors.Message && (
            <div className={"text-danger"}>{errors.Message}</div>
          )}

          {referError && <div className={"text-danger"} style={{
            fontSize:"1.2rem",
            marginTop:"-20px",
            marginLeft:"10px",
            marginBottom:"10px",
            textAlign:"left"
          }}>{referError}</div>}

          <div className={`form-footer ${formFooterClass}`}>
            <Button
              type="submit"
              variant="primary"
              className={`btn-block py-4 ${submitBtnClass} ${gaClass}`}
              id={gaClass}
              onClick={() => handleButtonClick(errors)}
              disabled={buttonDisabled}
            >
              {ButtonText ? ButtonText : t.common.submit}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CommonFormikForm;
