import * as yup from "yup";
import { REGEX } from "../constants/validation-regex";

//OTP VALIDATION
export const otpValidationSchema = (t) => {
  return yup.object({
    otp: yup
      .string()
      .required(t.validation_messages.required["otp"])
      .test(
        "otp-digit-length",
        () => t.validation_messages.valid['otp'],
        (value) => {
          if (value) {
            if (`${value}`.length !== 6) {
              return false;
            }
          }
          return true;
        }
      ),
  });
}

//sign-up  validations
export const signUpValidationSchema = (t) => {
  return yup.object({
    name: yup
      .string()
      .matches(REGEX.name, t.validation_messages.valid["name"])
      .nullable(),
    // mobile: yup
    //   .string()
    //   .min(10, `${t.validation_messages.valid["mobile"]}`)
    //   .max(10, `${t.validation_messages.valid["mobile"]}`)
    //   .matches(REGEX.mobile, t.validation_messages.valid["mobile"])
    //   .required(t.validation_messages.required["mobile"]),
    email: yup
      .string()
      .email(t.validation_messages.valid["email"])
      .matches(REGEX.email, t.validation_messages.valid["email"]),
    AgreeTerms: yup.bool().oneOf([true], t.validation_messages.required["terms_conditions"]),
    // referalCode : yup.string()
    // .required(t.validation_messages.required["refer_code"]),
  });
}

//address validation schema
export const addressValidationSchema = (t) => {
  return yup.object({
    AddressOne: yup.string()
      .matches(REGEX.Address, t.validation_messages.valid["address"])
      .required(t.validation_messages.required['adress_line1']),
    AddressTwo: yup.string()
      .matches(REGEX.Address, t.validation_messages.valid["address"])
      .nullable(),
    Near_Landmark: yup.string()
      .matches(REGEX.Address, t.validation_messages.valid["address"])
      .nullable(),
    State: yup.string()
      .required(t.validation_messages.required["state"]),
    City: yup.string()
      .required(t.validation_messages.required["city"]),
    Pincode: yup.string()
      .length(6, t.validation_messages.valid["pincode"])
      .required(t.validation_messages.required["pincode"]),
    shippingMobile: yup
      .string()
      .min(10, `${t.validation_messages.valid["mobile"]}`)
      .max(10, `${t.validation_messages.valid["mobile"]}`)
      .matches(REGEX.mobile, t.validation_messages.valid["mobile"])
      .nullable()
  });
}


export const createValidationSchema = (isPassportFieldShow, isPanFieldShow, t, activeTab) => {
  return yup.object({
    // Name: yup.string()
    //   .required("Name is required")
    //   .matches(REGEX.name, t.validation_messages.valid["name"]),
    // mobile: yup
    //   .string()
    //   .min(10, `${t.validation_messages.valid["mobile"]}`)
    //   .max(10, `${t.validation_messages.valid["mobile"]}`)
    //   .matches(REGEX.mobile, t.validation_messages.valid["mobile"])
    //   .required(t.validation_messages.required["mobile"]),
    // Email: yup
    //   .string()
    //   .email(t.validation_messages.valid["email"])
    //   .matches(REGEX.email, t.validation_messages.valid["email"])
    //   .required(t.validation_messages.required["email"]),
    PassNo: isPassportFieldShow ? yup.string()
      .required(t.validation_messages.required["passport"])
      .matches(REGEX.Passport, t.validation_messages.valid["passport"]) : null,
    Govid: isPanFieldShow ? yup.string()
      .required(t.validation_messages.required["govid"])
      .matches(REGEX.Govid, t.validation_messages.valid["govid"]) : null,
    termsAgreed: yup.boolean()
      .oneOf([true],t.validation_messages.required["terms_condition"])
      .required(t.validation_messages.required["terms_condition"]),
    Title: yup.string().required(t.validation_messages.required["title"]),
    Language: yup.string().required(t.validation_messages.required["language"]),

  });
};

//claim form validation
export const ClaimFormValidation = (isPassportFieldShow, isPanFieldShow, t, activeTab) => {
  return yup.object({
    PassNo: isPassportFieldShow ? yup.string()
      .required(t.validation_messages.required["passport"])
      .matches(REGEX.Passport, t.validation_messages.valid["passport"]) : null,
    Govid: isPanFieldShow ? yup.string()
      .required(t.validation_messages.required["govid"])
      .matches(REGEX.Govid, t.validation_messages.valid["govid"]) : null,
       Name: yup.string()
      .required(t.validation_messages.valid["name"])
      .matches(REGEX.name, t.validation_messages.valid["name"]),
       Email: yup
      .string()
      .email(t.validation_messages.valid["email"])
      .matches(REGEX.email, t.validation_messages.valid["email"])
      .required(t.validation_messages.required["email"]),

    // termsAgreed: yup.boolean()
    //   .oneOf([true], 'You must agree to the terms and conditions')
    //   .required('You must agree to the terms and conditions'),

  });
};

//clear filter validation
export const clearFilterValidation = yup.object().shape({
  lang: yup.string().required('Language is required'),
  category: yup.string().required('Category is required'),
});

//login form validation
export const loginValidationSchema = (t) => {
  return yup.object({
    mobile: yup
      .string()
      .min(10, `${t.validation_messages.valid["mobile"]}`)
      .max(10, `${t.validation_messages.valid["mobile"]}`)
      .matches(REGEX.mobile, t.validation_messages.valid["mobile"])
      .required(t.validation_messages.required["mobile"]),
  });
}

//unique code form validation
export const uniqueCodeValidationSchema = (t) => {
  return yup.object({
    UniqueCode: yup
      .string()
      .matches(REGEX.Code, t.validation_messages.valid["code"])
      .required(t.validation_messages.required["code"]),
  });
}

//mobile number validation for refer friend
export const mobileNoValidationSchema = (t) => {
  return yup.object({
    mobile: yup
      .string()
      .matches(REGEX.mobile, t.validation_messages.valid["mobile"])
      .required(t.validation_messages.required["mobile"]),
  });
}

//refer code validation
export const referCodeValidationSchema = (t) => {
  return yup.object({
    code: yup
      .string()
      .required(t.validation_messages.required["refer_code"]),
  });
}

export const sourceUrlValidationSchema = (t) => {
  return yup.object({
    url: yup
      .string()
      .matches(REGEX.url, t.validation_messages.valid["url"])
      .required(t.validation_messages.required["url"]),
  });
}

//edit profile validation
export const profileCardSchema = (t) => {
  return yup.object({
    name: yup
      .string()
      .nullable() // Allow undefined or empty string values
      .matches(REGEX.name, t.validation_messages.valid["name"]),
    mobile: yup
      .string()
      .nullable() // Allow undefined or empty string values
      .min(10, `${t.validation_messages.valid["mobile"]}`)
      .max(10, `${t.validation_messages.valid["mobile"]}`)
      .matches(REGEX.mobile, t.validation_messages.valid["mobile"]),
    email: yup
      .string()
      .nullable() // Allow undefined or empty string values
      .email(t.validation_messages.valid["email"])
      .matches(REGEX.email, t.validation_messages.valid["email"]),
    dob: yup.date()
      .nullable() // Allow undefined or empty string values
      .test('is-over-18', t.validation_messages.valid['age'], function (value) {
        // Check if the value is provided before comparing dates
        if (!value) {
          return true; // Allow empty or undefined values
        }
        const currentDate = new Date();
        const minBirthDate = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
        return new Date(value) <= minBirthDate;
      }),
    alternate_mobile: yup
      .string()
      .min(10, `${t.validation_messages.valid["mobile"]}`)
      .max(10, `${t.validation_messages.valid["mobile"]}`)
      .matches(REGEX.mobile, t.validation_messages.valid["mobile"])
      .nullable(),
  })
}
