import { AxiosInstance } from "../utils/axios-helper/axios-helper";
import { version } from "../config";
import { API_END_POINT } from "../utils/constants/api-end-points";

export const getNotificationsAPI = async (data) => {
  return await AxiosInstance.get(`${version}${API_END_POINT.getAllNotification}`,data);
};

export const getNotificationCount = async (data) => {
  return await AxiosInstance.get(`${version}${API_END_POINT.getNotificationCount}`,data);
};

export const updateNotificationStatus = async (data) => {
  return await AxiosInstance.get(`${version}${API_END_POINT.updateNotificationStatus}`,data);
};

export const readNotificationByIdAPI = async (data) => {
  return await AxiosInstance.post(`${version}${API_END_POINT.readNotification}?notificationId=${data.notificationId}`);
};

export const readAllNotifications = async () => {
  return await AxiosInstance.get(`${version}${API_END_POINT.readNotification}`);
};

export const deleteNotiById = async (data) => {
  return await AxiosInstance.post(`${version}${API_END_POINT.deleteNotificationById}?notificationId=${data.notificationId}`);
};

export const deleteAllNotifications = async () => {
  return await AxiosInstance.post(`${version}${API_END_POINT.deleteAllNotification}`);
};



