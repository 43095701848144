import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage, setLanguageID } from "../../store/slices/common-slice";
import translations from "../../lang";

function useTranslation() {
  const dispatch = useDispatch();

  const currentLanguage = useSelector((state) => state.common.currentLanguage);

  const t = useMemo(() => {
    // Check if the translation exists; if not, fall back to a default language or provide an error message.
    if (currentLanguage && translations[currentLanguage]) {
      return translations[currentLanguage];
    } else {
      console.warn(
        `Translation not found for language: ${currentLanguage}. Falling back to 'en'.`
      );
      return translations["en"];
    }
  }, [currentLanguage]);

  const changeLanguage = (lang, id) => {
    // You might want to add validation to ensure 'lang' is a valid language code.
    dispatch(setLanguage(lang));
    dispatch(setLanguageID(id));
    window.location.reload();
  };

  return { t, changeLanguage, currentLanguage };
}

export default useTranslation;
