import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import AppModal from "./index";
import useTranslation from "../../utils/hooks/use-translation";
import UgcListingCard from "../../pages/UgcListingCard";
import CommonCardFooter from "../footer/CommonCardFooter";
import { GENREID } from "../../config";
import { getJokeByUserId } from "../../services";
import VideoPlayer from "../common/VedioPlayer";
import gluedin from "gluedin";
import TitleShowCase from "../common/TitleShowCase";
import { setGenereID } from "../../store/slices/common-slice";
import { useLocation } from "react-router-dom";
import { displaySuprise } from "../../store/slices/suprise-me";

const SurpriseMe = ({
  show,
  onClose,
  jokeLogo,
  surpriseLogo,
  image,
  icon,
  card,
  footercontent1,
  footercontent2,
  welcomeMessage,
  isLoggedIn,
  iconType,
  gaClass,
  isJokeOfTheDay,
  setReactToJokePopup,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const [jokeData, setJokeData] = useState([]);
  const [isLimitExhusted, setIsLimitExhusted] = useState(false);
  const [activeEmoji, setActiveEmoji] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [viewCounter, setViewCounter] = useState(0);
  const videoRef = useRef(null);
  const [contentDescription, setContentDescription] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [reactionsState, setReactionsState] = useState({
    laugh: 0,
    neutral: 0,
    sad: 0,
  });
  const [isReacted, setIsReacted] = useState(false);

  const currentLanguageID = useSelector(
    (state) => state.common.currentLanguageID
  );
  const animationShow = useSelector((state) => state.common.animationShow);
  const genereId = useSelector((state) => state.common.genereID);

  useEffect(() => {
    if (isLoggedIn) {
      getJoke();
    }
  }, []);

  useEffect(() => {
    if (jokeData?.jokeDetails?.jokeContentType === "text") {
      setContentDescription(jokeData?.joke);
    }
    if (jokeData) {
      setViewCounter(jokeData?.jokeDetails?.viewsCount);
    }
  }, [jokeData]);

  const renderDescription = (description) => {
    return (
      <>
        {description.length > 100 && (
          <>
            <button
              className="text-b show-more-content"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? "Show Less" : "Show More"}
            </button>
          </>
        )}
      </>
    );
  };

  const viewCountHandler = () => {
    var activityTimelineModuleObj = new gluedin.GluedInActivityTimeline();
    activityTimelineModuleObj
      .activityTimelineView({ assetId: jokeData?.jokeId })
      .then(() => {
        setViewCounter((prev) => prev + 1);
      });
  };

  useEffect(() => {
    if (jokeData) {
      setViewCounter(jokeData?.jokeDetails?.viewsCount);
    }
  }, [jokeData]);

  const getJoke = async () => {
    try {
      const userId = localStorage.getItem("userId");
      let jokeReqData = {
        userId: userId,
        languageId: currentLanguageID || "1",
        genreId: genereId ? genereId : GENREID,
      };

      const res = await getJokeByUserId(jokeReqData);

      if (isLimitExhusted) {
        setIsLimitExhusted(false);
      } else {
        window.dataLayer.push({ event: "Sprite24_SurpriseMe" });
        setJokeData(res?.data?.data);

        const actityvityTimelineModule = new gluedin.GluedInActivityTimeline();
        const response = await actityvityTimelineModule.activityTimelineUnlock({
          assetId: res?.data?.data?.jokeId,
        });

        const reactions = res?.data?.data?.jokeDetails?.reaction;
        setReactionsState({
          laugh: reactions?.laugh || 0,
          neutral: reactions?.neutral || 0,
          sad: reactions?.sad || 0,
        });
      }
      setLoaded(true);
    } catch (err) {
      setIsLimitExhusted(true);
      setLoaded(true);
    }
  };

  const renderContent = (contentType, jokeAssetId, currentViews) => {
    if (jokeData) {
      if (contentType === "image") {
        return <img src={jokeData?.joke} alt="Icon" className="w-100 img" />;
      } else if (contentType === "video") {
        return (
          <VideoPlayer
            videoURL={jokeData?.joke}
            videoRef={videoRef}
            isCoinWinFeatureAllowed={false}
            id={jokeData?.jokeId}
            viewCountHandler={viewCountHandler}
            viewsCount={jokeData?.jokeDetails?.viewsCount}
          />
        );
      } else if (contentType === "audio") {
        return <audio src={jokeData?.joke} controls autoPlay />;
      } else if (contentType === "text") {
        return (
          <div
            className={`c-text ${contentType === "text" ? "text-post" : ""}`}
          >
            <p>
              {contentType === "text"
                ? contentDescription?.length > 325
                  ? contentDescription?.substring(0, 325)
                  : contentDescription
                : showMore && contentType !== "text"
                ? contentDescription
                : `${contentDescription.substring(0, 100)}`}
              {contentType !== "text"
                ? renderDescription(contentDescription)
                : ""}
            </p>
          </div>
        );
      }
    } else if (!isLimitExhusted) {
      dispatch(setGenereID(1));
      return (
        <>
          <div className="text-center modal-nojoke">
            <h5>
              No jokes available for this genre.
              <br /> Please try another genre.
            </h5>
          </div>
        </>
      );
    }
  };

  // // function to check if any reactions have occured
  // const areAllReactionsZero = () => {
  //   return Object.values(reactionsState).every((value) => value === 0);
  // };

  const handleClose = () => {
    if (jokeData && Object.keys(jokeData).length > 0 && !isReacted) {
      setReactToJokePopup(true);
    } else {
      onClose();
    }
  };

  return (
    (loaded || !isLoggedIn) &&
    location.pathname !== "/login" && (
      <AppModal
        className=""
        show={show}
        headerClass="py-3"
        animationShow={animationShow}
        heading={
          !isLimitExhusted && (
            <TitleShowCase
              showCardReport={false}
              jokeGenre={jokeData?.jokeDetails?.jokeGenre}
              jokeLang={jokeData?.jokeDetails?.jokeLanguage}
              jokeTitle={jokeData?.jokeDetails?.title}
              author={jokeData?.jokeDetails?.jokeCreator}
              isLoggedIn={isLoggedIn}
            />
          )
        }
        onClose={isLoggedIn && !isLimitExhusted ? handleClose : onClose}
        dialogClassName={
          isLimitExhusted ? "modal-onboard modal-sm" : "modal-onboard"
        }
        bodyClass="px-3 py-0  pb-3 modal-surprise"
        size=""
      >
        {console.log(
          "jokeData?.jokeDetails?.title: ",
          jokeData?.jokeDetails?.title
        )}
        <div className="text-center">
          {isLimitExhusted ? (
            <>
              <img
                src="/assets/img/modal/serial chillar.svg"
                alt="serial-chillar "
                className="mt-n5"
              />
              <h3 className="modal-title mt-2">
                {`${t.model_messages.serial_chiller}`}! 👀
              </h3>
              <p className=" modal-alert modal-spacing ">
                {`${t.model_messages.exhausted_of_jokes}`}
                <br />
              </p>
              <p className="modal-alert modal-spacing">
                {`${t.model_messages.come_back_tomorrow}`}
                <br />
              </p>
            </>
          ) : (
            <div className="mt-n5  mb-1">
              {jokeLogo && (
                <img
                  src={isJokeOfTheDay ? jokeLogo : surpriseLogo}
                  alt="Icon"
                  className="logo"
                />
              )}
              {!isLoggedIn ? (
                <div>
                  <img src={image} alt="Icon" className="w-100 img" />
                  <img src={iconType} alt="icon" className="icon-type" />
                </div>
              ) : (
                renderContent(
                  jokeData?.jokeDetails?.jokeContentType,
                  jokeData?.jokeId,
                  jokeData?.jokeDetails?.viewsCount
                )
              )}
              {!isLoggedIn && (
                <a
                  href="/login"
                  className="lock"
                  onClick={() => {
                    dispatch(displaySuprise(false));
                  }}
                >
                  {icon && <img src={icon} alt="Icon" className="icon-lock" />}
                </a>
              )}
              {welcomeMessage && (
                <div className="footer-wrapper">
                  {footercontent1 && (
                    <p className="modal-alert mb-0 pt-1 text-left">
                      {footercontent1}
                    </p>
                  )}
                  {footercontent2 && (
                    <p className="modal-alert mb-0 text-left">
                      {footercontent2}
                    </p>
                  )}
                </div>
              )}
              {card && <UgcListingCard />}
              {isLoggedIn && jokeData && (
                <CommonCardFooter
                  videoId={jokeData.jokeId}
                  activeEmoji={activeEmoji}
                  setActiveEmoji={setActiveEmoji}
                  viewsCount={viewCounter}
                  reactionsState={reactionsState}
                  setReactionsState={setReactionsState}
                  setIsReacted={setIsReacted}
                  viewCountHandler={viewCountHandler}
                  contentType={jokeData?.jokeDetails?.jokeContentType}
                />
              )}
            </div>
          )}
        </div>
      </AppModal>
    )
  );
};

export default React.memo(SurpriseMe);
