import { createContext, useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import BottleLoader from "../../components/common/BottleLoader";

export const BottleLoaderContext = createContext();

export const BottleLoaderProvider = ({ children }) => {
  const loading = useSelector((state) => state.common.bottleLoading);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const toggleBottleLoader = useCallback((action) => {
    setIsLoading((prevLoading) =>
      action !== undefined && typeof action === "boolean"
        ? action
        : !prevLoading
    );
  }, []);

  return (
    <BottleLoaderContext.Provider value={{ isLoading, toggleBottleLoader }}>
      {children}
      <BottleLoader isLoading={isLoading} />
    </BottleLoaderContext.Provider>
  );
};
