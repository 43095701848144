import React from "react";

function BottleLoader({ isLoading }) {
  // const { isLoading } = useContext(LoaderContext);

  if (isLoading) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(255, 255, 255, 0.2)",
          zIndex: 999999,
        }}
      >
        {isLoading ? (
          <img
            className="bottle d-block"
            src="/assets/img/animation/bottle-sprite-t.gif"
            alt="Bottle Animation"
            style={{
              height:"350px",
              maxWidth:"auto"
            }}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }

  return null;
}

export default BottleLoader;
