import React from "react";
import AppModal from "./index";
import useTranslation from "../../utils/hooks/use-translation";

import RewardCard from "../cards/RewardCard";
import { useSelector } from "react-redux";

const VoucherModal = ({ show, onClose }) => {
  const { t } = useTranslation();
  const rewardCardsData = useSelector((state) => state?.popUp?.voucherData);

  return (
    <AppModal
      show={show}
      dialogClassName="modal-reward-code"
      onClose={onClose}
      bodyClass="px-1 pt-2 pb-0 modal-response modal-voucher-code"
      size="md"
    >
      <>
        {rewardCardsData && (
          <RewardCard
            key={0}
            className="modal-reward"
            cardClassName="text-center"
            cardImage={rewardCardsData?.imageUrl}
            title={t.common.surprise_alert}
            description={t.common.earned_ten_rupee_voucher}
            cardAlt={t.common.surprise_alert}
            variant="top"
            points={rewardCardsData?.coinsEarned}
            fullFilled={false}
            rewardLogo={"https://www.phonepe.com/"}
            redirectUrl={"/reward-1"}
            isInModalUnique={true}
            voucherCode={rewardCardsData?.voucherCode}
            isPopReward={false}
            isRewardPopup={true}
            pinCode={rewardCardsData?.secureCode}
            button={{ buttonLanguageValue: rewardCardsData?.utilize }}
            expiration={rewardCardsData?.lastDateToRedeem}
            eligibleOrNot={true}
            modalUniqueClose={onClose}
            voucherId={rewardCardsData?.voucherId}
            {...rewardCardsData}
          />
        )}
      </>
    </AppModal>
  );
};

export default VoucherModal;
