export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const version = "v1";
export const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;
export const X_API_KEY = process.env.REACT_APP_X_API_KEY;
export const GLUEDIN_KEY = process.env.REACT_APP_GLUEDIN_KEY;
export const GLUEDIN_SECRET = process.env.REACT_APP_GLUEDIN_SECRET;
export const LANGUAGEID = 1;
export const GENREID = 0;
export const rewardType = {
  airpods: "Airpods",
  iphone: "iPhone",
  lollapalooza: "Lollapalooza",
  tomorrowland: "Tomorrowland",
};
export const RailId = {
  giggle_central: "a0d98c41-f4bd-4cbd-a06b-2f5b186557f7",
  comedians: process.env.REACT_APP_GLUEDIN_COMEDIAN_RAIL
};
