import en from "../lang/en.json"; // English
import hi from "../lang/hi.json"; //Hindi
import te from "../lang/te.json"; // Tamil
import ba from "../lang/ba.json"; //Bangla
import kn from "../lang/kn.json"; //Kannad
import or from "../lang/or.json"; //oriya
import mr from "../lang/mr.json"; //marathi
// import gu from "../lang/gu.json"; //gujrati
import bho from "../lang/bho.json"; //bhojpuri
import mai from "../lang/mai.json"; //maithali

const translations = {
  en,
  hi,
  te,
  ba,
  kn,
  or,
  mr,
  // gu,
  bho,
  mai,
};

export default translations;
