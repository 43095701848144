import { AxiosInstance } from "../utils/axios-helper/axios-helper";
import { API_END_POINT } from "../utils/constants/api-end-points";

export const getContestData = async (data) => {
  return await AxiosInstance.get(
    `${API_END_POINT.getContest}?languageId=${data.languageId}`
  );
};

export const getContestV1Data = async (data) => {
  return await AxiosInstance.get(
    `${API_END_POINT.getContestV1}?languageId=${data.languageId}`
  );
};

export const getAssuredWinsData = async (data) => {
  return await AxiosInstance.get(
    `${API_END_POINT.getAssuredWins}?languageId=${data.languageId}`
  );
};

export const getAssuredWinsDataFromVoucherId = async (voucherId) => {
  return await AxiosInstance.get(
    `${API_END_POINT.getAssuredWinsById}?voucherId=${voucherId}`
  );
};
