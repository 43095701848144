import React from "react";
import SectionHeader from "../../components/common/SectionHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { displayInviteCode } from "../../store/slices/invite-code";
import useAuth from "../../utils/hooks/use-auth";
import { setReferFriend } from "../../store/slices/common-slice";
import ContestUniqueCode from "../../components/common/ContestUniqueCode";

const SpritePoints = ({ spritePointsHeader, data }) => {
  const { isLoggedIn } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleClick(item) {
    if (item.redirectURL !== "") {
      if (
        item.redirectURL === "/my_profile" &&
        item.condition === "Referred a Friend" &&
        isLoggedIn
      )
        dispatch(setReferFriend(true));
      // navigate(item.redirectURL, { state: { showRefer: true } });
      else if (item.redirectURL === "invite-code") {
        if (isLoggedIn) {
          dispatch(displayInviteCode(true));
        } else {
          navigate("/login");
        }
      } else navigate(item.redirectURL);
    }
  }

  return (
    <div className="sprite-points">
      <SectionHeader {...spritePointsHeader} headerClass="pt-0 " />
      <div className="container-fluid ">
        {/* <div className="tour-code"> */} <ContestUniqueCode />
        {/* </div> */}
        <div className="row tour-gather-cominc" id="sprite_points">
          {data &&
            data.map((reward) => (
              <div className="col-xl-4 col-6 mb-2 mb-md-6">
                <div
                  className="d-flex align-items-center sprite-points-wrapper"
                  onClick={() => handleClick(reward)}
                >
                  <div>
                    <img
                      src={reward.imageUrl}
                      alt="icon"
                      className="sprite-points-icon"
                    />
                  </div>
                  <div className="pl-2">
                    <h3>{reward.text}</h3>
                    <p>
                      <picture>
                        <source
                          srcset="/assets/img/contest/comic_coin@1x.webp 1x, /assets/img/contest/comic_coin@2x.webp 2x"
                          type="image/webp"
                        />

                        <img
                          src="/assets/img/contest/comic_coin@1x.png"
                          srcset="/assets/img/contest/comic_coin@1x.png 1x, /assets/img/contest/comic_coin@2x.png 2x"
                          alt="unique code"
                          className="mr-1"
                          width={18}
                          height={18}
                        />
                      </picture>
                      <span>{reward.winnerCoin}</span> {reward.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SpritePoints;
