import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { formatLargeNumber } from "../utils/helpers";
import { getJokeOwnerData } from "../services";
import useTranslation from "../utils/hooks/use-translation";

const TickleBoard = ({
  header,
  totalCount,
  loadMore,
  setPage,
  page,
  pageSize,
  showPagination = true,
  data = [],
}) => {
  const [updatedData, setUpdatedData] = useState(data);
  const { t } = useTranslation();
  const handlePrevious = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handleNext = () => {
    const maxPage = Math.ceil(totalCount / pageSize);
    if (page < maxPage) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    let jokevideoId = [];
    data.map((joke) => {
      jokevideoId.push(joke.videoId.toLowerCase());
    });
    try {
      getJokeOwnerData(jokevideoId).then((res) => {
        const updatedArray = data.map((item) => {
          const matchingUser = res?.data?.data.find(
            (userItem) => userItem.assestId === item.videoId.toLowerCase()
          );
          if (matchingUser) {
            return { ...item, user: matchingUser.user };
          }
          return item;
        });
        setUpdatedData(updatedArray);
      });
    } catch (err) {
      console.log(err);
    }
  }, [data]);

  return (
    <div className="leaderboard hall-lame">
      {data.length > 0 ? (
        <table className="tickle-table custom-table mb-5 text-left profile-table table table-striped table-bordered table-hover">
          <thead>
            <tr>
              <th>{t.profile.rank}</th>
              <th>{t.common.jokes}</th>
              <th>{t.common.votes}</th>
            </tr>
          </thead>
          <tbody>
            {updatedData &&
              updatedData.slice(0, page * pageSize).map((joke) => (
                <tr className="bg-white">
                  <td>{joke?.rank}</td>
                  <td className="d-flex px-0 align-items-center">
                    <div className="mr-md-5 mr-2">
                      <img
                        src={
                          joke?.thumbnailUrl
                            ? joke?.thumbnailUrl
                            : "/assets/img/icons/polloution-woes.svg"
                        }
                        alt="Joke"
                        className="tickle-table-icon"
                      />
                    </div>
                    <div>
                      <h3>{joke?.title}</h3>
                      <p>{joke?.user}</p>
                      <span className="d-block">{joke?.labels[1]}</span>
                    </div>
                  </td>
                  <td>
                    <span className="d-block date">
                      {joke.updatedTimestamp
                        ? new Date(joke?.updatedTimestamp).toLocaleDateString()
                        : ""}
                    </span>
                    <h2>{formatLargeNumber(joke?.voteCount)}</h2>
                    <p className="mb-0 vote">Votes</p>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <h1 className="pt-8 text-center">No Data Found</h1>
      )}

      {showPagination && (
        <div className="d-flex justify-content-center align-items-center pb-5">
          {page!==1?<Button
            size="sm"
            onClick={handlePrevious}
            className="btn btn-outline-dark mr-2  py-2 px-4 mr-5"
            disabled={page === 1}
          >
            {t.common.previous}
          </Button>
          :
          <></>}
          <Button
            size="sm"
            onClick={handleNext}
            disabled={page === Math.ceil(totalCount / pageSize)}
            className="btn  refer-btns  py-2 px-4"
          >
            {t.common.next}
          </Button>
        </div>
      )}
    </div>
  );
};

export default TickleBoard;
