import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import useTranslation from '../../utils/hooks/use-translation';

const WalletCard = (props) => {

  const { t } = useTranslation();
  const {
    title = '',
    points = '',
    totalPoints = '',
    cardClassName = '',
    handleTierCard,
  } = props;

  const percentage = (points / totalPoints) * 100;

  const barStyle = {
    width: `${percentage}%`,
    height: '5px',
    backgroundColor: '#E41E2B',
    transition: 'width 0.5s ease-in-out',
  };

  const className = `wallet-card ${cardClassName}`;

  return (
    <Card className={className} onClick={handleTierCard}>
      <Card.Body className="wallet-body">
        <Row className="mb-4">
          <Col>
            <h3>{title}</h3>
          </Col>
          <div className="pr-3">
            <img
              className="wallet-img"
              src="/assets/img/icons/wallet_icon.svg"
              alt="Wallet"
            />
          </div>
        </Row>
        <Row className="justify-content-end  pr-3">
          <p className="mb-0">{t.common.required_coins}</p>
        </Row>
        <Row className="justify-content-end  pr-3">
          <img
            className="pr-1"
            src="/assets/img/icons/coin.svg"
            alt="Coin"></img>
          <h6>{totalPoints}</h6>
        </Row>
      </Card.Body>
      <div className="mb-1">
        <div style={{ height: '5px', backgroundColor: '#D9D9D9' }}>
          <div style={barStyle}></div>
        </div>
      </div>
      <div className="mb-4 ">
        <h4 className="pl-4">
          <span className="total-pt">{points}</span>/{totalPoints}
        </h4>
      </div>
    </Card>
  );
};

export default WalletCard;
