import toast from "react-hot-toast";
import { getHomepageDetailsAfterLogin } from "../../services";
import { setHomePageDetails } from "../../store/slices/common-slice";

/**
 * @method apiErrorHandler
 * @description handle api error
 */

let is401ErrorShown = false;

// export const apiErrorHandler = (res) => {
//   if (res && res.message) {
//     if(res?.message !== "Max limit exhausted for UGC upload") {
//       toast.error(res.message);
//     }
//   } else if(res?.statusCode!==401) {
//     toast.error("Something went wrong please try again later");
//   }
// };


export const apiErrorHandler = (res) => {
  const status = res?.StatusCode || res?.status;
  let message = res?.data?.message || res?.message || "Something went wrong";
  if (status !== 200) {
    if (!is401ErrorShown) {
      is401ErrorShown = true;
      toast.error(message || "Unauthorized access");
    }
  } else {
    toast.error(message);
  }
  setTimeout(() => (is401ErrorShown = false), 8000);
};


//get all home page after login
export const getAllHomepageDetailsALogin = async (
  dispatch,
  toggleLoader,
  currentLanguageID
) => {
  toggleLoader(true);
  try {
    let reqData = { languageId: currentLanguageID || "1" };

    const res = await getHomepageDetailsAfterLogin(reqData);
    if (res.status === 200 && res.data.succeeded) {
      let section = res.data?.data?.sections;
      let notificationCount = res.data?.data?.newNotification;
      let coinCount = res.data?.data?.walletCoins;
      const updatedPayload = {
        sections: [...section], // Replace with the actual updated array
        notificationCount: notificationCount,
        coinCount: coinCount,
      };
      dispatch(setHomePageDetails(updatedPayload));
    }
  } catch (err) {
  } finally {
    toggleLoader(false);
  }
};
