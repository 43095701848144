import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import useAuth from "../../../utils/hooks/use-auth";

function ProfileImageSelector({ selected = "", setFieldValue }) {
  const { userDetails } = useAuth();
  const avatarList = useSelector((state) => state?.common?.avatarList);
  const avatarDataList = Object.values(avatarList);
  const [showAvatarDropdown, setShowAvatarDropdown] = useState(false);
  const avatarRef = useRef(null);

  const isDisplayEditIcon = true;

  useEffect(() => {
    if (selected) {
      setShowAvatarDropdown(false);
    }
  }, [selected]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (avatarRef.current && !avatarRef.current.contains(event.target)) {
        setShowAvatarDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleProfileSelected = (name, value, type, avatarObj) => {
    if (name && value) {
      const tempImg = new Image();
      tempImg.src = value;
      tempImg.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = 51;
        canvas.height = 51;
        ctx.drawImage(tempImg, 0, 0, 53, 53);

        if (type === "icon") {
          setFieldValue(name, value);
          setFieldValue("profileId", avatarObj.id);
        } else {
          const resizedImage = canvas.toDataURL("image/jpeg");
          setFieldValue(name, resizedImage);
        }
      };
    }
  };

  const handleToggleAvatarDropdown = () => {
    setShowAvatarDropdown(!showAvatarDropdown);
  };

  return (
    <div
      className="position-relative d-flex justify-content-center"
      ref={avatarRef}
    >
      <div
        className="avatar-wrapper dropdown-arrow-disable"
        onClick={handleToggleAvatarDropdown}
      >
        <img
          className="avatar-img"
          src={
            selected
              ? selected
              : userDetails && userDetails.avatarURL
              ? userDetails.avatarURL
              : "/assets/img/icons/profile-image.svg"
          }
          alt="profile"
        />

        {isDisplayEditIcon && (
          <img
            className="avatar-edit"
            src="/assets/img/icons/avatar-edit.svg"
            alt="edit"
          />
        )}
      </div>

      {showAvatarDropdown && (
        <div className="position-absolute avatar-select-wrapper d-flex flex-wrap align-items-center">
          {avatarDataList.map((a) => (
            <img
              className="avatar-img"
              src={a.imageURL}
              alt="avatar"
              onClick={() =>
                handleProfileSelected("profileImage", a.imageURL, "icon", a)
              }
              key={a.id}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default ProfileImageSelector;
