import React from "react";
import SectionHeader from "./SectionHeader";
import { useNavigate } from "react-router";
import { useViewport } from "../../utils/context/viewport-context";
import Header from "../header";
import { useLocation } from "react-router-dom";
import LanguageSwitcher from "../common/languageSwitcher";

const CommonSection = ({ children, ...props }) => {
  const { isMobile } = useViewport();
  const { pathname } = useLocation();
  const { headerContent = {}, headerType = "", sectionClass = "" } = props;
  const navigate = useNavigate();
  const isHeaderVisible = ["/login", "/signup", "/otp_verification"].includes(
    pathname
  );
  const { pageHeaderClass = "", headerClass = "", containerClass = "" } = props;
  return (
    <>
      {!isMobile && isHeaderVisible && headerType === 1 && <Header />}
      {isMobile && (
        <div className={`page-header ${pageHeaderClass}`}>
          {/* Back button  notification*/}
          {headerType !== 2 && (
            <span
              className="icon icon-back icon-Back-01"
              onClick={() => navigate(-1)}
            ></span>
          )}
          {/* Enable for logo only  login */}
          {headerType === 1 && (
            <>
              <div className="page-header-bottom row pt-0">
                <div className="col-9 text-center pr-0">
                  <img
                    className="page-header-logo ml-md-0 ml-4  page-header-logo-md"
                    src="/assets/img/icons/header-logo.svg"
                    alt="Logo"
                    onClick={() => navigate("/")}
                  />
                </div>
                <div className=" d-lg-none col-3 text-right px-0">
                  <LanguageSwitcher />
                </div>
              </div>
            </>
          )}
          {/* Enable for logo and close button  claim header*/}
          {headerType === 2 && (
            <div className="page-header-bottom">
              <img
                className="page-header-logo"
                src="/assets/img/icons/header-logo.svg"
                alt="Logo"
                onClick={() => navigate("/")}
              />
              <span
                className="icon-close icon-Cross-01"
                onClick={() => navigate(-1)}
              ></span>
            </div>
          )}
          {/* Enable section header  */}
          {headerContent &&
            headerType === 3 &&
            Object.keys(headerContent).length > 0 && (
              <SectionHeader
                {...headerContent}
                headerClass={`pt-4 pb-0 ${headerClass}`}
                containerClass={"px-0"}
              />
            )}
        </div>
      )}

      <div className={`container-fluid ${containerClass}`}>
        <div className="row justify-content-center mx-0">
          <section className={`col-12    ${sectionClass}`}>
            <img src="/assets/img/icons/sprite-bottle.svg" alt="sprite bottle" className="bottle" />
            {children}
          </section>
        </div>
      </div>
    </>
  );
};

export default CommonSection;
