import React, { useState } from "react";
import { Card, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../config";
import { useViewport } from "../../utils/context/viewport-context";
import useTranslation from "../../utils/hooks/use-translation";
import TimerComponent from "../common/Timer";
import CountdownTimer from "../common/CountDownTimer";
import { getAssuredWinsDataFromVoucherId, redeemCode } from "../../services";
import useLoader from "../../utils/hooks/use-loader";
import { ShareButton } from "../Button/ShareButton";

const BlurRewardCard = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    title = "",
    description = "",
    cardClassName = "",
    variant = "",
    cardImage = "",
    cardImageClassName = "",
    redirectUrl,
    button,
    eligibleOrNot,
    fullFilled,
    expiration,
    winnerRewardId,
    rewardClassName,
    utilize,
    tooltipInfo,
    voucherCode,
    pinCode = "",
    showTooltip,
    isInModalUnique = false,
    onClose = () => {},
    isPopReward = false,
    lastDate = "",
    points = "",
    modalUniqueClose = () => {},
    imgStyle = {},
    cardStyle = {},
    redeemRedirection = "",
    isRewardPopup = false,
    voucherId = "",
    information = "",
  } = props;

  const { isMobile } = useViewport();

  let imagebaseURL = isMobile
    ? `${IMAGE_BASE_URL}/Rewards/Mobile/`
    : `${IMAGE_BASE_URL}/Rewards/Desktop/`;

  const targetTime = new Date("2024-01-10T23:59:59").getTime();

  const [scrolling, setScrolling] = useState(false);

  const { toggleLoader } = useLoader();

  const copyToClipboard = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy);
  };

  function formatDate(inputDate) {
    const [day, month, year] = inputDate.split("-");
    const date = new Date(year, month - 1, day);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    function getOrdinalSuffix(day) {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    const formattedDate = `${day}${getOrdinalSuffix(day)} ${
      monthNames[date.getMonth()]
    } ${year}`;

    return formattedDate;
  }

  const tooltip = (
    <Tooltip id="tooltip">
      <p
        style={{
          fontSize: "12px",
        }}
        className="mb-0 reward-para"
        dangerouslySetInnerHTML={{
          __html: information
            ? information
            : title.includes("25") || title.includes("Ultimate")
            ? t.common.price_announced_daily
            : t.common.winevery_unique_code,
        }}
      />
    </Tooltip>
  );

  const sendRedeemCode = async (rewardId) => {
    toggleLoader(true);
    try {
      const res = await redeemCode(rewardId);
      window.open(redirectUrl, "_blank");
    } catch (err) {
      window.open(redirectUrl, "_blank");
    } finally {
      toggleLoader(false);
    }
  };
  const renderDescription = () => {
    const parts = description.split("Rs.25000");
    return (
      <>
        {parts[0]}
        {parts.length === 2 && (
          <span style={{ fontWeight: "bold" }}>
            <br />
            Rs.25000
          </span>
        )}
        {parts[1]}
      </>
    );
  };
  return (
    <div className={`reward-card ${rewardClassName}`} {...props}>
      <Card className={`${cardClassName} card-reward`}>
        <div className={`card-top-img-wrapper position-relative`}>
          <div className="d-flex justify-content-end align-items-start w-100">
            {cardImage ? (
              <Card.Img
                variant={variant}
                // src={`${imagebaseURL}${cardImage}`}
                style={imgStyle}
                src={
                  isPopReward
                    ? "/assets/img/voucher-10.png"
                    : `${imagebaseURL}${cardImage}`
                }
                className={cardImageClassName}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <Card.Body className="d-flex justify-content-between flex-column">
          <div>
            <Card.Title as={"h4"}>
              {title}
              {showTooltip && !scrolling && (
                <OverlayTrigger placement="bottom" overlay={tooltip}>
                  <img
                    src="/assets/img/icons/info-icon.svg"
                    alt="info-icon ml-4"
                    className="info-icon"
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
              )}
            </Card.Title>
            <Card.Text className={`pb-3 pb-md-6 pt-1 pt-md-2 voucher-message`}>
              {renderDescription()}
            </Card.Text>
          </div>
          <div className="link-wrapper">
            {/* {!fullFilled ? ( */}
            <>
              {utilize === "Redeem" || utilize === "Claim" ? (
                <>
                  <div className="or-copy-link d-flex p-1 justify-content-center mb-1 mb-md-2">
                    <input
                      className="w-100"
                      value={voucherCode}
                      type="text"
                      readOnly
                    />
                    <button
                      onClick={() => {
                        copyToClipboard(voucherCode);
                      }}
                      className="copy-btn btn-primary"
                    >
                      {t.common.copy_code}
                    </button>
                  </div>
                  <div className="or-copy-link d-flex p-1 justify-content-center mb-1 mb-md-2">
                    <input
                      className="w-100"
                      value={pinCode}
                      type="text"
                      readOnly
                    />
                    <button
                      onClick={() => {
                        copyToClipboard(pinCode);
                      }}
                      className="copy-btn btn-primary"
                    >
                      {t.common.copy_pin}
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
              {/* Collect more button  */}
              {!utilize && (
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => {
                    navigate("/contest");
                  }}
                >
                  {t.common.collect_now}
                </Button>
              )}
              {utilize === "Redeem" && !fullFilled && (
                <div className="d-flex button-wrapper">
                  {isInModalUnique ? (
                    <ShareButton
                      title={title}
                      description={description}
                      imgUrl={`/assets/img/reward-share/JIAB-phone-pay.png`}
                      type={"PHONEPE"}
                    />
                  ) : (
                    ""
                  )}
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={async () => {
                      if (utilize === "Redeem") {
                        if (redirectUrl === "https://phonepe.com") {
                          await getAssuredWinsDataFromVoucherId(voucherId);
                        }
                        window.open(redirectUrl, "_target");
                      } else if (!eligibleOrNot) {
                        navigate("/contest");
                      } else if (utilize === "Claim") {
                        navigate(`/claim_form/${winnerRewardId}`, {
                          state: {
                            validRedirection: "valid",
                            isPanFieldShow: true,
                          },
                        });
                      } else {
                        const absoluteUrlPattern = /^(http|https):\/\//;
                        const url = absoluteUrlPattern.test(redirectUrl)
                          ? redirectUrl
                          : `https://${redirectUrl}`;
                        window.open(url, "_blank");
                        // navigate(redirectUrl);
                        sendRedeemCode(winnerRewardId);
                      }
                      onClose();
                    }}
                    // disabled={fullFilled || expiration === "00:00:00"}
                    className={``}
                  >
                    {`${button?.buttonLanguageValue} ${
                      eligibleOrNot ? t.common.now : ""
                    }`}
                  </Button>
                  {/* <Button variant="primary"
                  size="sm"
                  >{t.common.save_on_tax}
  
                  </Button> */}
                </div>
              )}
              {/* <button className="btn btn-warning">Share with friends</button> */}
            </>
            {utilize === "Claim" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                  marginBottom: "10px",
                }}
              >
                <Button
                  variant="warning"
                  size="sm"
                  onClick={async () => {
                    try {
                      const res = await redeemCode(winnerRewardId);
                    } catch (error) {}
                    window.open(redeemRedirection, "_target");
                  }}
                  className={`px-7`}
                >
                  {t?.common?.redeemed}
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => {
                    navigate(`/claim_form/${winnerRewardId}`, {
                      state: {
                        validRedirection: "valid",
                        isPanFieldShow: true,
                      },
                    });
                  }}
                  className={`px-7`}
                >
                  {t?.common?.save_on_tax}
                </Button>
              </div>
            )}
            {expiration ? (
              <p className="redeem-date mt-2">
                {" "}
                {t.common.last_date_to_redeem}:{" "}
                <span className="text-primary">{formatDate(expiration)}</span>{" "}
              </p>
            ) : (
              <></>
            )}
          </div>

          <>
            {/* <div className="or-copy-link d-flex p-4  justify-content-center mb-3 mb-md-4">
                <input
                  className="w-100"
                  value={voucherCode}
                  type="text"
                  readOnly
                />
                <button
                  onClick={() => {
                    copyToClipboard(voucherCode);
                  }}
                  className="copy-btn btn-primary"
                >
                  {t.common.copy_code}
                </button>
              </div> */}
            {/* {lastDate && (
              <p className="redeem-date mt-3">
                {" "}
                Last date to redeem:{" "}
                <span className="text-primary">
                  {formatDate(lastDate)}
                </span>{" "}
              </p>
            )} */}
            {isInModalUnique && points && (
              <p className="para">
                You've also earned{" "}
                <span className="font-weight-bold">{points} Comic Coins</span>
                <picture>
                  <source
                    srcset="/assets/img/contest/comic_coin@1x.webp 1x, /assets/img/contest/comic_coin@2x.webp 2x"
                    type="image/webp"
                  />

                  <img
                    src="/assets/img/contest/comic_coin@1x.png"
                    srcset="/assets/img/contest/comic_coin@1x.png 1x, /assets/img/contest/comic_coin@2x.png 2x"
                    alt="unique code"
                    className="mr-1"
                    width={25}
                    height={25}
                  />
                </picture>
              </p>
            )}
            {/* <div className="button-wrapper">
              <Button
                variant="warning "
                className="btn-card"
                onClick={handleShareWithFriends}
              >
                Share with Friends
              </Button>
            </div> */}
            {isRewardPopup ? (
              <>
                {isInModalUnique ? (
                  <Link
                    to={"#"}
                    className="text-primary font-weight-bold text-underline"
                    onClick={() => {
                      navigate("/leaderboard");
                      modalUniqueClose();
                    }}
                  >
                    {t.common.view_leaderboard}
                  </Link>
                ) : (
                  <ShareButton
                    title={title}
                    description={description}
                    type={
                      redirectUrl === "https://www.phonepe.com/"
                        ? "PHONEPE"
                        : "MMT"
                    }
                    imgUrl={
                      redirectUrl === "https://www.phonepe.com/"
                        ? "/assets/img/reward-share/JIAB-phone-pay.png"
                        : `/assets/img/reward-share/JIAB-MMT.png`
                    }
                    isLink={true}
                  />
                )}
              </>
            ) : (
              <></>
              // <ShareButton title={title} description={description} imgUrl={`/assets/img/reward-share/JIAB-MMT.png`} isLink={true}  />
            )}
          </>
        </Card.Body>
      </Card>
    </div>
  );
};

export default BlurRewardCard;
