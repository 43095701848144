import React from "react";

const ArtistHome = () => {
  return (
    <>
      <div className="  align-items-center">
        <img
          src="/assets/img/placeholder/Jon-img.png"
          alt="Jon Batiste"
          loading="lazy"
          className="img-fluid artist-img"
        />
        <h1 className=" artist-name">Jon Batiste</h1>
        <small className="artist-follow">29k Followers</small>
      </div>
    </>
  );
};

export default ArtistHome;
