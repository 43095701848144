import React from "react";
import { Modal, Button } from "react-bootstrap";
import TitleShowCase from "../common/TitleShowCase";

function AppModal({
  children,
  show,
  onClose,
  titleHeading = "",
  heading = "",
  subHeading = "",
  headerClass = "",
  headingClass = "",
  bodyClass = "",
  animationShow = false,
  centered = true,
  hideClose = false,
  closeLabel = "Close",
  contentClassName = "",
  dialogClassName = "",
  size = "",
  wrapperClass = "",
  modalHeading = "",
  animation,
  gaClass,
  ...rest
}) {
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered={centered}
      dialogClassName={dialogClassName}
      contentClassName={'overflow-hidden-n'}
      {...rest}
    >
      <Modal.Header className={`border-0 ${headerClass}`}>
        {titleHeading && <TitleShowCase showCardReport={false} />}
        {heading && (
          <Modal.Title as="h3" className={headingClass}>
            {heading}
          </Modal.Title>
        )}
        {!hideClose && (
          <Button
            variant="link"
            className="close"
            onClick={onClose}
            aria-label={closeLabel}
          >
            <img src="/assets/img/icons/cross.svg" alt="close" />
          </Button>
        )}
        {subHeading && <h6>{subHeading}</h6>}
      </Modal.Header>

      <Modal.Body className={bodyClass}>
        {modalHeading && <h3 className={headingClass}>{modalHeading}</h3>}
        {animationShow && (
          <img
            className="modal-animation"
            style={{
              zIndex: "999",
              position: "fixed",
              top: "59%",
              left: "52%",
              transform: "translate(-50%, -50%)",
            }}
            src="/assets/img/animation/coin-animation.gif"
            alt="animation"
          />
        )}
        {children}
      </Modal.Body>
    </Modal>
  );
}

export default AppModal;
