import React from "react";
import CommonSection from "../CommonSection";
import SectionHeader from "../SectionHeader";
import useTranslation from "../../../utils/hooks/use-translation";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import UgcUploadPreview from "./UgcUploadPreview";

const UgcPreview = () => {
  const { t } = useTranslation();
  const isDesktop = window.innerWidth >= 992;
  const headerContent = {
    title: t.header.submit_your_jokes,
    subTitle: t.header.hit_funniest_joke,
  };

  return (
    <div className="upload-form  col-12 mx-auto">
      <CommonSection
        headerContent={headerContent}
        headerType={3}
        containerClass="pt-6 mt-md-15 "
        sectionClass=" px-0"
      >
        {isDesktop && (
          <SectionHeader
            {...headerContent}
            headerClass={`pt-6 pb-5 pt-md-10 pb-md-6 text-md-center text-left`}
          />
        )}

        <UgcUploadPreview />
        {/* <RewardShareCard /> */}
      </CommonSection>
    </div>
  );
};

export default UgcPreview;
