import React, { useEffect, useState } from "react";
import gluedin from "gluedin";
import { useDispatch, useSelector } from "react-redux";

import useTranslation from "../../utils/hooks/use-translation";
// import { ClickEmoji } from "../../utils/cdp/cdpHelper";
import { Button } from "react-bootstrap";
import { sendReacts } from "../../services/ugc";
import { getAllRewards, getLeaderboardData } from "../../services";
import { getCDSPixelId } from "../../utils/helpers";
import {
  setAnimationShow,
  setHomePageDetails,
  setLeaderboardData,
  setLeaderboardUserData,
} from "../../store/slices/common-slice";
import { appendVoting } from "../../store/slices/auth-slice";
import toast from "react-hot-toast";
import useAuth from "../../utils/hooks/use-auth";
import SuccessModel from "../../components/modals/api_response_model";
import { format } from "date-fns";
import { getNotificationCount } from "../../services";
import { setNotificationCount } from "../../store/slices/common-slice";

const CommonCardFooter = ({
  videoId,
  activeEmoji,
  setActiveEmoji,
  viewsCount = 0,
  reactions = {},
  isDetailPage = false,
  showVoteButton = false,
  reactionsState,
  setReactionsState,
  setIsReacted = () => {},
  contentType = "video",
  show,
  onClose,
  viewCountHandler = () => {},
  handleProfileReaction = () => {},
}) => {
  const { isLoggedIn, userDetails } = useAuth();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [jokePassedModal, setJokePassedModal] = useState();
  const currentLanguageID = useSelector(
    (state) => state.common.currentLanguageID
  );
  const ipDetailsInfo = useSelector((state) => state?.common?.ipDetailsInfo);

  const votingData = useSelector((state) => state.auth.votingData);

  const triggerNotificationCount = () => {
    getNotificationCount({ params: { userId: userDetails?.id } })
      .then((res) => dispatch(setNotificationCount(res?.data?.data || 0)))
      .catch((err) => {});
  };

  useEffect(() => {
    getVoteOnUGC();
  }, []);

  useEffect(() => {
    if (activeEmoji) {
      setIsReacted(true);
    }
  }, [activeEmoji]);

  const getVoteOnUGC = async () => {
    const actityvityTimelineModule = new gluedin.GluedInActivityTimeline();
    const response = await actityvityTimelineModule.getActivityTimelineList({
      event: "like",
      limit: 100000,
    });
    let votingData = {};
    response?.data?.result?.map(
      (el) => (votingData[el.metakeys.videoId] = true)
    );
    dispatch(appendVoting(votingData));
  };

  const getAllRewardsData = async () => {
    const hfSessionKey = await getCDSPixelId();

    const rewardsRes = await getAllRewards({
      languageId: currentLanguageID || "1",
      hfSession: hfSessionKey,
    });

    dispatch(
      setHomePageDetails({
        coinCount: rewardsRes?.data?.data?.currentBalance ?? 0,
      })
    );
  };
  const JokePassed = {
    image: "/assets/img/modal/thank-you.svg",
    heading: t.model_messages.vote_is_in,
    subHeading: "",
    timer: false,
    cta: t.header.explore_more,
    description: "",
    id: 10,
  };

  const handleVote = async () => {
    if (isLoggedIn) {
      try {
        await sendReacts({
          assetId: videoId,
          activityType: 2,
        })
          .then((res) => {
            if (res.data.succeeded) {
              const activityTimelineModuleObj =
                new gluedin.GluedInActivityTimeline();
              activityTimelineModuleObj
                .activityTimelineLike({
                  assetId: videoId,
                  metakeys: {
                    country: ipDetailsInfo && ipDetailsInfo.country,
                    country_code: ipDetailsInfo && ipDetailsInfo.countryCode,
                    region: ipDetailsInfo && ipDetailsInfo.regionName,
                    region_code: ipDetailsInfo && ipDetailsInfo.region,
                    city: ipDetailsInfo && ipDetailsInfo.city,
                  },
                })
                .then(async (response) => {
                  if (response && response.status === 200) {
                    dispatch(setAnimationShow(true));
                    setTimeout(() => {
                      dispatch(setAnimationShow(false));
                      setJokePassedModal(true);
                    }, 3000);
                    dispatch(appendVoting({ [videoId]: true }));
                    triggerNotificationCount();
                    viewCountHandler();
                    getAllRewardsData();
                  }
                });
            }
          })
          .catch((err) => {
            if (
              err?.response?.data?.message ===
              "User has already done VoteForAJoke activity!"
            ) {
              dispatch(appendVoting({ [videoId]: true }));
            }
          });
      } catch {
        // dispatch(appendVoting({ [videoId]: true }));
      }
    }
  };

  const handleEmojiClick = async (event, emoji) => {
    // ClickEmoji(emoji);

    if (!activeEmoji) {
      viewCountHandler();
      setActiveEmoji(emoji);
      try {
        const activityTimelineModule = new gluedin.GluedInActivityTimeline();
        const response = await activityTimelineModule.activityTimelineReact({
          assetId: videoId,
          reactionType: emoji,
          metakeys: {
            country: ipDetailsInfo && ipDetailsInfo.country,
            country_code: ipDetailsInfo && ipDetailsInfo.countryCode,
            region: ipDetailsInfo && ipDetailsInfo.regionName,
            region_code: ipDetailsInfo && ipDetailsInfo.region,
            city: ipDetailsInfo && ipDetailsInfo.city,
          },
        });
        const {
          data: { success = false },
        } = response;
        if (success) {
          setReactionsState({
            ...reactionsState,
            [emoji]: reactionsState[emoji] + 1,
          });
          handleProfileReaction();

          try {
            await sendReacts({
              assetId: videoId,
              activityType: 1,
            }).then((res) => {
              if (res.data.succeeded) {
                dispatch(setAnimationShow(true));
                setTimeout(() => {
                  dispatch(setAnimationShow(false));
                }, 3000);
                getAllRewardsData();
                triggerNotificationCount();
                setTimeout(() => {
                  fetchLeaderBoardData();
                }, 1000);
              }
            });
          } catch {
            toast.info("Already reacted");
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const fetchLeaderBoardData = async () => {
    try {
      let reqData = {
        startDate: format(new Date(), "yyyy-MM-dd") || "",
        endDate: format(new Date(), "yyyy-MM-dd") || "",
        PageNumber: 1,
        PageSize: 2000,
      };
      const res = await getLeaderboardData(reqData);
      if (res.status === 200) {
        let data =
          res.data &&
          res.data.data &&
          res.data.data.winnerUsers &&
          Array.isArray(res.data.data.winnerUsers.data) &&
          res.data.data.winnerUsers.data.length
            ? res.data.data.winnerUsers.data
            : [];
        let userData =
          res.data && res.data.data && res.data.data.currentUser
            ? [
                {
                  ...res.data.data.currentUser,
                  rank:
                    res.data.data.currentUser.rank === 0
                      ? "-"
                      : res.data.data.currentUser.rank,
                },
              ]
            : [];
        dispatch(setLeaderboardData(data));
        dispatch(setLeaderboardUserData(userData));
      }
    } catch (err) {
      console.error("Error fetching leaderboard data:", err);
    }
  };

  return (
    <div className="footer-reaction  d-flex justify-content-between  mb-n2">
      <div className="reaction-icons d-flex justify-content-between w-100 text-center">
        <div className="d-flex ">
          <div
            className={`icon icon-like d-flex flex-column mr-2 align-items-center ${
              activeEmoji !== ""
                ? activeEmoji === "laugh"
                  ? "active-emoji"
                  : "disabled-emoji"
                : "active-emoji"
            }`}
            onClick={(e) => handleEmojiClick(e, "laugh")}
          >
            <img
              src="/assets/img/icons/joy.png"
              alt="laugh"
              className="react-emoji-footer"
            />
            <span className="icon-num">{reactionsState?.laugh}</span>
          </div>
          <div
            className={`icon icon-like d-flex flex-column  mr-2 ${
              activeEmoji !== ""
                ? activeEmoji === "neutral"
                  ? "active-emoji"
                  : "disabled-emoji"
                : "active-emoji"
            }`}
            onClick={(e) => handleEmojiClick(e, "neutral")}
          >
            <img
              src="/assets/img/icons/feelingless.png"
              alt="neutral"
              className="react-emoji-footer"
            />
            <span className="icon-num">{reactionsState?.neutral}</span>
          </div>
          <div
            className={`icon icon-like d-flex flex-column ${
              activeEmoji !== ""
                ? activeEmoji === "sad"
                  ? "active-emoji"
                  : "disabled-emoji"
                : "active-emoji"
            }`}
            onClick={(e) => handleEmojiClick(e, "sad")}
          >
            <img
              src="/assets/img/icons/angry.png"
              alt="sad"
              className="react-emoji-footer"
            />
            <span className="icon-num">{reactionsState?.sad}</span>
          </div>
        </div>
        <div className="d-flex align-items-center ">
          {showVoteButton && ( // Conditionally render the Vote button
            <Button
              size="sm"
              className="btn mr-2 vote"
              disabled={
                isLoggedIn
                  ? votingData && typeof votingData === "object"
                    ? Object.keys(votingData).length !== 0 &&
                      votingData[videoId]
                      ? true
                      : false
                    : false
                  : true
              }
              onClick={() => handleVote()}
            >
              {votingData && typeof votingData === "object"
                ? Object.keys(votingData).length !== 0 && votingData[videoId]
                  ? "Voted"
                  : "Vote"
                : "Vote"}
            </Button>
          )}
          <div className="icon icon-view d-flex flex-column text-center views-link">
            <img
              src="/assets/img/icons/speech-bubble.svg"
              alt="speech bubble"
              className="react-emoji-footer"
            />
            <span className="icon-num">{viewsCount || 0}</span>
          </div>
        </div>
      </div>
      <>
        {jokePassedModal && (
          <SuccessModel
            show={jokePassedModal}
            {...JokePassed}
            jokeSubmit={false}
            onClose={() => setJokePassedModal(false)}
          />
        )}
      </>
    </div>
  );
};

export default CommonCardFooter;
