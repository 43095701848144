import React from "react";
import AppModal from "./index";
import TimerComponent from "../common/Timer";
import useTranslation from "../../utils/hooks/use-translation";
import { useNavigate } from "react-router-dom";
import {
  setClaimSucess,
  setJokeSubmitSuccess,
} from "../../store/slices/common-slice";
import { useDispatch } from "react-redux";
import { setClaimDone } from "../../store/slices/home-popup";

const ErrorModel = ({
  show,
  onClose,
  image,
  heading,
  subHeading,
  cta,
  referralCode,
  ctaNo,
  ctaYes,
  timer,
  description,
  timerContent,
  alertDescription,
  notExitPopup,
  jokeSubmit = false,
  yesExitPop,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const targetTime = new Date("2024-01-30T23:59:59").getTime();
  return (
    <AppModal
      show={show}
      onClose={onClose}
      bodyClass="px-6 pt-2 pb-5 modal-response"
      size="md"
    >
      <div className="text-center">
        {image && (
          <img
            src={image}
            alt="Icon"
            className="modal-icon mb-md-4 mb-2"
            loading="lazy"
          />
        )}
        {heading && (
          <h6
            className="modal-heading pb-md-3 pb-2"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        )}
        {subHeading && (
          <p className="modal-description pb-md-2 pb-1">{subHeading}</p>
        )}
        {referralCode && (
          <p className="modal-description pb-md-2 pb-1 text-primary font-weight-bolder">{`“${referralCode}”`}</p>
        )}
        {timer && (
          <p className="modal-notification pb-md-2 pb-1">
            <TimerComponent
              title={t.common.claim_within}
              targetTime={targetTime}
            />
          </p>
        )}
        {timerContent && (
          <p className="modal-notification pb-md-2 pb-1">{timerContent}</p>
        )}
        {cta && (
          <button
            className="btn btn-primary mb-2"
            onClick={() => {
              if (cta === "Explore more jokes") {
                dispatch(setClaimDone(false));
                dispatch(setClaimSucess(false));
                navigate("/ugc");
              }
              if (jokeSubmit) {
                navigate("/ugc");
                dispatch(setJokeSubmitSuccess(false));
              } else {
                onClose();
              }
            }}
          >
            {cta}
          </button>
        )}
        {description && <p className="modal-notification">{description}</p>}
        {alertDescription && (
          <h6 className="modal-alert mb-3">{alertDescription}</h6>
        )}
        <div className="d-flex justify-content-around">
          {ctaYes && (
            <button
              onClick={yesExitPop ? yesExitPop : onClose}
              className="btn btn-outline border mb-2 btn-modal"
            >
              {ctaYes}
            </button>
          )}
          {ctaNo && (
            <button
              onClick={notExitPopup ? notExitPopup : ""}
              className="btn btn-primary mb-2 btn-modal"
            >
              {ctaNo}
            </button>
          )}
        </div>
      </div>
    </AppModal>
  );
};

export default ErrorModel;
