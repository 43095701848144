import React from "react";
import { Card, Container } from "react-bootstrap";
import useTranslation from "../utils/hooks/use-translation";
import CommonCardFooter from "../components/footer/CommonCardFooter";
const UgcListingCard = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Card className="card-joke mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <img src="/assets/img/icons/traffic.svg" alt="card icon" />
            <div className="ml-2">
              <h6>{t.common.breakup}</h6>
              <p className="mb-0">{t.common.artist_kapil_sharma}</p>
            </div>
          </div>
          <div className="card-report">
            <span className="text-danger mr-1">{t.common.report}</span>
            <img src="/assets/img/icons/info.svg" alt="card icon" />
          </div>
        </Card.Header>
        <Card.Body>
          <Card.Img variant="top" src="/assets/img/modal/UGC_listing.png" />
          <img
            src="/assets/img/icons/video-icon.svg"
            alt=""
            className="joke-type"
          />
        </Card.Body>
        <CommonCardFooter showVoteButton={true} />
      </Card>
    </Container>
  );
};

export default UgcListingCard;
