import React from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../utils/hooks/use-translation";
import { MetaPixel, TTDPixel, CounterFloodLight } from "../../utils/helpers";

const ComedianCard = (props) => {
  const { id, profileImageUrl, fullName, followersCount } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const windowLayerPush = (comedian) => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: "interaction",
      data: {
        action: "interaction",
        type: "comedian",
        name: comedian,
      },
    });
  };

  const TTDapplyFn = (name, followTTD) => {
    let nameKey = "";
    if (name === "Kapil") {
      if (followTTD) {
        nameKey = "00k09go";
      } else {
        nameKey = "a83kvcx";
      }
    } else if (name === "Niranjan") {
      if (followTTD) {
        nameKey = "29259sx";
      } else {
        nameKey = "6lvaetx";
      }
    } else if (name === "Naveen") {
      if (followTTD) {
        nameKey = "yk4qyx8";
      } else {
        nameKey = "qzsxs6b";
      }
    } else if (name === "Sorabh") {
      if (followTTD) {
        nameKey = "rmjmahs";
      } else {
        nameKey = "vzibrd8";
      }
    } else if (name === "Niharika") {
      if (followTTD) {
        nameKey = "hf2tuum";
      } else {
        nameKey = "dha2o7u";
      }
    } else if (name === "Varun") {
      if (followTTD) {
        nameKey = "5uygfnd";
      } else {
        nameKey = "k44e3u5";
      }
    } else if (name === "Saikiran") {
      if (followTTD) {
        nameKey = "349abml";
      } else {
        nameKey = "fojt8bg";
      }
    } else if (name === "Anirban") {
      if (followTTD) {
        nameKey = "a2am28r";
      } else {
        nameKey = "5gk8mcv";
      }
    } else if (name === "Prashasti") {
      if (followTTD) {
        nameKey = "zddbxuf";
      } else {
        nameKey = "ay9atrc";
      }
    } else if (name === "Mallika") {
      if (followTTD) {
        nameKey = "bwirsvc";
      } else {
        nameKey = "xrgtp09";
      }
    } else if (name === "Kenny") {
      if (followTTD) {
        nameKey = "h3c2ewq";
      } else {
        nameKey = "uubfx1z";
      }
    } else if (name === "Biswa") {
      if (followTTD) {
        nameKey = "tmddbkt";
      } else {
        nameKey = "8xykzc7";
      }
    }

    TTDPixel(nameKey);
  };

  return (
    <Card
      key={id}
      className={`card-comedian`}
      onClick={() => {
        TTDapplyFn(props.fullName.split(" ")[0], false);
        MetaPixel(`Comedian_${props.fullName.split(" ")[0]}`);
        CounterFloodLight(`DC-12665261/sprit0/sprit01f+unique`, {
          u44: props.fullName.replaceAll(" ", "_"),
        });
        windowLayerPush(props.fullName);
        navigate(`/artist_details/${id}`);
      }}
    >
      <div className={`card-top-img-wrapper position-relative`}>
        <div className="">
          <Card.Img
            src={profileImageUrl}
            className="comedian-img W-100 "
            loading="lazy"
          />
        </div>
      </div>
      <Card.Body className="d-flex justify-content-center">
        <div>
          <h4 className="mt-2 mb-md-2" as={"h4"}>
            {fullName}
          </h4>
          <div>
            <Card.Text className={`pb-3 pb-md-6`}>
              {followersCount + " " + t.common.followers}
            </Card.Text>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ComedianCard;
