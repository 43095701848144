import { createSlice } from "@reduxjs/toolkit";

// Define the initial state for the authentication slice
const initialState = {
  jokeData: null,
};

// Create the authentication slice using `createSlice`
export const jokeDataSlice = createSlice({
  name: "jokedata", // Name of the slice
  initialState,
  reducers: {
    setJokeData: (state, actions) => {
      state.jokeData = actions.payload;
    },
  },
});

// Extract the action creators generated by `createSlice`
export const { setJokeData } = jokeDataSlice.actions;

// Export the reducer function generated by `createSlice`
export default jokeDataSlice.reducer;
