import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonFormikForm from "../../../utils/form";
import useLoader from "../../../utils/hooks/use-loader";
import useTranslation from "../../../utils/hooks/use-translation";
import AppModal from "../../modals";
import { uniqueCodeValidationSchema } from "../../../utils/validations";
import {
  getAllRewards,
  getLeaderboardData,
  mixCodeApi,
} from "../../../services";
import {
  getCDSPixelId,
  handleInputLimitedLength,
  showApiErrors,
} from "../../../utils/helpers";
import {
  displayUniqueCodeSuccess,
  displayVoucherUniqueSuccess,
  setVoucherData,
} from "../../../store/slices/pop-up";
import useAuth from "../../../utils/hooks/use-auth";
import {
  setAnimationShow,
  setHomePageDetails,
  setLeaderboardData,
  setLeaderboardUserData,
  setAssuredWinsData
} from "../../../store/slices/common-slice";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { format } from "date-fns";
import { getAssuredWinsData } from "../../../services";


const UniqueCodeModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { show, onClose, type = "model" } = props;
  const { toggleLoader } = useLoader();
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const animationShow = useSelector((state) => state.common.animationShow);
  const currentLanguageID = useSelector(
    (state) => state.common.currentLanguageID
  );
  const [timesExhausted, setTimesExhausted] = useState(false);

  const initialValues = {
    UniqueCode: "",
  };

  const getAssuredWins = async () => {
    let reqData = { languageId: currentLanguageID || "1" };

    try {
      let res = await getAssuredWinsData(reqData);
      dispatch(setAssuredWinsData(res?.data?.data));
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (values, options) => {
    toggleLoader(true);
    try {
      const hfSessionKey = await getCDSPixelId();
      const reqData = {
        mixcode: values.UniqueCode,
        hfSession: hfSessionKey || "",
      };
      const res = await mixCodeApi(reqData);
      window.dataLayer.push({ event: "SpriteJ24_UniqueCode_Submit" });
      if (type === "model") {
        dispatch(setAnimationShow(true));
        setTimeout(() => {
          dispatch(setAnimationShow(false));
          getAllRewardsData();
          if (res.data.data.voucherWon) {
            onClose(false);
            dispatch(displayVoucherUniqueSuccess(true));
            getAssuredWins();
            dispatch(
              setVoucherData({
                ...res.data.data,
                ...res.data.data.voucher,
                redirectUrl: "https://phonepe.com",
              })
            );
          } else {
            onClose(false);
            dispatch(displayUniqueCodeSuccess(true));
          }
        }, 3000);
      } else {
        if (res.data.data.voucherWon) {
          onClose(false);
          dispatch(displayVoucherUniqueSuccess(true));
          getAssuredWins();
          dispatch(
            setVoucherData({
              ...res.data.data,
              ...res.data.data.voucher,
              redirectUrl: "https://phonepe.com",
            })
          );
        } else {
          onClose(false);
          dispatch(displayUniqueCodeSuccess(true));
        }
      }
      setTimeout(() => { 
        fetchLeaderBoardData();
      }, 1000);
    } catch (err) {
      if (
        err?.response?.data?.message ===
        "You can not redeem more than 5 unique codes in a Day."
      ) {
        setTimesExhausted(true);
      } else if (
        err?.response?.data?.message
          .toLowerCase()
          .includes("this code has been used before")
      ) {
        options.setFieldError("UniqueCode", t.model_messages.used_unique_code);
      } else if (
        err?.response?.data?.message.toLowerCase().includes("invalid")
      ) {
        options.setFieldError("UniqueCode", t.model_messages.enter_valid_code);
      } else {
        options.setFieldError("UniqueCode", err?.response?.data?.message);
      }
    } finally {
      toggleLoader(false);
    }
  };

  const fetchLeaderBoardData = async () => {
    try {
      let reqData = {
        startDate: format(new Date(), "yyyy-MM-dd") || "",
        endDate: format(new Date(), "yyyy-MM-dd") || "",
        PageNumber: 1,
        PageSize: 2000,
      };
      const res = await getLeaderboardData(reqData);
      if (res.status === 200) {
        let data =
          res.data &&
          res.data.data &&
          res.data.data.winnerUsers &&
          Array.isArray(res.data.data.winnerUsers.data) &&
          res.data.data.winnerUsers.data.length
            ? res.data.data.winnerUsers.data
            : [];
        let userData =
          res.data && res.data.data && res.data.data.currentUser
          ? [{...res.data.data.currentUser,rank:res.data.data.currentUser.rank===0?"-":res.data.data.currentUser.rank}]
            : [];
        dispatch(setLeaderboardData(data));
        dispatch(setLeaderboardUserData(userData));
      }
    } catch (err) {
      console.error("Error fetching leaderboard data:", err);
    }
  };

  const getAllRewardsData = async () => {
    const hfSessionKey = await getCDSPixelId();
    try {
      const rewardsRes = await getAllRewards({
        languageId: currentLanguageID || "1",
        hfSession: hfSessionKey,
      });
      dispatch(
        setHomePageDetails({
          coinCount: rewardsRes?.data?.data?.currentBalance ?? 0,
        })
      );
    } catch (err) {
      showApiErrors(err);
    }
  };

  const handleLockClick = () => {
    navigate("/login");
    onClose(false);
  };

  const renderInputs = [
    {
      type: "text",
      name: "UniqueCode",
      placeHolder: t.contest.enter_unique_code_here,
      label: t.common.participate_win_makemytrip_voucher,
      info: t.common.enter_unique_code_from_behind_label,
      maxlength: 10,
    },
  ];

  const renderInputs2 = [
    {
      type: "text",
      name: "UniqueCode",
      placeHolder: t.contest.enter_unique_code_here,
      disabled: true,
    },
  ];

  let uniqueCodeValidation = uniqueCodeValidationSchema(t);

  const modalBodyClass = `modal-unique-code pt-0`;

  return (
    <>
      {type === "model" ? (
        <AppModal
          show={show}
          onClose={() => onClose(true)}
          dialogClassName="reward-pay"
          bodyClass={modalBodyClass}
          animationShow={animationShow}
        >
          <>
            {!isLoggedIn ? (
              <div className="text-center">
                <img
                  src="/assets/img/animation/unique-code.gif"
                  alt="unique code"
                  className="d-block modal-image mx-auto mb-2"
                />
                <label>{t.common.participate_win_makemytrip_voucher}</label>
                <p className="modal-subcontent px-4">
                  {t.common.enter_unique_code_from_behind_label}
                </p>
                <button
                  className="btn-block py-4 mt-2 d-flex justify-content-center align-items-center  btn btn-primary"
                  onClick={handleLockClick}
                >
                  <img
                    src="/assets/img/icons/unique-code-lock.svg"
                    alt="Lock icon"
                    className="mr-2 "
                  />
                  <span className="mt-1">
                    {t.model_messages.login_to_unblock}
                  </span>
                </button>
                <p className="text-center font-weight-bold mt-1">
                  {t.model_messages.note}
                  <span className="font-weight-normal">
                    {t.model_messages.you_can_enter_five_code}
                  </span>
                </p>
              </div>
            ) : !timesExhausted ? (
              <>
                <img
                  src="/assets/img/animation/unique-code.gif"
                  alt="unique code"
                  className="d-block modal-image mx-auto mb-2"
                />
                <CommonFormikForm
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  renderInputs={renderInputs}
                  validationSchema={uniqueCodeValidation}
                />
                <p className="text-center font-weight-bold mt-1">
                  {t.model_messages.note}
                  <span className="font-weight-normal">
                    {t.model_messages.you_can_enter_five_code}
                  </span>
                </p>
              </>
            ) : (
              <div className="text-center unique-code-limit">
                <img
                  src="/assets/img/modal/sorry-icon.svg"
                  alt="limit reached"
                  className="d-block mx-auto mb-2"
                />
                <h4 className="modal-title">{t.model_messages.sorry}</h4>
                <p className="modal-subcontent">
                  {t.model_messages.daily_limit_reached}
                </p>
                <CommonFormikForm
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  renderInputs={renderInputs2}
                  validationSchema={uniqueCodeValidation}
                  buttonDisabled={true}
                />
              </div>
            )}
          </>
        </AppModal>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={uniqueCodeValidation}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, values, touched, errors }) => (
            <Form
              className="w-100 position-relative"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {/* Input Field */}
              <Field name="inputField">
                {({ field }) => (
                  <div className="header-unique-code ">
                    <input
                      placeholder={t.contest.enter_unique_code}
                      {...field}
                      type="text"
                      name={"UniqueCode"}
                      value={!timesExhausted ? values.UniqueCode : ""}
                      onInput={(e) => {
                        handleInputLimitedLength(e, 10, 10);
                      }}
                      disabled={timesExhausted}
                      style={{ opacity: timesExhausted ? 0.6 : 1 }}
                    />

                    <button
                      className={`btn-primary`}
                      type="submit"
                      disabled={timesExhausted}
                      style={{ opacity: timesExhausted ? 0.6 : 1 }}
                    >
                      {t.common.submit}
                    </button>
                  </div>
                )}
              </Field>
              {!timesExhausted && (
                <ErrorMessage
                  name={"UniqueCode"}
                  component="div"
                  className={
                    touched["UniqueCode"] && errors["UniqueCode"]
                      ? "text-danger mt-1 text-center"
                      : "text-danger text-center"
                  }
                />
              )}
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default UniqueCodeModal;
