import React from "react";
import useTranslation from "../../utils/hooks/use-translation";
import SuccessModel from "../../components/modals/api_response_model.js";
import { useSelector, useDispatch } from "react-redux";
import { setClaimSucess } from "../../store/slices/common-slice.js";
 
const ClaimSuccess = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const claim = useSelector((state) => state.common.claimSuccess)
  
  const contentType = {
    image: "/assets/img/modal/congratulations.svg",
    heading: t.model_messages.all_boxes,
    subHeading: t.model_messages.successfully_claimed_reward_subheading,
    timer: false,
    cta: t.model_messages.explore_more_jokes,
    description: "",
    id: 7,
  };
 
  return (
    <>
      {claim && (
        <SuccessModel
          show={claim}
          {...contentType}
          jokeSubmit={true}
          onClose={() => dispatch(setClaimSucess(false))}
        />
      )}
    </>
  );
};
 
export default ClaimSuccess;