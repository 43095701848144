// Import the `createSlice` function from `@reduxjs/toolkit`
import { createSlice } from "@reduxjs/toolkit";

// Define the initial state for the loading slice
const initialState = {
  isLoading: false, // Indicates if the application is currently in a loading state
  bottleLoading: false,
  ipDetails: null, // Stores the IP details
  currentLanguage: "en",
  currentLanguageID: 1,
  genereID: 0,
  animationShow: false,
  leaderboardData: [],
  leaderboardUserData: [],
  assuredWinsData: [],
  homepageDetails: {
    sections: [],
    notificationCount: 0,
    coinCount: 0,
  },
  avatarList: [],
  defaultAddress: "",
  playVedio: { showVedio: "", id: "" },
  tourGuide: { showTourGuide: false },
  contestTour: { showContestTour: false },
  allGluedinData: [],
  ipDetailsInfo: {},
  referFriend: false,
  jokeSubmitSuccess: { state: false, message: "" },
  claimSuccess: false,
  notificationCount: 0
};

// Create the loading slice using `createSlice`
const loadingSlice = createSlice({
  name: "common", // Name of the slice
  initialState, // Initial state defined above
  reducers: {
    // Reducer function for toggling the loading state
    toggleLoading: (state, actions) => {
      state.isLoading = actions?.payload ? actions.payload : !state.isLoading; // Toggle the loading state based on the payload of the action or invert the current loading state
    },
    bottleToggleLoading: (state, actions) => {
      state.bottleLoading = actions?.payload
        ? actions.payload
        : !state.bottleLoading; // Toggle the loading state based on the payload of the action or invert the current loading state
    },
    // Reducer function for setting IP details
    setIpDetails: (state, actions) => {
      state.ipDetails = actions.payload; // Set the IP details to the payload of the action
    },
    setLanguage: (state, actions) => {
      state.currentLanguage = actions.payload; // Set the IP details to the payload of the action
    },
    setLanguageID: (state, actions) => {
      state.currentLanguageID = actions.payload; // Set the IP details to the payload of the action
    },
    setHomePageDetails: (state, actions) => {
      state.homepageDetails = { ...state.homepageDetails, ...actions.payload }; // act as cdp cache to send event only once
    },
    setfaqDetails: (state, actions) => {
      state.setfaqDetails = { ...state.setfaqDetails, ...actions.payload }; // act as cdp cache to send event only once
    },
    setTierDetails: (state, actions) => {
      state.setTierDetails = { ...state.setTierDetails, ...actions.payload }; // act as cdp cache to send event only once
    },
    setAvatarList: (state, actions) => {
      state.avatarList = { ...state.avatarList, ...actions.payload }; // act as cdp cache to send event only once
    },
    setDefaultAddress: (state, actions) => {
      state.defaultAddress = { ...state.defaultAddress, ...actions.payload }; // act as cdp cache to send event only once
    },
    setPlayVedio: (state, actions) => {
      state.playVedio = { ...state.playVedio, ...actions.payload }; // act as cdp cache to send event only once
    },
    showTourGuide: (state, actions) => {
      state.tourGuide = { ...state.tourGuide, ...actions.payload }; // act as cdp cache to send event only once
    },
    showContestTour: (state, actions) => {
      state.contestTour = { ...state.contestTour, ...actions.payload }; // act as cdp cache to send event only once
    },
    setGluedinDataByRailId: (state, actions) => {
      state.allGluedinData = { ...state.allGluedinData, ...actions.payload }; // act as cdp cache to send event only once
    },
    setIpDetailsInfo: (state, action) => {
      state.ipDetailsInfo = { ...action.payload };
    },
    setGenereID: (state, action) => {
      state.genereID = action.payload;
    },
    setAnimationShow: (state, action) => {
      state.animationShow = action.payload;
    },
    setReferFriend: (state, action) => {
      state.referFriend = action.payload;
    },
    setJokeSubmitSuccess: (state, action) => {
      state.jokeSubmitSuccess = action.payload;
    },
    setClaimSucess: (state, action) => {
      state.claimSuccess = action.payload;
    },
    setLeaderboardData: (state, action) => {
      state.leaderboardData = action.payload;
    },
    setLeaderboardUserData: (state, action) => {
      state.leaderboardUserData = action.payload;
    },
    setAssuredWinsData: (state, action) => {
      state.assuredWinsData = action.payload;
    },
    setNotificationCount: (state, action) => {
      state.notificationCount = action.payload;
    },
  },
});

// Extract the action creators generated by `createSlice`
export const {
  setGluedinDataByRailId,
  toggleLoading,
  setIpDetails,
  setLanguage,
  setLanguageID,
  setHomePageDetails,
  setAvatarList,
  setfaqDetails,
  setTierDetails,
  setDefaultAddress,
  setPlayVedio,
  showTourGuide,
  setIpDetailsInfo,
  setGenereID,
  setAnimationShow,
  setReferFriend,
  setJokeSubmitSuccess,
  setClaimSucess,
  showContestTour,
  setLeaderboardData,
  setLeaderboardUserData,
  setAssuredWinsData,
  setNotificationCount
} = loadingSlice.actions;

// Export the reducer function generated by `createSlice`
export default loadingSlice.reducer;
