export const REGEX = {
  name: /^[A-Za-z\u0900-\u097F\s]+(?:\s[A-Za-z\u0900-\u097F\s]+)*$/,
  mobile: /^(?!0|1|2|3|4|5)[0-9]{10}$/, // allow only 10 digits start with 6-9
  Code: /^[A-Za-z0-9]{10}$/, // allow alphanumeric of 10 char only
  otp: /^[0-9]{6}$/, // allow 6 digit number only
  PinCode: /^[1-9][0-9]{5}$/, // allow 6 digit number only
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, // general email check
  Address: /^[a-zA-Z0-9\s,./-]*$/,
  Passport: /^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/,
  Govid: /^[A-Z]{5}[0-9]{4}[A-Z]$/,
  url: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/
};
