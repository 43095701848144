import { AxiosInstance } from "../utils/axios-helper/axios-helper";
import { API_END_POINT } from "../utils/constants/api-end-points";
import { version } from "../config";
import { gluedinLogin } from "./auth-service";

//get p
export const getProfile = async (data) => {
  let formData = {
    email: `${data.userId}@gmail.com`,
    password: data.userId,
  };
  await gluedinLogin(formData);
  return await AxiosInstance.get(
    `${API_END_POINT.getUserProfile}?userId=${data.userId}`
  );
};

export const updateUserProfile = async (data) => {
  return await AxiosInstance.post(API_END_POINT.updateProfile, data);
};

export const sendReferralAPI = async (data) => {
  return await AxiosInstance.post(
    `${version}${API_END_POINT.sendReferral}`,
    data
  );
};

export const getAllreferralAPI = async (data) => {
  return await AxiosInstance.get(
    `${version}${API_END_POINT.getReferral}?PageNumber=${data.PageNumber}&Pagesize=${data.Pagesize}`
  );
};

export const getAllProfileQuestionsAPI = async (data) => {
  return await AxiosInstance.post(
    `${API_END_POINT.getAllProfileQuestions}?LanguageId=${data.languageId}`
  );
};

export const updateUserProfileQuestion = async (data) => {
  return await AxiosInstance.post(
    API_END_POINT.updateUserProfileQuestion,
    data
  );
};

export const getPendingreferralAPI = async (data) => {
  return await AxiosInstance.post(
    `${version}${API_END_POINT.pendingReferalList}?PageNumber=${data.PageNumber}&Pagesize=${data.Pagesize}`
  );
};

export const verifyRefCodeAPI = async (data) => {
  return await AxiosInstance.post(
    `${version}${API_END_POINT.verifyRefCode}`,
    data
  );
};

export const getJokesByUser = async () => {
  return await AxiosInstance.post(`${API_END_POINT.getJokesByUser}`);
};
