import React, { useState, useEffect } from "react";
import useTranslation from "../../utils/hooks/use-translation";
import { ClickLanguageChangeCDP } from "../../utils/cdp/cdpHelper";
import { TTDPixel } from "../../utils/helpers";

function LanguageSwitcher() {
  const { currentLanguage, changeLanguage } = useTranslation();
  const [value, setvalue] = useState(currentLanguage);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 991);

  useEffect(() => {
    setvalue(currentLanguage);
  }, [currentLanguage]);

  const getClassByname = () => {
    let className = "en_global";
    if (value === "hi") {
      className = "hi_global";
    } else if (value === "ba") {
      ///bengali
      className = "ba_global";
    } else if (value === "te") {
      //telgu
      className = "te_global";
    } else if (value === "or") {
      //oriya
      className = "or_global";
    } else if (value === "kn") {
      //Kannad
      className = "kn_global";
    } else if (value === "mr") {
      //marathi
      className = "mr_global";
    } else if (value === "gu") {
      //gujrati
      className = "gu_global";
    } else if (value === "mai") {
      //Maithli
      className = "mai_global";
    } else if (value === "bho") {
      //bhojpuri
      className = "bho_global";
    }

    return className;
  };

  useEffect(() => {
    document.body.className = getClassByname();
    return () => {
      document.body.className = "";
    };
  }, [value]);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 991);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const TTDapplyFn = (name) => {
    let nameKey = "";
    if (name === "en") {
      nameKey = "nof99ok";
    } else if (name === "mai") {
      nameKey = "bcpi0vw";
    } else if (name === "ba") {
      nameKey = "887fsfq";
    } else if (name === "te") {
      nameKey = "50fd8ix";
    } else if (name === "or") {
      nameKey = "wb8fgty";
    } else if (name === "mr") {
      nameKey = "52ucfgv";
    } else if (name === "hi") {
      nameKey = "sz7sw4k";
    } else if (name === "bho") {
      nameKey = "i26ckpg";
    }

    TTDPixel(nameKey);
  };

  const handleLanguageChange = (value, idNum) => {
    if (value) {
      const newLanguage = value;
      changeLanguage(newLanguage, idNum);
      setvalue(value);
      ClickLanguageChangeCDP(value.toUpperCase());
    } else {
      setvalue("en");
      ClickLanguageChangeCDP("EN");
    }
    TTDapplyFn(value);
  };

  //language and IDs
  // 1: English,
  // 2: Hindi,
  // 3: Telugu,
  // 4: Oriya,
  // 5: Bengali,
  // 6: Marathi,
  // 7: Kannada,
  // 8: Gujarati,
  // 9: Bhojpuri,
  // 10: Maithli

  return (
    //please add correct IDs for correct language, please don't assign random IDs
    <select
      className={`form-select form-select-language`}
      value={value}
      onChange={(event) =>
        handleLanguageChange(
          event.target.value,
          event.target.selectedOptions[0].id
        )
      }
    >
      <option value="en" id="1">
        ENGLISH
      </option>
      <option value="hi" id="2">
        हिन्दी
      </option>
      <option value="te" id="3">
        తెలుగు
      </option>
      <option value="or" id="4">
        ଓରିୟା
      </option>
      <option value="ba" id="5">
        বাংলা
      </option>
      <option value="mr" id="6">
        मराठी
      </option>

      <option value="kn" id="7">
        ಕನ್ನಡ
      </option>
      {/* <option value="gu" id="8">
       ગુજરાતી
      </option> */}
      <option value="bho" id="9">
        भोजपुरी
      </option>
      <option value="mai" id="10">
        मैथिली
      </option>
    </select>
  );
}

export default LanguageSwitcher;
