import React, { useRef, useState } from "react";
import CommonSlider from "./Slider";
import SectionHeader from "./SectionHeader";
import RewardCard from "../cards/RewardCard";
import ComedianCard from "../cards/ComedianCard";

import VedioCard from "../cards/vedioCards";
import WalletCard from "../cards/WalletCard";
import CokeInfoCard from "../../pages/contestPage/CoinInfoCard";
import Image from "./CommonImage";
import ArtistHome from "../cards/artistHome";
import Tabs from "./tabs";
import { Link } from "react-router-dom";
import useTranslation from "../../utils/hooks/use-translation";
import { setPlayVedio } from "../../store/slices/common-slice";
import { useDispatch, useSelector } from "react-redux";
import GiggleCard from "../cards/GiggleCard";
import TickleCard from "../cards/TickleCard";

function Crousal(props) {
  const { t } = useTranslation();
  const videoRef = useRef(null);
  const {
    headerContent,
    sectionClass = "",
    SliderContainerClass = "",
    fullWidthVideo = true,
    data,
    isHeader,
    tabActive = false,
    sliderType,
    cardType,
    blockClass = "",
    iconClass = "",
    imgClass = "",
    wrapperClassName,
    id,
    subData,
    isCoinWinFeatureAllowed = false,
    redirectUrl,
    cardOnClick,
    cardOuterClass = "",
    imageType,
    gaClass,
    clickAnimation,
    videoLock = false,
    iconBlockTop = "",
    isHomePage,
    handleCategorySelect = "",
    setSurpriseMe,
    isUgc = false,
  } = props;

  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showTooltip, setShowTooltip] = useState(true);
  const [activeAudio, setActiveAudio] = useState(null);

  const defaultImage = "/assets/img/placeholder/h_b_d_img01.webp";
  const tabLabels = [`${t.common.latest}`, `${t.common.trending}`];

  const currentLanguage = useSelector((state) => state.common.currentLanguage);

  const handleSlideChange = () => {
    dispatch(setPlayVedio({ showVedio: false, id: "" }));

    setShowTooltip(false);
    setTimeout(() => {
      setShowTooltip(true);
    }, 100);
  };

  const handlePlay = (audioElement) => {
    if (activeAudio && activeAudio !== audioElement) {
      activeAudio.pause();
      activeAudio.currentTime = 0;
    }
    setActiveAudio(audioElement);
  };

  const handleAudioPause = () => {
    if (activeAudio) {
      activeAudio.pause();
      activeAudio.currentTime = 0;
      setActiveAudio(null);
    }
  };

  const getSetting = (type) => {
    let setting = {
      slideShowD: 1,
      slideShowM: 1,
      cPadding: "3.2rem",
      slidesToScroll: 1,
    };
    if (type === 1) {
      setting = {
        slideShowD: 1,
        slideShowM: 1,
        loopD: true,
        cPadding: "0px",
        dPadding: "0px",
        centerModeM: true,
        centerModeD: true,
        infinite_d: true,
        infinite_m: true,
        slidesToScroll: 1,
      };
    } else if (type === 2) {
      setting = {
        slideShowD: 3,
        slideShowM: 1.8,
        cPadding: "16px",
        slidesToScroll: 1,
      };
    } else if (type === 3) {
      setting = {
        slideShowD: 2,
        slideShowM: 1,
        slidesToScrollD: 3,
        slidesToScrollM: 2,
        cPadding: "60px",
        dPadding: "0",
        centerModeM: true,
        centerModeD: true,
        slidesToScroll: 1,
        infinite_d: true,
        infinite_m: true,
        initialSlide: 0,
      };
    } else if (type === 4) {
      setting = {
        slideShowD: 3,
        slideShowM: 1,
        centerModeD: false,
        slidesToScrollD: 3,
        slidesToScrollM: 2,
        cPadding: "10px",
        dPadding: "0",
        centerModeM: true,
        slidesToScroll: 1,
        infinite_d: false,
        infinite_m: true,
        initialSlide: 0,
      };
    } else if (type === 6) {
      setting = {
        className: "center",
        centerMode: true,
        slideShowD: 1,
        slideShowM: 1,
        cPadding: "0",
        mPadding: "0",
        slidesToScrollD: 6,
        slidesToScrollM: 1,
        infinite_d: false,
        infinite_m: false,
        centerPadding: "0px",
        rows: 2,
        slidesPerRow: 2,
      };
    } else if (type === 7) {
      setting = {
        className: "",
        centerModeD: false,
        centerModeM: false,
        slideShowD: 6,
        slideShowM: 4,
        slidesToScrollD: 6,
        slidesToScrollM: 4,
        cPadding: "0",
        dPadding: "0",
        mPadding: "0",
        infinite_d: false,
        infinite_m: true,

        // centerPadding: "px",
      };
    } else if (type === 8) {
      setting = {
        slideShowD: 4,
        slideShowM: 1,
        centerModeD: false,
        slidesToScrollD: 4,
        slidesToScrollM: 2,
        cPadding: "60px",
        dPadding: "0",
        centerModeM: true,
        slidesToScroll: 1,
        infinite_d: false,
        infinite_m: true,
        initialSlide: 0,
      };
    }
    return setting;
  };

  let setting = getSetting(sliderType);

  const handleCardClick = (index) => {
    setSelectedIndex(index);
  };
  const handleTierCard = (data) => {
    subData(data);
  };

  const renderImageCard = (el, i) => {
    return (
      <Image
        className={`img-card ${gaClass}_${i}`}
        id={gaClass}
        key={i}
        src={
          el.thumbnailURL
            ? el.thumbnailURL
            : el.imageURL
            ? el.imageURL
            : el.imageUrl
            ? el.imageUrl
            : defaultImage
        }
        alt={el.title}
        imageType={imageType}
      />
    );
  };

  const renderCards = (el, i) => {
    if (cardType === "wallet") {
      return (
        <WalletCard
          key={i}
          cardClassName={el.cardClassName}
          title={el.tierName}
          cardAlt={el.title}
          points={el.currentBalance}
          handleTierCard={() => handleTierCard(el.rewards)}
          totalPoints={el.tierBalance}
        />
      );
    } else if (cardType === "vedio") {
      return (
        <VedioCard
          key={i}
          index={i}
          image={el.thumbnailURL}
          altText={el.title}
          likes={el.likes}
          views={el.totalViews}
          videoURL={el.videoURL}
          blockClass={blockClass}
          iconClass={iconClass}
          fullWidthVideo={fullWidthVideo}
          imgClass={imgClass}
          videoRef={videoRef}
          setSelectedIndex={handleCardClick}
          selectedIndex={selectedIndex}
          isCoinWinFeatureAllowed={isCoinWinFeatureAllowed}
          id={el.id}
          videoLock={videoLock}
          iconBlockTop={iconBlockTop}
          isExclusive={el.isExclusive}
          vedioType={id}
          gaClass={`${gaClass}_${i}`}
        />
      );
    } else if (cardType === "giggle") {
      return (
        <GiggleCard
          key={i + 1}
          data={el.video ?? el.data}
          ugcUrl={el.url ?? null}
          showVoteButton={true}
          isUgc={isUgc}
          onPlay={handlePlay}
          handleAudioPause={handleAudioPause}
        />
      );
    } else if (cardType === "image") {
      return el.videoLink ? (
        <Link to={el.videoLink ? el.videoLink : "#"} key={i}>
          {renderImageCard(el, i)}
        </Link>
      ) : (
        renderImageCard(el, i)
      );
    } else if (cardType === "reward") {
      return (
        <RewardCard
          key={i}
          clickAnimation={clickAnimation}
          cardClassName="text-center"
          cardImage={el.imageUrl}
          title={el.name}
          description={el.description}
          cardAlt={el.name}
          variant="top"
          points={el.noOfCoins}
          eligibleOrNot={el.eligible}
          fullFilled={el.fullFilled}
          rewardLogo={el.iconURL}
          redirectUrl={el.redirectUrl}
          redeemRedirection={redirectUrl}
          buttonValue={el.button}
          expiration={el.expiration}
          winnerRewardId={el.winnerRewardId}
          utilize={el.utilize}
          tooltipInfo={el.information}
          voucherCode={el.voucherCode}
          showTooltip={showTooltip}
          pinCode={el.secureCode}
          information={el.information}
          setShowTooltip={setShowTooltip}
          {...el}
        />
      );
    } else if (cardType === "comedian") {
      return (
        <ComedianCard
          id={el.assetId}
          type="7"
          profileImageUrl={el?.profile?.profileImageUrl}
          fullName={
            el?.profile?.localisedFullName[currentLanguage] ??
            el?.profile?.localisedFullName["en"]
          }
          followersCount={el?.profile?.followersCount}
          isHomePage={isHomePage}
          key={i}
          {...el}
        />
      );
    } else if (cardType === "cokecoins") {
      return (
        <div
          className={cardOuterClass}
          onClick={() => {
            if (el.id === 10) {
              cardOnClick();
            }
          }}
        >
          <CokeInfoCard key={i} card={el} />
        </div>
      );
    } else if (cardType === "artist") {
      return (
        <ArtistHome
          key={i}
          cardClassName="text-center"
          cardImage={el.imageURL}
          title={el.rewardsName}
          description={el.rewardsDescription}
          cardAlt={el.name}
          variant="top"
          points={el.noOfCoins}
          buttonText={el.canClaim ? t.common.redeem_now : t.common.collect_now}
          alreadyClaim={el.isAlreadyClaim}
          rewardLogo={el.iconURL}
          redirectUrl={redirectUrl}
          canClaim={el.canClaim}
          winnerRewardid={el.rewardsId}
          {...el}
        />
      );
    } else if (cardType === "moodPicker") {
      return (
        <TickleCard
          key={i}
          wrapperClassName={`${wrapperClassName}`}
          cardClassName="text-center"
          cardImage={el?.imageUrl ? el?.imageUrl : "./assets/img/football.svg"}
          enTitle={el?.genre}
          otherLangTitle={el?.jokeGenreLanguageList}
          cardAlt={el?.title}
          genereID={el?.id}
          handleCategorySelect={handleCategorySelect ?? ""}
          setSurpriseMe={setSurpriseMe}
          {...el}
        />
      );
    }
  };

  const sliderShow = data && data.length > 1;

  return (
    <>
      <section className={`s ${sectionClass}`} id={id}>
        <SectionHeader {...headerContent} isHeader={isHeader} sectionId={id} />
        {tabActive && <Tabs tabLabels={tabLabels} />}
        <div
          className={`container-fluid ${sliderShow && SliderContainerClass}`}
        >
          <CommonSlider
            {...setting}
            handleSlideChange={handleSlideChange}
            showSlider={sliderShow}
            setShowTooltip={setShowTooltip}
          >
            {data && data.map((el, i) => renderCards(el, i))}
          </CommonSlider>
        </div>
      </section>
    </>
  );
}

export default Crousal;
