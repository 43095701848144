import { AxiosInstance } from "../utils/axios-helper/axios-helper";
import { API_END_POINT } from "../utils/constants/api-end-points";

export const getLeaderboardData = async (data) => {
  return await AxiosInstance.get(
    `${API_END_POINT.getLeaderboard}${
      data.startDate ? `?StartDate=${data.startDate}` : ``
    }&${data.endDate ? `EndDate=${data.endDate}` : ``}&PageNumber=${data.PageNumber}&PageSize=${data.PageSize}`
  );
};

export const getJokeOwnerData = async (data) => {
  return await AxiosInstance.post(`${API_END_POINT.jokeOwner}`, data);
};
