import { createSlice } from "@reduxjs/toolkit";

// Define the initial state for the authentication slice
const initialState = {
  contactUs: false,
  uniqueCodeSuccess: false,
  voucherUniqueSuccess: false,
  showUniqueCodePopup: false,
  voucherData: {}
};

// Create the authentication slice using `createSlice`
export const popUpSlice = createSlice({
  name: "pupUp", // Name of the slice
  initialState,
  reducers: {
    displayContactUs: (state, action) => {
      const { payload } = action; // Payload contains the user details
      state.contactUs = payload; // Set isLoggedIn to true if payload is not empty
    },
    displayUniqueCode: (state, action) => {
      const { payload } = action;
      state.showUniqueCodePopup = payload;
    },
    displayUniqueCodeSuccess: (state, action) => {
      const { payload } = action;
      state.uniqueCodeSuccess = payload;
    },
    displayVoucherUniqueSuccess: (state, action) => {
      const { payload } = action;
      state.voucherUniqueSuccess = payload;
    },
    setVoucherData: (state, action) => {
      const { payload } = action;
      state.voucherData = payload;
    },

    displayShareWithFriends: (state, action) => {
      const { payload } = action;
      state.voucherUniqueSuccess = false;
      state.showShareWithFriends = payload;
    },
  },
});

// Extract the action creators generated by `createSlice`
export const {
  displayContactUs,
  displayUniqueCodeSuccess,
  displayVoucherUniqueSuccess,
  setVoucherData,
  displayShareWithFriends,
  displayUniqueCode
} = popUpSlice.actions;

// Export the reducer function generated by `createSlice`
export default popUpSlice.reducer;
