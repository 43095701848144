import React, { useState, useEffect } from "react";
import CommonSection from "../components/common/CommonSection";
import SectionHeader from "../components/common/SectionHeader";
import useTranslation from "../utils/hooks/use-translation";
import styled from "styled-components";
import { getNotificationsAPI } from "../services/notification";
import useAuth from "../utils/hooks/use-auth";
import moment from "moment";

const elementIsVisibleInViewport = (
  el,
  partiallyVisible = false,
  offset = 0
) => {
  const { top, left, bottom, right } = el.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;
  const heightWithOffset = innerHeight - offset;
  return partiallyVisible
    ? ((top > 0 && top < heightWithOffset) ||
        (bottom > 0 && bottom < heightWithOffset)) &&
        ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
    : top >= 0 &&
        left >= 0 &&
        bottom <= heightWithOffset &&
        right <= innerWidth;
};

const iconMap = {
  1: "/assets/img/notifications/sprite_coin.svg",
  5: "/assets/img/notifications/sprite_coin.svg",
};

const ImageBox = styled.img`
  width: 38px;
  height: 38px;
  border-radius: 5px;
  background-color: #00953b;
  // padding: 9px;
`;

const NotificationContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  background-color: #fff;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #d3d3d3 !important;
  }
  & > .notif-inner-container {
    display: flex;
    padding-top: 22px;
    padding-bottom: 16px;
    border-bottom: 1px solid #d3d3d3;
    cursor: pointer;
    & > div {
      display: contents;
      .title,
      .description {
        line-height: 12px;
      }
      .title {
        font-weight: bold;
      }
      .description {
        font-size: 10px;
      }
      .timeposted {
        font-size: 8px;
        color: #686868;
      }
    }
    gap: 8px;
  }
`;

const NotificationList = styled.div`
  & > .notification-container:last-child {
    & > .notif-inner-container {
      border-bottom: none;
    }
  }
`;

const Notifications = ({ popover }) => {
  const { t } = useTranslation();
  const [notificationList, setNotificationList] = useState([]);
  const { userDetails } = useAuth();

  const fetchNotifications = async () => {
    if (!userDetails || !userDetails.id) {
      return;
    } else {
      try {
        const response = await getNotificationsAPI({
          params: { UserID: userDetails?.id },
        });
        setNotificationList(
          response.data?.data?.sort(
            (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
          ) || []
        );
      } catch (error) {}
    }
  };

  const readNotifications = async () => {
    let notificationsElements = [].slice.call(
      document.getElementsByClassName("notification-list-item")
    );
    const notificationsInViewport = notificationsElements.filter((el) =>
      elementIsVisibleInViewport(el, false, 60)
    );
  };
  useEffect(() => {
    fetchNotifications();
  }, [userDetails]);

  useEffect(() => {
    if (notificationList.length) {
      setTimeout(() => {
        readNotifications();
      }, 100);
    }
  }, [notificationList]);
  const headerContent = {
    title: t.notification.notification_header,
    subTitle: t.notification.notification_subheader,
    // linkText: t.common.clear_all,
    // action: (() => {
    //   return handleDeleteAllNotification;
    // })(),
  };

  return (
    <div id="notificationPageBody">
      <CommonSection
        headerContent={headerContent}
        headerType={3}
        containerClass="px-0"
        sectionClass="px-0"
      >
        {!popover && (
          <SectionHeader {...headerContent} headerClass={` pb-6 header-desk`} />
        )}
        <div
          className="static-content pt-md-0 mt-16 mt-md-0"
          style={
            popover
              ? { width: "450px", maxHeight: "65vh", overflow: "scroll" }
              : {}
          }
        >
          <div className={notificationList.length ? "bg-white" : ""}>
            {notificationList.length ? (
              <NotificationList>
                {notificationList?.map((_, index) => (
                  <NotificationContainer
                    className="notification-container notification-list-item"
                    key={`notification-container-${index}`}
                    data-item-id={_?.id}
                  >
                    <div className="notif-inner-container">
                      <div>
                        <ImageBox
                          src={
                            _.iconUrl ||
                            "/assets/img/notifications/clap_white.svg"
                          }
                          data-icon-type-id={_.notificationTypeId}
                        />
                      </div>
                      <div className="d-flex flex-column w-100">
                        <p className="m-0 p-0 title">
                          {_.notificationTitle || "Title"}
                        </p>
                        <p className="m-0 p-0 mt-1 description">
                          {_.notificationText || "Desc"}
                        </p>
                      </div>
                      <div className="d-flex align-items-center">
                        <p
                          className="m-0 p-0 timeposted"
                          style={{ width: "3rem" }}
                        >
                          {moment().diff(_.createdOn, "h") > 0
                            ? `${moment().diff(_.createdOn, "h")} hr`
                            : `${moment().diff(_.createdOn, "m")} min`}
                        </p>
                      </div>
                    </div>
                  </NotificationContainer>
                ))}
              </NotificationList>
            ) : (
              <>
                <div
                  className=""
                  style={{ height: !popover ? "calc(100vh - 200px)" : "65vh" }}
                >
                  <div className="xy-center block-middle">
                    <span className="icon-notifi icon-Notification-01"></span>
                    <h1 className="title">{t.notification.no_notification}</h1>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </CommonSection>
    </div>
  );
};

export default Notifications;
