import React from "react";

const CokeInfoCard = ({ card, link }) => {
  return (
    <div className="coin-info-outer">
      <div className="title-div">
        {card.imageUrl && <img src="/assets/img/react-joke.svg" alt="react on joke" />}
        {card.text && (
          <div className="coin-title-div d-flex flex-column justify-content-between">
            <div className="main-coin-title">{card.text}</div>
            {/* <div className="sub-coin-title">{card.description}</div> */}
            <div className="info-points">
              <div className="points-box">
                <img
                  src="/assets/img/icons/sprite-meter.svg"
                  alt="coin"
                  className="points-sign info-card-sign"
                />
                {card.winnerCoin && (
                  <h6 className="points-num">
                    {card.winnerCoin}
                  </h6>
                )}
              </div>
              {card.condition && <div className="info-text">{card.condition}</div>}
            </div>
          </div>
        )}
      </div>

    </div>
  );
};

export default CokeInfoCard;
