export const API_END_POINT = {
  sendOtp: "/identity/OTP",
  verifyOtp: "/identity/OTPLogin",
  registerUser: "/identity/register",
  getUserProfile: "/identity/GetUserDetail",
  updateProfile: "/identity/UpdateUserDetail",
  getAllProfileQuestions: "/Identity/GetAllQuestions",
  updateUserProfileQuestion: "/Identity/AddEditUserQuestion",
  refreshToken: "/identity/RefreshToken",
  homeDetails: "/Home/GetByHomePage",
  homeDetailsAfterLogin: "/Home/GetHomePageByUser",
  addClaimData: "/ClaimForm/MMTClaimFormAddEdit",
  avatarList: "/Masters/GetAvatar",
  getAddressByUId: "/User/getaddressbyuserid",
  getAddressByAId: "/User/getaddressbyid",
  addEditAddress: "/User/addeditaddress",
  deleteAddress: "/User/deleteaddress",
  defaultAddress: "/User/setdefaultaddress",
  cityStateByPinCode: "/Geolocation/GetByPinCode",
  getWalletData: "/Rewards/GetAllRewards",
  getJoke: "/Joke/GetJoke",
  getJokeGenre: "/Joke/GetJokeGenres",
  getJokeByArtist: "/Joke/GetJokesConsumedByArtist",
  getRewardsHistory: "/Rewards/GetAllRewardsHistory",
  getAllArtist: "/Video/GetAllArtistList",
  getAllRewards: "/Rewards/GetAllRewards",
  faqDetails: "/Masters/GetFAQ",
  sendReferral: "/User/sendReferral",
  getReferral: "/User/GetReferralList",
  getAllNotification: "/Notification/getNotifications",
  getNotificationCount: "/Notification/getNotificationsCount",
  updateNotificationStatus: "/Notification/updateNotificationStatus",
  readNotification: "/Notification/ReadNotificationByID",
  readAllNotification: "/Notification/ReadAllNotification",
  deleteNotificationById: "/Notification/DeleteNotificationById",
  deleteAllNotification: "/Notification/DeleteAllNotification",
  getContest: "/Contest/GetAllContest",
  getContestV1: "/Contest/GetAllContestMMTClaim",
  getAssuredWins: "/Contest/GetAllAssuredWinsDetails",
  getAssuredWinsById: "/Contest/VerifyAssuredWinsVoucherId",
  getAllBehindThescneData: "/Video/GetBehindScenes",
  getAllMelodies: "/Video/GetAllMelodies",
  getAllTreasures: "/Video/GetAllTreasure",
  getAllUGC: "/Video/GetAllUGCVideo",
  getAllTheSeasons: "/Video/GetSeasons",
  userViewedVedio: "/User/AddUserVideo",
  likeVedios: "/Video/SaveLikeVideoById",
  uniqueCode: "/MixCode/verify-mixcode",
  validateRefCode: "/identity/ValidateCode",
  verifyRefCode: "/User/VerifyRefCode",
  pendingReferalList: "/User/GetPendingReferralList",
  getLeaderboard: "/LeaderBoard/GetAllLeaderBoard",
  ugcUpload: "/UGC/UploadLarge",
  ugcGetContent: "/UGC/GetContentForAllByIds",
  sendReactPoints: "/User/AddUserReactPoint",
  sendJokeReport: "/UGC/PlagiarizeContent",
  unlockJoke: "/Joke/UnlockJoke",
  getJokesByUser: "/UGC/GetUGCJokesDetailByUserId",
  getGenres: "/Joke/GetJokeGenreLanguageId",
  jokeOwner: "/UGC/GetUGCUserDetailByAssetIds",
  notifyUserPopup: "/User/GetNotifyUser",
  sendCode: "/User/SendCode",
  mixCodeMock: "/MixCode/MixcodeVerificationMock",
  profileCompletePopup: "/v1/User/UpdateIsProfilePopup",
  getClaimformData: "/ClaimForm/GetMMTClaimFormDetails",
};
