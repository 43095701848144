import React, { useState, useEffect } from "react";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useViewport } from "../../utils/context/viewport-context";
import useTranslation from "../../utils/hooks/use-translation";
import { showTourGuide } from "../../store/slices/common-slice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { displayContestTour } from "../../store/slices/suprise-me";

const TourGuide = ({ isOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { isMobile } = useViewport();

  const commonStepContent = (heading, text) => (
    <>
      {heading && (
        <h3 className="tour-heading text-center mb-1 mb-md-2">{heading}</h3>
      )}
      <p className="tour-text mb-0">{text}</p>
    </>
  );
  const getBorderRadius = (selector) => {
    const element = document.querySelector(selector);
    return window.getComputedStyle(element).borderRadius;
  };

  const stepsD = [
    {
      target: ".how-participate",
      content: commonStepContent(
        t.tour_guide.how_to_participate,
        t.tour_guide.crack_code_to_grand_prize
      ),
      disableBeacon: true,
      placement: "left",
      isFixed: true,
    },
    {
      target: ".tour-reward",
      content: commonStepContent(
        t.header.reward_pool,
        t.tour_guide.fab_prizes_up_for_grabs
      ),
      disableBeacon: true,
      placement: "bottom",
      isFixed: true,
      styles: {
        spotlight: {
          borderRadius: "20px",
          padding: "1px",
        },
      },
    },
    {
      target: ".tour-leaderboard",
      content: commonStepContent(
        t.tour_guide.where_do_stand,
        t.tour_guide.check_out_rank_here
      ),
      disableBeacon: true,
      placement: "bottom",
      isFixed: true,
      styles: {
        spotlight: {
          borderRadius: "70px",
          padding: "1px",
        },
      },
    },
    {
      target: ".tour-comic",
      content: commonStepContent(
        t.wallet.total_coins,
        t.tour_guide.check_your_collection
      ),
      disableBeacon: true,
      placement: "bottom",
      isFixed: true,
      styles: {
        spotlight: {
          borderRadius: "70px",
          padding: "1px",
        },
      },
    },
    {
      target: ".tour-code",
      content: commonStepContent(
        t.contest.enter_unique_code,
        t.tour_guide.sprite_pack
      ),
      disableBeacon: true,
      placement: "bottom",
      isFixed: true,
      styles: {
        spotlight: {
          borderRadius: "50px",
          padding: "1px",
        },
      },
    },
    {
      target: ".tour-gather-cominc",
      content: commonStepContent(
        t.tour_guide.level_up_your_coins,
        t.tour_guide.check_out_how_to_collect
      ),
      disableBeacon: true,
      placement: "bottom",
      isFixed: true,
      styles: {
        spotlight: {
          borderRadius: "20px",
          padding: "1px",
        },
      },
    },
  ];

  const stepsM = [
    {
      target: ".how-participate",
      content: commonStepContent(
        t.tour_guide.how_to_participate,
        t.tour_guide.crack_code_to_grand_prize
      ),
      disableBeacon: true,
      placement: "left",
      isFixed: true,
      styles: {
        spotlight: {
          borderRadius: "10px",
          padding: "1px",
        },
      },
    },
    {
      target: ".tour-reward",
      content: commonStepContent(
        t.header.reward_pool,
        t.tour_guide.fab_prizes_up_for_grabs
      ),
      disableBeacon: true,
      placement: "bottom",
      isFixed: true,
    },
    {
      target: ".tour-leaderboard",
      content: commonStepContent(
        t.tour_guide.where_do_stand,
        t.tour_guide.check_out_rank_here
      ),
      disableBeacon: true,
      placement: "bottom",
      isFixed: true,
      styles: {
        spotlight: {
          borderRadius: "70px",
          padding: "1px",
        },
      },
    },
    {
      target: ".tour-comic",
      content: commonStepContent(
        t.wallet.total_coins,
        t.tour_guide.check_your_collection
      ),
      disableBeacon: true,
      placement: "bottom",
      isFixed: true,
      styles: {
        spotlight: {
          borderRadius: "70px",
          padding: "1px",
        },
      },
    },
    {
      target: ".tour-code",
      content: commonStepContent(
        t.contest.enter_unique_code,
        t.tour_guide.sprite_pack
      ),
      disableBeacon: true,
      placement: "bottom",
      isFixed: true,
    },
    {
      target: ".tour-gather-cominc",
      content: commonStepContent(
        t.tour_guide.level_up_your_coins,
        t.tour_guide.check_out_how_to_collect
      ),
      disableBeacon: true,
      placement: "bottom",
      isFixed: true,
    },
  ];

  const steps = isMobile ? stepsM : stepsD;

  // New Code start here

  const [run, setRun] = useState(isOpen);
  const [stepIndex, setStepIndex] = useState(0);
  const [automaticProgressTimer, setAutomaticProgressTimer] = useState(null);
  var element = document.querySelector('[data-test-id="button-primary"]');

  useEffect(() => {
    if (run) {
      startAutomaticProgression();
      // if (stopAutomaticProgress) startAutomaticProgression();
    }
    return () => {
      // Clear the automatic progression interval when the component unmounts or when the tour is stopped
      clearInterval(automaticProgressTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [run, stepIndex]);

  const startAutomaticProgression = () => {
    const intervalTime = 2500; // 2.5 seconds
    setAutomaticProgressTimer(
      setTimeout(() => {
        if (stepIndex === steps.length - 1) {
          setRun(false);
          dispatch(displayContestTour(false));
          navigate("/");
        } else {
          setStepIndex(stepIndex + 1);
        }
      }, intervalTime)
    );
  };

  if (element) {
    element.addEventListener("click", function (event) {
      if (event.type === "click") {
        clearInterval(automaticProgressTimer);
      }
    });
    element.addEventListener("touchstart", function (event) {
      if (event.type === "touchstart") {
        clearInterval(automaticProgressTimer);
      }
    });
  } else {
    // Element not found
    console.log("Element not found with data-test-id: yourTestId");
  }

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    // Check if the element exists
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      dispatch(displayContestTour(false));
    }
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      run={run}
      continuous
      stepIndex={stepIndex}
      steps={steps}
      scrollOffset={120}
      // spotlightPadding={0}
      hideCloseButton
      hideBackButton
      hideNextButton
      showProgress={false}
      styles={{
        spotlight: {
          borderRadius: 10,
        },
      }}
    />
  );
};

export default TourGuide;
