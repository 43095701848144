import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
} from "react-router-dom";
import { lazy, Suspense } from "react";
import FullScreenLoader from "../components/loader";
import useAuth from "../utils/hooks/use-auth";
import { useLocation } from "react-router-dom";
import UgcListing from "../pages/UgcListingCard";
import ContestOver from "../pages/ContestOver";
import UgcUpload from "../components/common/appForm/UgcUpload";
import UgcPreview from "../components/common/appForm/UgcPreview";
import HallLame from "../pages/HallLame";
import HomeIndex from "../pages/HomeIndex";

// Lazy load components with React.lazy
const HomePage = lazy(() => import("../pages/Home"));
const App = lazy(() => import("../App"));
const Page404 = lazy(() => import("../pages/404"));
const ConnectionLoss = lazy(() => import("../pages/ConnectionLoss"));
const Login = lazy(() => import("../features/auth/Login"));
const SignUp = lazy(() => import("../features/auth/SignUp"));
const OtpVerification = lazy(() => import("../features/auth/OtpVerification"));
const MyProfile = lazy(() => import("../pages/MyProfile"));
const MyWallet = lazy(() => import("../pages/Wallet"));
const Notification = lazy(() => import("../pages/NotificationV2"));
const Faq = lazy(() => import("../pages/Faq"));
const Contest = lazy(() => import("../pages/Contest"));
const TermsCondition = lazy(() => import("../pages/TermsConditions"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy"));
const AuthAccess = lazy(() => import("../pages/AuthAccess"));
const Artist = lazy(() => import("../pages/Artist"));
const ArtistDetails = lazy(() => import("../pages/ArtistDetail"));
const SeeAll = lazy(() => import("../pages/SeeAll"));
const Sitemap = lazy(() => import("../pages/Sitemap"));
const ClaimForm = lazy(() => import("../components/common/appForm/ClaimForm"));
const Referral = lazy(() => import("../pages/ReferaFriend"));
const SendReminder = lazy(() => import("../pages/SendReminder"));
const SiteMap = lazy(() => import("../pages/Sitemap"));
const Popup = lazy(() => import("../pages/popup"));
const UGCVedios = lazy(() => import("../pages/UgcVedios"));
const LeaderBoard = lazy(() => import("../pages/LeaderBoard"));
const GiggleMeter = lazy(() => import("../pages/GiggleMeter"));

// Define a fallback for lazy loading
const loaderComponent = (
  <div>
    <FullScreenLoader />
  </div>
);

// Create a wrapper component for lazy loaded components
function LazyRoute({ component: Component }) {
  return (
    <Suspense fallback={loaderComponent}>
      <Component />
    </Suspense>
  );
}

function ProtectedRoute({ component: Component, path }) {
  const { isLoggedIn } = useAuth();
  if (!isLoggedIn) {
    // Redirect to login if not authenticated
    //window.location.assign('/login')
    localStorage.setItem("pathBeforeLogin", window.location.pathname);

    return <Navigate to="/login" />;
  }
  return <Component />;
}

function PublicOnlyRoute({ component: Component }) {
  const { isLoggedIn } = useAuth();
  const { state, pathname } = useLocation();
  if (isLoggedIn) {
    // Redirect to home page if authenticated
    return <Navigate to="/" />;
  }
  if (
    (pathname === "/signup" || pathname === "/otp_verification") &&
    !(state && state.mobile)
  ) {
    return <Navigate to="/login" />;
  }

  return <Component />;
}

const renderRoute = ({ element, path, ...props }) => {
  // Check if the route requires authentication
  if (props.requiresAuth) {
    return <ProtectedRoute component={() => element} path={path} />;
  }
  return <LazyRoute component={() => element} />;
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<LazyRoute component={App} />}
      errorElement={<LazyRoute component={Page404} />}
    >
      {/* without login  */}

      <Route path="/" element={<LazyRoute component={HomeIndex} />} />
      <Route
        path="/connection_loss"
        element={<LazyRoute component={ConnectionLoss} />}
      />
      <Route
        path="/contest"
        element={renderRoute({
          element: <ContestOver />,
          path: "/contest",
        })}
      />

      <Route
        path="/login"
        element={
          <PublicOnlyRoute component={() => <LazyRoute component={Login} />} />
        }
      />
      <Route
        path="/signup"
        element={
          <PublicOnlyRoute component={() => <LazyRoute component={SignUp} />} />
        }
      />
      <Route
        path="/auth_access/:key"
        element={
          <PublicOnlyRoute
            component={() => <LazyRoute component={AuthAccess} />}
          />
        }
      />

      <Route
        path="/contest_over"
        element={
          <PublicOnlyRoute
            component={() => <LazyRoute component={ContestOver} />}
          />
        }
      />
      <Route
        path="/otp_verification"
        element={
          <PublicOnlyRoute
            component={() => <LazyRoute component={OtpVerification} />}
          />
        }
      />
      {/* without login  and with login both can access */}
      <Route
        path="/notifications"
        element={<LazyRoute component={Notification} />}
      />
      <Route path="/faq" element={<LazyRoute component={Faq} />} />

      <Route
        path="/giggle_meter"
        element={<LazyRoute component={GiggleMeter} />}
      />
      <Route
        path="/leaderboard"
        element={<LazyRoute component={LeaderBoard} />}
      />
      {/* <Route path="/hall_lame" element={<LazyRoute component={HallLame} />} /> */}
      <Route path="/sitemap" element={<LazyRoute component={Sitemap} />} />
      <Route
        path="/terms_conditions"
        element={<LazyRoute component={TermsCondition} />}
      />
      <Route
        path="/privacy_policy"
        element={<LazyRoute component={PrivacyPolicy} />}
      />

      <Route
        path="/ugc_preview"
        element={<LazyRoute component={UgcPreview} />}
      />

      <Route path="/popup" element={<LazyRoute component={Popup} />} />
      <Route
        path="/see_all/:cardtype/:section"
        element={<LazyRoute component={SeeAll} />}
      />
      <Route path="/artist" element={<LazyRoute component={Artist} />} />

      <Route
        path="/artist_details/:id"
        element={<LazyRoute component={ArtistDetails} />}
      />

      <Route path="/site_map" element={<LazyRoute component={SiteMap} />} />
      <Route
        path="/reels/:reel_id"
        element={<LazyRoute component={UgcListing} />}
      />

      {/* with login  */}
      <Route
        path="/ugc"
        element={renderRoute({
          element: <UGCVedios />,
          requiresAuth: true,
          path: "/ugc",
        })}
      />

      <Route
        path="/my_profile"
        element={renderRoute({
          element: <MyProfile />,
          requiresAuth: true,
          path: "/my_profile",
        })}
      />
      <Route
        path="/my_profile/:id"
        element={renderRoute({
          element: <MyProfile />,
          requiresAuth: true,
          path: "/my_profile/:id",
        })}
      />
      <Route
        path="/my_wallet"
        element={renderRoute({
          element: <MyWallet />,
          requiresAuth: true,
          path: "/my_wallet",
        })}
      />
      <Route
        path="/claim_form/:winnerRewardid"
        element={renderRoute({ element: <ClaimForm />, requiresAuth: false })} //true
      />
      <Route
        path="/referral"
        element={renderRoute({
          element: <Referral />,
          requiresAuth: true,
          path: "/referral",
        })}
      />
      <Route
        path="/send-reminder"
        element={renderRoute({
          element: <SendReminder />,
          requiresAuth: true,
          path: "/send-reminder",
        })}
      />
      {/* <Route
        path="/contest"
        element={renderRoute({ element: <Contest />, requiresAuth: true })}
      /> */}
      <Route
        path="/ugc_upload"
        element={renderRoute({
          element: <UgcUpload />,
          requiresAuth: true,
          path: "/ugc_upload",
        })}
      />
      <Route
        path="/hall_lame"
        element={renderRoute({
          element: <HallLame />,
          requiresAuth: true,
          path: "/hall_lame",
        })}
      />
    </Route>
  )
);
