import React from "react";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { setAnimationShow } from "../../store/slices/common-slice";
import { appendVoting } from "../../store/slices/auth-slice";
import gluedin from "gluedin";
import { sendReacts } from "../../services/ugc";
import { getCDSPixelId } from "../../utils/helpers";
import { getAllRewards } from "../../services";
import { setHomePageDetails } from "../../store/slices/common-slice";

const ReactionFooter = ({
  viewsCount = 0,
  reactions = {},
  isArtistDetail = false,
  showVoteButton = false,
  videoId = "",
  isLoggedIn = false,
}) => {
  const dispatch = useDispatch();

  const votingData = useSelector((state) => state.auth.votingData);
  const currentLanguageID = useSelector(
    (state) => state.common.currentLanguageID
  );

  const getAllRewardsData = async () => {
    const hfSessionKey = await getCDSPixelId();

    const rewardsRes = await getAllRewards({
      languageId: currentLanguageID || "1",
      hfSession: hfSessionKey,
    });

    dispatch(
      setHomePageDetails({
        coinCount: rewardsRes?.data?.data?.currentBalance ?? 0,
      })
    );
  };

  const handleVote = () => {
    if (isLoggedIn) {
      const activityTimelineModuleObj = new gluedin.GluedInActivityTimeline();
      activityTimelineModuleObj
        .activityTimelineLike({ assetId: videoId })
        .then(async (response) => {
          if (response && response.status === 200) {
            try {
              await sendReacts({
                assetId: videoId,
                activityType: 2,
              }).then((res) => {
                if (res.data.succeeded) {
                  dispatch(setAnimationShow(true));
                  setTimeout(() => {
                    dispatch(setAnimationShow(false));
                  }, 3000);
                  dispatch(appendVoting({ [videoId]: true }));
                  getAllRewardsData();
                }
              });
            } catch {
              dispatch(appendVoting({ [videoId]: true }));
            }
          }
        });
    }

    // else {
    //   navigate("/login");
    // }
  };

  return (
    <div className="footer-reaction  d-flex justify-content-between pt-2 mb-n2">
      <div className="reaction-icons d-flex  ">
        <div className="icon  d-flex flex-column  mr-1 mr-sm-2">
          <img
            src="/assets/img/icons/joy.png"
            alt="laugh"
            style={{
              opacity:0.5
            }}
            className={isArtistDetail ? "react-emoji-footer" : ""}
          />
          <span
            className={`icon-num ${isArtistDetail ? "artist-icon-num" : ""}`}
          >
            {isArtistDetail ? reactions?.laugh || 0 : "506"}
          </span>
        </div>
        <div className="icon  d-flex flex-column mr-1 mr-sm-2 ">
          <img
            src="/assets/img/icons/feelingless.png"
            alt="neutral"
            style={{
              opacity:0.5
            }}
            className={isArtistDetail ? "react-emoji-footer" : ""}
          />
          <span
            className={`icon-num ${isArtistDetail ? "artist-icon-num" : ""}`}
          >
            {isArtistDetail ? reactions?.neutral || 0 : "201"}
          </span>
        </div>
        <div className="icon  d-flex flex-column ">
          <img
            src="/assets/img/icons/angry.png"
            alt="sad"
            style={{
              opacity:0.5
            }}
            className={isArtistDetail ? "react-emoji-footer" : ""}
          />
          <span
            className={`icon-num ${isArtistDetail ? "artist-icon-num" : ""}`}
          >
            {isArtistDetail ? reactions?.sad || 0 : "112"}
          </span>
        </div>
      </div>
      <div className="d-flex align-items-center">
        {showVoteButton && ( // Conditionally render the Vote button
          <Button
            size="sm"
            className="btn mr-2 vote"
            disabled={
              isLoggedIn
                ? votingData && typeof votingData === "object"
                  ? Object.keys(votingData).length !== 0 && votingData[videoId]
                    ? true
                    : false
                  : false
                : true
            }
            onClick={() => handleVote()}
          >
            {votingData && typeof votingData === "object"
              ? Object.keys(votingData).length !== 0 && votingData[videoId]
                ? "Voted"
                : "Vote"
              : "Vote"}
          </Button>
        )}
        <div className="icon icon-view d-flex flex-column text-center views-link">
          <img
            src="/assets/img/icons/speech-bubble.svg"
            alt="speech bubble"
            className={isArtistDetail ? "react-emoji-footer" : ""}
          />
          <span
            className={`icon-num ${isArtistDetail ? "artist-icon-num" : ""}`}
          >
            {isArtistDetail ? viewsCount || 0 : "6K"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ReactionFooter;