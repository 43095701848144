import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useAuth from "../../utils/hooks/use-auth";
import { TTDPixel, MetaPixel } from "../../utils/helpers";
import { ClickButtonCDP } from "../../utils/cdp/cdpHelper";

function ComingSoonSection(props) {
  const {
    imgSrc,
    heading1,
    heading2,
    heading3,
    sectionClass,
    btnText,
    showButton,
    buttonVariant,
    route,
    navigationState = {},
    headingClass,
    navigation,
    routeButton = true,
    onClick,

    submitBtnId = "",
  } = props;
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const handleClick = () => {
    if (navigation) {
      if (isLoggedIn) {
        navigate("/contest");
      } else {
        navigate("/login");
      }
    }
  };
  const navigateFn = () => {
    if (route === "/ugc_upload") {
      TTDPixel("m696e5k");
      MetaPixel("ComicKaun?");
    }
    navigate(route, { state: navigationState });
  };
  return (
    <div className={` text-center ${sectionClass}`} onClick={handleClick}>
      <img src={imgSrc} alt="banner" loading="lazy" />
      <div className="">
        <h6 className="font-italic ">{heading1}</h6>
        <h5 className="comic-heading text-light font-italic">{heading3}</h5>
        <h3
          className={headingClass}
          dangerouslySetInnerHTML={{ __html: heading2 }}
        ></h3>
        {/* <h4 className="font-italic ">{subheading}</h4> */}
        {/* {showCountdown && <CountdownTimer targetTime={targetTime} />} */}
        {showButton && (
          <Button
            variant={buttonVariant}
            className={submitBtnId}
            size="sm"
            id={submitBtnId}
            onClick={() => {
              if(route=="/ugc_upload")
                {
                  ClickButtonCDP("Interest_UGC_Submission")
                }
              routeButton ? navigateFn() : onClick();
            }}
          >
            {btnText}
          </Button>
        )}
      </div>
    </div>
  );
}

export default ComingSoonSection;
