import React, { useState, useEffect } from "react";
import useTranslation from "../utils/hooks/use-translation";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../utils/hooks/use-auth";

function ContestOver() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn } = useAuth();
  useEffect(() => {
    if (location.pathname === "/contest") {
      document.querySelector("main").classList.add("contest-over-main");
    } else {
      document.querySelector("main").classList.remove("contest-over-main");
    }
  }, [location.pathname]);

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const targetDate = new Date("2024-07-15T00:00:00").getTime();

    const updateCountdown = () => {
      const now = new Date().getTime();
      const difference = targetDate - now;

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });
    };

    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="s-over text-center">
      <div className="d-flex justify-content-center align-items-center flex-column">
        <img
          src="./assets/img/contest-prize.png"
          alt="prize image"
          className="mt-16 mb-md-4"
        />
        <p className="text-light">{t.common.contest_over}</p>
        <Button
          onClick={() => {
            navigate(isLoggedIn ? "/leaderboard" : "/login");
          }}
          variant="warning"
          className=" mb-8 mx-2"
        >
          {t.common.winner_list}
        </Button>
        <h4 className="text-warning mb-0 pt-1">{t.common.exciting_reward} </h4>
        <h4 className="text-warning mb-2">{t.common.loading_in} </h4>
        {/* <h6 className="mt-6">{t.common.stay_tuned}</h6> */}
        {/* <h2 className="text-warning mt-1">{t.home.coming_soon}</h2> */}
        {/* <p
          className="text-light py-2"
          dangerouslySetInnerHTML={{
            __html: t.common.your_wishlist_about_get_exciting_rewards,
          }}
        /> */}
        {/* <div className="row timer-container">
          <div className="col timer-wrapper">
            <h4 className="text-dark">{timeLeft.days}</h4>
            <span className="text-uppercase">{t.common.days}</span>
          </div>
          <div className="col timer-wrapper">
            <h4 className="text-dark">{timeLeft.hours}</h4>
            <span className="text-uppercase">{t.common.hours}</span>
          </div>
          <div className="col timer-wrapper">
            <h4 className="text-dark">{timeLeft.minutes}</h4>
            <span className="text-uppercase">{t.common.minutes}</span>
          </div>
          <div className="col timer-wrapper">
            <h4 className="text-dark">{timeLeft.seconds}</h4>
            <span className="text-uppercase">{t.common.seconds}</span>
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default ContestOver;
