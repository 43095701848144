import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { sentUserViewedVedio } from "../../services";
import { useDispatch } from "react-redux";
import { getAllHomepageDetailsALogin } from "../../utils/axios-helper/api-hander";
import useLoader from "../../utils/hooks/use-loader";
import CommonCardFooter from "../footer/CommonCardFooter";
import TitleShowCase from "./TitleShowCase";

const VideoPlayer = ({
  videoURL,
  videoRef,
  showVoteButton,
  onReady,
  isCoinWinFeatureAllowed,
  id,
  fullWidthVideo = false,
  reactions = "",
  videoId = "",
  activeEmoji = "",
  setActiveEmoji = "",
  viewsCount = "",
  isDetailPage = false,
  data = [],
  isLoggedIn = "",
  reactionsState = "",
  setReactionsState = "",
  animationShow = "",
  setAnimationShow = "",
  viewCountHandler,
  setIsReacted,
  width = "100%",
  height = "100%",
  isAutoPlay = true,
  controls = true,
  muted = false,
  handleProfileReaction = () => {},
}) => {
  const dispatch = useDispatch();
  // const [autoplay, setAutoplay] = useState(isAutoPlay);
  const currentLanguageID = useSelector(
    (state) => state.common.currentLanguageID
  );
  const { toggleLoader } = useLoader();

  useEffect(() => {
    // Check if autoplay is allowed
    const isAutoplayAllowed =
      "mediaSession" in navigator || // Chrome and Edge
      (typeof document !== "undefined" && "documentMode" in document) || // IE
      (typeof document !== "undefined" &&
        "MozAppearance" in document.documentElement.style); // Firefox

    if (isAutoplayAllowed) {
      // setAutoplay(true);
    }
  }, []);

  const currentLanguage = useSelector((state) => state.common.currentLanguage);

  const config = {
    file: {
      forceVideo: true,
    },
  };

  const handlePlay = () => {};

  const handlePause = () => {
    // setAutoplay(false);
  };

  //call api ones user watched full vedio
  const userWatchedFullVedio = async () => {
    try {
      let reqData = { videoMasterId: id, hfSession: "" };
      const res = await sentUserViewedVedio(reqData);
      if (res.status === 200 && res.data.succeeded) {
        getAllHomepageDetailsALogin(dispatch, toggleLoader, currentLanguageID);
      }
    } catch (err) {}
  };

  const handleProgress = (state) => {
    const { played } = state;
    const playedPercentage = played * 100;

    if (playedPercentage === 100) {
      if (viewCountHandler) {
        viewCountHandler();
      }
      if (isCoinWinFeatureAllowed) {
        userWatchedFullVedio();
      }
      // You can perform additional actions here when the user has completed the video
    }
  };
  // controls: false,
  //   playing: true,
  //   muted: true,
  //   loop: true,
  //   playsinline: true,

  const setting = {
    ref: videoRef,
    url: videoURL, // Replace with your video URL
    controls: controls,
    playing: isAutoPlay,
    muted: muted,
    preload: "auto",
    config: config,
    playsinline: true,
    type: "video/mp4",
    className: "react-player",
    width: width,
    height: height,
    onPlay: handlePlay,
    onPause: handlePause,
    onReady: onReady,
    onProgress: handleProgress,
  };
  // const widthSetting = fullWidthVideo ? { width: '100%', height: '100%' } : {};

  return (
    <>
      {isDetailPage && (
        <TitleShowCase
          showCardReport={false}
          jokeGenre={data?.labels?.[0]}
          jokeLang={data?.labels?.[1]}
          videoId={data?.videoId}
          // jokeTitle={data?.title}
          jokeTitle={
            data?.localisedTitle[currentLanguage] ?? data?.localisedTitle?.["en"]
          }
          author={data?.user?.fullName}
          isLoggedIn={isLoggedIn}
          onPopUpClass={true}
          isModal={true}
        />
      )}
      {videoURL && (
        <ReactPlayer
          {...setting}
          config={{ file: { attributes: { disablepictureinpicture: "true" } } }}
        />
      )}
      <div className="list-actions">
        {/* <Link>
          <span className="icon icon-like-01"></span>
          <span className="count">2</span>
        </Link>
        <Link>
          <span className="icon icon-share-01"></span>
          <span className="count">3</span>
        </Link>
        <Link>
          <span className="icon icon-views-01"></span>
          <span className="count">4</span>
        </Link> */}
        {isDetailPage && (
          <CommonCardFooter
            reactionsState={reactionsState}
            setReactionsState={setReactionsState}
            videoId={videoId}
            activeEmoji={activeEmoji}
            setActiveEmoji={setActiveEmoji}
            viewsCount={viewsCount}
            isDetailPage={isDetailPage}
            showVoteButton={showVoteButton}
            animationShow={animationShow}
            setIsReacted={setIsReacted}
            handleProfileReaction={handleProfileReaction}
          />
        )}
      </div>
    </>
  );
};

export default VideoPlayer;
