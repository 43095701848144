/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import usePopup from "../../utils/hooks/use-popup";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Image,
  Dropdown,
} from "react-bootstrap";
import Sidebar from "../sidebar";
import LanguageSwitcher from "../common/languageSwitcher";
import useAuth from "../../utils/hooks/use-auth";
import { logoutUser } from "../../store/slices/auth-slice";
import {
  displayContactUs,
  displayUniqueCode,
} from "../../store/slices/pop-up.js";
import { useViewport } from "../../utils/context/viewport-context";
import { getAllHomepageDetailsALogin } from "../../utils/axios-helper/api-hander";
import { useDispatch, useSelector } from "react-redux";
import useLoader from "../../utils/hooks/use-loader";
import { menuData } from "../../utils/constants";
import useTranslation from "../../utils/hooks/use-translation";
import { displayInviteCode } from "../../store/slices/invite-code.js";
import {
  setAssuredWinsData,
  setGenereID,
  setReferFriend,
} from "../../store/slices/common-slice.js";
import {
  CounterFloodLight,
  MetaPixel,
  TTDPixel,
} from "../../utils/helpers/index.js";
import Notifications from "../../pages/NotificationV2.js";
import { getNotificationCount } from "../../services/notification";
import { setNotificationCount } from "../../store/slices/common-slice.js";
import UniqueCodeModal from "../common/appForm/UniqueCode.js";
import { ClickButtonCDPWithData } from "../../utils/cdp/cdpHelper";

const Header = () => {
  const { t } = useTranslation();
  let allMenuOptions = menuData(t);
  const { isMobile } = useViewport();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toggleLoader } = useLoader();
  const { isLoggedIn, userDetails } = useAuth();
  const [isExpanded, setIsExpanded] = useState(false);
  // const [notificationCount, setNotificationCount] = useState(0);

  const notificationCount = useSelector(
    (state) => state.common.notificationCount
  );
  const { openUniqueCodePopup } = usePopup();
  const { pathname } = useLocation();

  const isBackIconVisible = ["/ugc"].includes(pathname);

  const navbarRef = useRef();
  const homePageData = useSelector((state) => state?.common?.homepageDetails);
  const currentLanguageID = useSelector(
    (state) => state.common.currentLanguageID
  );

  useEffect(() => {
    if (userDetails?.id) {
      getNotificationCount({ params: { userId: userDetails?.id } })
        .then((res) => dispatch(setNotificationCount(res?.data?.data || 0)))
        .catch((err) => {});
    }
  }, [userDetails]);

  useEffect(() => {
    if (!isMobile) {
      setIsExpanded(false);
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isMobile]);

  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setIsExpanded(false);
    }
  };

  const toggleNavbar = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClick = () => {
    window.scrollTo(0, 0);
    CounterFloodLight("DC-12665261/sprit0/sprit01-+unique");
    navigate("/");
  };

  const windowLayerPush = () => {
    window.dataLayer = window.dataLayer || [];

    let indexToUpdate = -1;
    for (let i = 0; i < window.dataLayer.length; i++) {
      if (window.dataLayer[i].loginEvent) {
        indexToUpdate = i;
        break;
      }
    }

    if (indexToUpdate !== -1) {
      // Update the existing shareContent entry
      window.dataLayer[indexToUpdate].loginEvent = {
        loggedInEvent: false,
      };
    } else {
      // Add a new shareContent entry
      window.dataLayer.push({
        loginEvent: {
          loggedInEvent: false,
        },
      });
    }
  };

  const logoutUserAction = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("token");
    localStorage.removeItem("persist:root");
    dispatch(logoutUser());
    windowLayerPush();
    dispatch(setAssuredWinsData([]));
    dispatch(setGenereID(0));
    navigate("/");
    TTDPixel("3z343xi");
  };

  const metaPixelFn = (title) => {
    if (title === "Pick your mood") {
      MetaPixel("PickYourMood");
    } else if (title === "Contest") {
      MetaPixel("Contest");
      CounterFloodLight("DC-12665261/sprit0/sprit01e+unique");
    } else if (title === "PJ Challenge") {
      MetaPixel("ComicKaun?");
      CounterFloodLight("DC-12665261/sprit0/sprit01h+unique");
    } else if (title === "Joke Box") {
      CounterFloodLight("DC-12665261/sprit0/sprit01j+unique");
      MetaPixel("JokeBox");
      TTDPixel("ehis9x4");
    } else if (title === "Comedians' Spotlight") {
      MetaPixel("ComedianSpotlight");
    } else if (title === "Refer A Friend") {
      CounterFloodLight("DC-12665261/sprit0/sprit01m+unique");
      MetaPixel("ReferAFreind");
      TTDPixel("5e8js6i");
    } else if (title === "HALL-OF-LAME 🤪") {
      CounterFloodLight("DC-12665261/sprit0/sprit01k+unique");
      MetaPixel("HallOfFame");
      TTDPixel("cfnr6co");
    } else if (title === "Have an Invite Code?") {
      MetaPixel("HaveACode");
      CounterFloodLight("DC-12665261/sprit0/sprit01n+unique");
    }
  };
  const navigateTo = (route, navigationState, id) => {
    if (route === "contact-us") {
      setIsExpanded(false);
      dispatch(displayContactUs(true));
    } else if (route === "invite-code") {
      setIsExpanded(false);
      if (isLoggedIn) {
        dispatch(displayInviteCode(true));
      } else {
        navigate("/login");
      }
    } else if (route === "profile") {
      setIsExpanded(false);
      if (isLoggedIn) {
        dispatch(setReferFriend(true));
      } else {
        navigate("/login");
      }
    } else {
      if (route === "Contest") {
        MetaPixel("Contest");
      }
      setIsExpanded(false);
      navigate(route, { state: navigationState });
    }
  };

  return (
    <>
      <header className="header">
        <Navbar
          expand="lg"
          variant={"null"}
          bg={"light"}
          expanded={isExpanded}
          ref={navbarRef}
        >
          <Container fluid>
            {isBackIconVisible && isMobile ? (
              <span
                className="icon icon-back icon-Back-01"
                onClick={() => navigate(-1)}
              ></span>
            ) : (
              <Navbar.Toggle
                aria-controls="sidebar"
                onClick={toggleNavbar}
                className="navbar-toggler"
              />
            )}
            <h1>
              <Navbar.Brand onClick={handleClick}>
                <img
                  className="logo"
                  src="/assets/img/icons/header-logo.svg"
                  alt="Sprite"
                />
              </Navbar.Brand>
            </h1>

            <Nav className="align-items-center navbar-right d-lg-none">
              {isLoggedIn && (
                <div className="dropdown notification-d ">
                  <a
                    href={void 0}
                    className="icon-notifi icon-Notification-01"
                    onClick={() => {
                      getAllHomepageDetailsALogin(
                        dispatch,
                        toggleLoader,
                        currentLanguageID
                      );
                      ClickButtonCDPWithData("Consent_PushNotificationWeb", {
                        user_identifiers: [
                          {
                            user_identifier_type: "COKE_COOKIE",
                            user_identifier_sub_type: "CDS_PIXEL_COOKIE",
                            user_identifier: userDetails?.id,
                          },
                        ],
                      });
                      navigate("/notifications");
                    }}
                  >
                    {notificationCount > 0 && (
                      <span className="notification-count">
                        {notificationCount}
                      </span>
                    )}
                  </a>
                </div>
              )}
              {isMobile && <LanguageSwitcher />}
            </Nav>
            <Navbar.Collapse
              id="sidebar"
              className="offcanvas-menu d-lg-none sidebar"
            >
              <Sidebar navigateTo={navigateTo} />
            </Navbar.Collapse>
            {isExpanded && (
              <div className="offcanvas-backdrop" onClick={toggleNavbar}></div>
            )}
            <Navbar.Collapse className="d-none d-lg-block">
              <Nav className="me-auto navbar-left" as="ul">
                <NavDropdown
                  title={t.header.explore}
                  as="li"
                  className="tour-explore"
                >
                  {allMenuOptions &&
                    allMenuOptions[2].subMenu?.map((el, i) => {
                      return (
                        <React.Fragment key={el.id}>
                          {el.buttonComponent ? (
                            <NavDropdown.Item key={i}>
                              {el.buttonComponent}
                            </NavDropdown.Item>
                          ) : (
                            <NavDropdown.Item
                              onClick={() => {
                                navigateTo(el?.url, el?.state, el?.id);
                              }}
                              key={i}
                            >
                              <span
                                dangerouslySetInnerHTML={{ __html: el.title }}
                                onClick={() => {
                                  metaPixelFn(el?.title);
                                  navigateTo(el?.url, el?.state);
                                }}
                              />
                            </NavDropdown.Item>
                          )}
                        </React.Fragment>
                      );
                    })}
                </NavDropdown>
                <Nav.Item as="li">
                  <Nav.Link className="tour-contest" href={"/contest"}>
                    {t.common.contest}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Nav className="ml-auto align-items-center navbar-right">
                {/* {isLoggedIn && (
                  <UniqueCodeModal
                    type={"input"}
                    onClose={() => {
                      dispatch(displayUniqueCode(false));
                    }}
                  />
                )} */}
                {isLoggedIn && (
                  <Dropdown onToggle={console.log} className="">
                    <Dropdown.Toggle
                      id="dropdown-notification"
                      as="a"
                      className="icon-notifi icon-Notification-01  "
                    >
                      {notificationCount > 0 && (
                        <span className="notification-count">
                          {notificationCount}
                        </span>
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu align={"end"} as="div">
                      <Notifications popover={true} />
                    </Dropdown.Menu>
                  </Dropdown>
                )}

                <LanguageSwitcher />

                {isLoggedIn ? (
                  <>
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-user"
                        as="a"
                        className="header-profile-pic avatar_icon"
                      >
                        <Image
                          src={
                            userDetails && userDetails.avatarURL
                              ? userDetails.avatarURL
                              : "/assets/img/icons/profile-image.svg"
                          }
                          alt="profile-image"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu align={"end"} as="div">
                        <Dropdown.Item href="/my_profile">
                          <span>{t.header.my_profile}</span>
                          <img
                            className="icon"
                            alt="profile-icon"
                            src="/assets/img/icons/profile-icon.svg"
                          />
                        </Dropdown.Item>
                        <Dropdown.Item href="/my_wallet">
                          <span className="">{t.wallet.total_coins}</span>
                          <span className="icon icon-My-Wallet-01"></span>
                        </Dropdown.Item>
                        <Dropdown.Item href="#" onClick={logoutUserAction}>
                          <span>{t.header.logout}</span>
                          <img
                            className="icon "
                            src="/assets/img/icons/logout-icon.svg"
                            alt="icon"
                          />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-user"
                      as="a"
                      className="header-profile-pic avatar_icon"
                    >
                      <Image
                        src={
                          userDetails && userDetails.avatarURL
                            ? userDetails.avatarURL
                            : "/assets/img/icons/profile-image.svg"
                        }
                        alt=""
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu align={"end"} as="div">
                      <Dropdown.Item
                        href="#"
                        onClick={() => {
                          MetaPixel("Login");
                          navigate("/login");
                        }}
                      >
                        {t.header.login}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default Header;
