import axios from "axios";
import { apiErrorHandler } from "./api-hander";
import { API_BASE_URL, X_API_KEY, version } from "../../config";
import { store } from "../../store";
import { logoutUser } from "../../store/slices/auth-slice";
import { API_END_POINT } from "../../utils/constants/api-end-points";

// axios instance
export const AxiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
});
// v1/MixCode/verify-mixcode
const errorByPassRoutes = [`${version}${API_END_POINT.uniqueCode}`];

// Add a request interceptor
AxiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent

    const Token = localStorage.getItem("token");
    if (Token) {
      config.headers.Authorization = `Bearer ${Token}`;
    }
    config.headers["X-Api-Key"] = X_API_KEY;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
AxiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      // logout user on 401 error
      store.dispatch(logoutUser());
      localStorage.removeItem("userId");
      localStorage.removeItem("token");
      localStorage.removeItem("persist:root");
      // localStorage.removeItem("visitTime");
      // toast.error('You are authorised please login again');
      if (typeof window !== "undefined" && window.location.pathname !== "/") {
        if (
          error?.response?.data?.message ===
          "Account for 8908908900' is not active. Please contact the Administrator."
        ) {
          setTimeout(() => window.location.assign("/"), 3000);
        } else {
          if (
            !error?.response?.data?.message ===
            "On account of suspicious activity, you are hereby barred from participating under this campaign"
          ) {
            window.location.assign("/");
          }
        }
      }
    }
    console.log(error, error.response, "SERVER ERROR");
    if (
      error?.response?.data?.message !==
        "Maximum Joke Limit Reached for today." &&
      error?.response?.data?.message !== "Referral already sent." &&
      error?.response?.data?.message !== "Referred User already Exist." &&
      error?.response?.data?.message !== "Not Found" &&
      error?.response?.data?.message !== "No Content Available in Repo" &&
      !errorByPassRoutes.includes(error?.response?.config?.url)
    ) {
      apiErrorHandler(error?.response?.data);
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
