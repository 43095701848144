import React, { useState } from "react";
import AppModal from "./index";
import useTranslation from "../../utils/hooks/use-translation.js";
import { useSelector, useDispatch } from "react-redux";
import CommonFormikForm from "../../utils/form.js";
import { sourceUrlValidationSchema } from "../../utils/validations/index.js";
import useLoader from "../../utils/hooks/use-loader.js";
import { displayReportJoke } from "../../store/slices/report-joke.js";
import { sendJokeReportCall } from "../../services/ugc.js";

const ReportJokeModel = () => {
  const { t } = useTranslation();
  const { toggleLoader } = useLoader();
  const reportJoke = useSelector((state) => state.reportJoke.reportJoke);
  const reportVideoId = useSelector((state) => state.reportJoke.videoId);
  const [resSuccessModal, setResSuccessModal] = useState(false);
  const dispatch = useDispatch();
  let sourceUrlValidation = sourceUrlValidationSchema(t);

  const initialValues = {
    url: "",
  };

  const handleSubmit = async (values, { setFieldError, resetForm }) => {
    try {
      let reqData = {
        assetId: reportVideoId,
        information: values.url,
      };
      const res = await sendJokeReportCall(reqData);
      if (res.data && res.data.succeeded) {
        resetForm();
        dispatch(displayReportJoke(!reportJoke));
        setResSuccessModal(true);
      } else {
        let message = res.data && res.data.message;
        setFieldError("code", message);
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  };

  const renderInput = [
    {
      type: "text",
      name: "url",
      placeHolder: "Source URL",
      inputType: "text",
    },
  ];

  return (
    <>
      <AppModal
        show={reportJoke}
        onClose={() => dispatch(displayReportJoke(!reportJoke))}
        bodyClass="px-6 pt-2 pb-5"
        size=""
      >
        <div className="text-center">
          <h5 className="modal-title mb-3">
            Please provide a link below so we can verify if this joke is
            plagiarised
          </h5>
        </div>
        <CommonFormikForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          renderInputs={renderInput}
          ButtonText="Submit"
          validationSchema={sourceUrlValidation}
          referConditionsText=""
        />
      </AppModal>
      <AppModal
        show={resSuccessModal}
        onClose={() => setResSuccessModal(!resSuccessModal)}
        bodyClass="px-6 pt-2 pb-5"
        size=""
      >
        <div className="text-center">
          <img
            src="/assets/img/modal/thand-rakh.svg"
            alt="thand-rakh-image"
            className="modal-icon mb-md-4 mb-2 mt-n5"
          />
          <h5 className="modal-title mb-3">We heard you!​</h5>
          <p className=" modal-alert  ">
            Thand Rakh, we'll get this sorted soon!​
          </p>
        </div>
      </AppModal>
    </>
  );
};

export default ReportJokeModel;
