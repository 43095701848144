import React from 'react';
import { IMAGE_BASE_URL } from '../../config';
import { useViewport } from '../../utils/context/viewport-context';


const Image = ({ className, src, alt, imageType }) => {
    const { isMobile } = useViewport();
    let imagebaseURL = isMobile ? `${IMAGE_BASE_URL}/${imageType}/Mobile/` : `${IMAGE_BASE_URL}/${imageType}/Desktop/`
    return (
        <img
            className={className}
            src={imageType ? `${imagebaseURL}${src}` : src}
            alt={alt}
        />
    )
};

export default Image;