import { AxiosInstance } from "../utils/axios-helper/axios-helper";
import { API_END_POINT } from "../utils/constants/api-end-points";
import { version } from "../config";
import gluedin from "gluedin";

const userModule = new gluedin.GluedInUserModule();

export const getAllArtistDetails = async (data) => {
  return await AxiosInstance.get(
    `${version}${API_END_POINT.getAllArtist}?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`
  );
};

export const unlockJokeId = async (data) => {
  return await AxiosInstance.get(`${API_END_POINT.unlockJoke}?VideoId=${data}`);
};

export const getJokesByArtist = async () => {
  return await AxiosInstance.get(`${API_END_POINT.getJokeByArtist}`);
};

export const followArtist = async (artistId, followState) => {
  var formData = {
    followingId: artistId,
    isFollow: followState,
  };
  const userModuleResponse = await userModule.followUser(formData);
  if (userModuleResponse.status === 201 || userModuleResponse.status === 200) {
    return userModuleResponse?.data?.status;
  } else {
    return false;
  }
};
