import React, { useEffect, useState, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import useLoader from "../utils/hooks/use-loader";
import {
  getHomepageDetailsBeforeLogin,
  refreshToken,
  gluedinLogin,
  getJokeGenreID,
  getHomePopupInfo,
} from "../services";
import {
  setHomePageDetails,
  setGluedinDataByRailId,
  setIpDetailsInfo,
  setJokeSubmitSuccess,
  setLanguageID,
  setLanguage,
} from "../store/slices/common-slice";
import useAuth from "../utils/hooks/use-auth";
import { getAllHomepageDetailsALogin } from "../utils/axios-helper/api-hander";
import Tabs from "../components/common/tabs";
import TourGuide from "../components/common/TourGuide";
import useTranslation from "../utils/hooks/use-translation";
import { RailId } from "../config";
import ComingSoonSection from "../components/common/ComingSoonSection";
import { fetchFeedsForRailIds } from "../services";
import FollowSprite from "../components/FolllowSprite";
import {
  appendFollowing,
  appendVoting,
  loginSuccess,
} from "../store/slices/auth-slice";
import { getJokesByArtist } from "../services";
import { setJokeData } from "../store/slices/jokedata-slice";
import { setAnimationShow } from "../store/slices/common-slice";
import axios from "axios";
import { HomeCDP } from "../utils/cdp/cdpHelper";
import SuccessModel from "../components/modals/api_response_model";
import gluedin from "gluedin";
import { getUGCContent } from "../services/ugc";
import {
  CounterFloodLight,
  getCDSPixelId,
  TTDPixel,
  MetaPixel,
  showApiErrors,
} from "../utils/helpers";
import { getAllRewards } from "../services";
import SupriseMe from "../components/common/SupriseMe";
import {
  displayJokeOfTheDay,
  displaySuprise,
} from "../store/slices/suprise-me";
import RewardCardModal from "../components/common/RewardCardModal";
import {
  setContestPoolPrize,
  setJokeFeaturedPopup,
  setReferralDone,
  setShowRewardModal,
  setClaimDone,
} from "../store/slices/home-popup";
import CountDown from "../components/common/CountDown";
import usePopup from "../utils/hooks/use-popup";
import { displayWelcome } from "../store/slices/suprise-me";
import { useViewport } from "../utils/context/viewport-context";

// Lazy-loaded components
const HomeBanner = lazy(() => import("../components/common/Carousel"));
const GiggleCarousel = lazy(() => import("../components/common/Carousel"));
const ComedianSlider = lazy(() => import("../components/common/Carousel"));
const MoodPickerSlider = lazy(() => import("../components/common/Carousel"));

// Home component

const Home = () => {
  const { t } = useTranslation();
  const { toggleLoader } = useLoader();
  const { isLoggedIn } = useAuth();
  const { isMobile } = useViewport();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { openUniqueCodePopup, closeUniqueCodePopup } = usePopup();
  const feedModule = new gluedin.GluedInFeedModule();
  const toggleRewardModal = () => {
    dispatch(setContestPoolPrize({}));
    dispatch(setShowRewardModal(false));
  };

  const homePageData = useSelector((state) => state?.common?.homepageDetails);
  const jokeSubmit = useSelector((state) => state.common.jokeSubmitSuccess);
  const [gluedinData, setGluedinData] = useState({});
  const [activeTab, setActiveTab] = useState(1);
  const [giggleData, setGiggleData] = useState([]);
  const [welcomeMe, setWelcomeMe] = useState(false);
  let welcomeJourney = useSelector((state) => state?.supriseMe?.welcomeJourney);

  const [surpriseMe, setSurpriseMe] = useState(false); // State variable to track the Surprise Me popup
  const [jokeSubmitPopup, setJokeSubmitPopup] = useState(false);
  const [ugcUrlData, setUGCurlData] = useState([]);
  const [ugcAllData, setUGCAllData] = useState([]);
  const [ugcVideoData, setUgcVideoData] = useState([]);
  const [jokeFeaturedState, setJokeFeaturedState] = useState(false);
  const [referralDoneState, setRefferalDoneState] = useState(false);
  const [genreData, setGenreData] = useState([]);
  const currentLanguageID = useSelector(
    (state) => state.common.currentLanguageID
  );

  const tourGuideShow = useSelector((state) => state.common.tourGuide);
  const animationShow = useSelector((state) => state.common.animationShow);
  const ipDetailsInfo = useSelector((state) => state?.common?.ipDetailsInfo);
  const jokeFeaturedPopup = useSelector(
    (state) => state?.homePopup?.jokeFeaturedPopup
  );
  const referralDone = useSelector((state) => state?.homePopup?.referralDone);
  const claimDone = useSelector((state) => state?.homePopup?.claimDone);

  const showRewardModal = useSelector(
    (state) => state?.homePopup?.showRewardModal
  );
  const contestPoolPrize = useSelector(
    (state) => state?.homePopup?.contestPoolPrize
  );

  const windowLayerPush = (event) => {
    window.dataLayer = window.dataLayer || [];

    let indexToUpdate = -1;
    for (let i = 0; i < window.dataLayer.length; i++) {
      if (window.dataLayer[i].loginEvent) {
        indexToUpdate = i;
        break;
      }
    }

    if (indexToUpdate !== -1) {
      // Update the existing shareContent entry
      window.dataLayer[indexToUpdate].loginEvent = {
        loggedInEvent: event,
      };
    } else {
      // Add a new shareContent entry
      window.dataLayer.push({
        loginEvent: {
          loggedInEvent: event,
        },
      });
    }
  };

  useEffect(() => {
    if (isLoggedIn && welcomeJourney) {
      if (jokeSubmit?.state !== true && !jokeFeaturedPopup && !referralDone) {
        setTimeout(() => {
          setWelcomeMe(welcomeJourney);
        }, 1000);
      } else if (jokeSubmit?.state) {
        setJokeSubmitPopup(true);
      } else if (jokeFeaturedPopup) {
        setJokeFeaturedState(true);
      } else if (referralDone) {
        setRefferalDoneState(true);
      }
    } else {
      if (jokeSubmit?.state === true) {
        setJokeSubmitPopup(true);
      } else if (jokeFeaturedPopup) {
        setJokeFeaturedState(true);
      } else if (referralDone) {
        setRefferalDoneState(true);
      }
      setWelcomeMe(false);
    }
  }, [welcomeJourney, jokeSubmit, jokeFeaturedPopup, referralDone]);

  const referralType = {
    image: "/assets/img/modal/true-color-modal-icon.svg",
    heading: t.model_messages.accepted_referral_heading,
    subHeading: t.model_messages.accepted_referral_sub_heading,
    timer: false,
    cta: t.model_messages.got_it,
    description: "",
    id: 7,
  };

  const claimType = {
    image: "/assets/img/modal/claim_success.svg",
    heading: t.model_messages.all_boxes,
    subHeading: t.model_messages.successfully_claimed_reward_subheading,
    timer: false,
    cta: t.model_messages.explore_more_jokes,
    description: "",
    id: 8,
  };

  const customHeadingProps = {
    heading1: t.home.bring_funny,
    heading2: t.home.fablous_prizes,
    subheading: t.home.coming_soon,
    imgSrc: "/assets/img/bgs/comingsoon-bg.png",
  };

  const getAllRewardsData = async () => {
    const hfSessionKey = await getCDSPixelId();
    try {
      const rewardsRes = await getAllRewards({
        languageId: currentLanguageID || "1",
        hfSession: hfSessionKey,
      });
      dispatch(
        setHomePageDetails({
          coinCount: rewardsRes?.data?.data?.currentBalance ?? 0,
        })
      );
    } catch (err) {
      showApiErrors(err);
    }
  };

  useEffect(() => {
    TTDPixel("xhrh3dp");
    CounterFloodLight("DC-12665261/sprit0/sprit01-+unique");
    // const welcome = localStorage.getItem("welcome");

    // if (!welcome) {
    //   localStorage.setItem("welcome", "welcome");
    // }
    //Check if welcome message is shown
    const ipResponse = async () => {
      try {
        await fetchIPAddress();
        await HomeCDP(ipDetailsInfo);
      } catch (err) {
        showApiErrors(err);
      }
    };
    ipResponse();

    //SSO login
    const checkToken = new URLSearchParams(location.search).get("refresh");
    if (checkToken && !isLoggedIn) {
      autoLoginUser(checkToken);
    }

    let railIds = [RailId.comedians, RailId.giggle_central];
    fetchFeed(railIds);
    fetchServedJokes();
    //getSongDropDate()

    getJokeGenreID({ languageId: currentLanguageID || "1" }).then(
      (genreData) => {
        setGenreData(genreData.data.data);
      }
    );

    if (isLoggedIn) {
      getAllRewardsData();
    }

    // dispatch(displayJokeOfTheDay(true));
  }, []);

  useEffect(() => {
    //Time based session for 1st time pop up on homepage open - 30 minutes
    const checkToken = new URLSearchParams(location.search).get("refresh");
    let visitTime = localStorage.getItem("visitTime");
    let currentTime = new Date(Date.now());
    let nextVisitTime = new Date(currentTime.getTime() + 24 * 60 * 60 * 1000);
    if (isLoggedIn) {
      fetchSearchResults();
      getVoteOnUGC();
      getFollowingOnArtist();
      if (!visitTime) {
        dispatch(displaySuprise(true));
        dispatch(displayJokeOfTheDay(true));
        // openUniqueCodePopup();
      } else {
        visitTime = new Date(visitTime);
        currentTime = new Date(Date.now());
        if (currentTime.getTime() > visitTime.getTime()) {
          dispatch(displayJokeOfTheDay(true));
          dispatch(displaySuprise(true));
          // openUniqueCodePopup();
          localStorage.setItem(
            "visitTime",
            new Date(isLoggedIn ? nextVisitTime : Date.now()).toString()
          );
        } else {
          getNotifyPopup();
        }
      }
    }
    // else if (!checkToken) {
    //   // dispatch(displaySuprise(true));
    //   openUniqueCodePopup();
    // }
    else {
      dispatch(displaySuprise(true));
      // openUniqueCodePopup();
    }

    if (tourGuideShow.showTourGuide && isLoggedIn) {
      // closeUniqueCodePopup();
      dispatch(displayWelcome(true));
      dispatch(displaySuprise(false));
      // localStorage.setItem("welcome", "complete");
    }
    windowLayerPush(isLoggedIn);
  }, [isLoggedIn]);

  const getNotifyPopup = () => {
    if (isLoggedIn) {
      try {
        getHomePopupInfo(currentLanguageID || "1").then((res) => {
          if (res?.data?.data) {
            if (
              res?.data?.data?.length > 0 &&
              res?.data?.data?.[0].ugc?.length > 0
            ) {
              dispatch(setJokeFeaturedPopup(true));
            }
            if (
              res?.data?.data?.length > 0 &&
              res?.data?.data?.[0].referralsAccepted?.length > 0
            ) {
              dispatch(setReferralDone(true));
            }
            if (
              res?.data?.data?.length > 0 &&
              res?.data?.data?.[0].rewards?.length > 0 &&
              res?.data?.data?.[0].rewards[0]?.contestRewardsPools.length > 0
            ) {
              dispatch(setShowRewardModal(true));
              dispatch(
                setContestPoolPrize(
                  res?.data?.data?.[0].rewards[0]?.contestRewardsPools[0]
                )
              );
            }
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getVoteOnUGC = async () => {
    try {
      const actityvityTimelineModule = new gluedin.GluedInActivityTimeline();
      const response = await actityvityTimelineModule.getActivityTimelineList({
        event: "like",
        limit: 100000,
      });
      let votingData = {};
      response?.data?.result?.map(
        (el) => (votingData[el.metakeys.videoId] = true)
      );
      dispatch(appendVoting(votingData));
    } catch (err) {
      showApiErrors(err);
    }
  };

  const getFollowingOnArtist = async () => {
    try {
      const userModule = new gluedin.GluedInUserModule();
      const userConfigResponse = await userModule.getUserMetaIds();
      if (
        userConfigResponse.status === 200 ||
        userConfigResponse.status === 201
      ) {
        const following = userConfigResponse.data.result.following;
        let followingData = {};
        following?.map((el) => (followingData[el] = true));
        dispatch(appendFollowing(followingData));
      }
    } catch (err) {
      showApiErrors(err);
    }
  };

  useEffect(() => {
    fetchSearchResults();
  }, [activeTab]);

  const fetchSearchResults = async () => {
    let payload = {
      ugc: true,
      c_type: "UGC",
      offset: 1,
      sortBy: activeTab === 1 ? "newest" : "popular",
    };
    try {
      const data = await feedModule.getFeedList(payload);
      // payload.labels = "dance,music";
      // const data = await feedModule.filterVideos(payload);
      setUGCAllData(data?.data?.result);
      let videoIDs = [];
      if (data?.data?.result.length > 0) {
        data?.data?.result.map((vid) => {
          videoIDs.push(vid.videoId);
        });
        if (isLoggedIn) {
          getUGCContent(videoIDs).then((res) => {
            setUGCurlData(res?.data?.data);
          });
        }
      }
    } catch (error) {
      showApiErrors(error);
    }
  };

  useEffect(() => {
    let ugcVideos = [];
    ugcUrlData.map((urlVid) => {
      ugcAllData.map((vid) => {
        if (vid.videoId.toLowerCase() === urlVid.assestId.toLowerCase()) {
          let temp = {
            data: vid,
            url: urlVid.contentUrl,
          };
          ugcVideos.push(temp);
        }
      });
    });
    setUgcVideoData(ugcVideos);
  }, [ugcUrlData]);

  useEffect(() => {
    let timeout = setTimeout(() => dispatch(setAnimationShow(false)), 3000);
    return () => clearTimeout(timeout);
  }, [animationShow]);

  const fetchServedJokes = async () => {
    if (isLoggedIn) {
      toggleLoader(true);
      try {
        const resTwo = await getJokesByArtist();
        if (resTwo.status === 200) {
          dispatch(setJokeData(resTwo?.data?.data?.jokesServed));
        }
      } catch (err) {
        showApiErrors(err);
      } finally {
        toggleLoader(false);
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      getAllHomepageDetailsALogin(dispatch, toggleLoader, currentLanguageID);
    } else {
      getAllHomepageDetailsBLogin();
    }
  }, [isLoggedIn]);

  const fetchIPAddress = async () => {
    try {
      let ipDetails = await axios.get(`${process.env.REACT_APP_IP_API}`);
      dispatch(setIpDetailsInfo(ipDetails.data));
    } catch (error) {
      showApiErrors(error);
    }
  };

  const fetchFeed = async (railIds) => {
    toggleLoader(true);
    fetchFeedsForRailIds(railIds)
      .then((feeds) => {
        toggleLoader(false);
        // Process the fetched feeds here
        let data = {
          comedians: feeds.find(
            (obj) => obj.railId === process.env.REACT_APP_GLUEDIN_COMEDIAN_RAIL
          ),
          giggleCentral: feeds.find((obj) => obj.railName === "Giggle Central"),
        };

        dispatch(setGluedinDataByRailId(data));
        setGiggleData(data.giggleCentral?.itemList);

        setGluedinData(data);
      })
      .catch((error) => {
        toggleLoader(false);
        showApiErrors(error);
      });
  };

  const autoLoginUser = async (checkToken) => {
    try {
      const res = await refreshToken(checkToken, ipDetailsInfo.query);
      if (res.data && res.data.succeeded) {
        const data = res?.data?.data;
        let formData = {
          email: `${data.id}@gmail.com`,
          password: data.id,
        };
        gluedinLogin(formData, true);
        localStorage.setItem("token", data.jwtToken);
        localStorage.setItem("userId", data.id);
        dispatch(loginSuccess(data));
        setTimeout(() => getAllRewardsData(), 1000);
        //GA event added for successfully otp verification
        window.dataLayer.push({ event: "CSBharat_OTP_Submit" });
        let langId = new URLSearchParams(location.search).get("lang");
        let langCode = ["en", "hi", "te", "or", "ba", "mr", "kn", "bho", "mai"];
        dispatch(setLanguageID(langId));
        dispatch(setLanguage(langCode[langId - 1]));
        navigate("/");
      }
    } catch (error) {
      showApiErrors(error);
    }
  };
  //get all home page details before login
  const getAllHomepageDetailsBLogin = async () => {
    toggleLoader(true);
    try {
      let reqData = { languageId: currentLanguageID || "1" };
      const res = await getHomepageDetailsBeforeLogin(reqData);
      if (res.status === 200 && res.data.succeeded) {
        let section = res.data?.data;
        const updatedPayload = {
          sections: [...section],
          notificationCount: 0,
          coinCount: 0,
        };
        dispatch(setHomePageDetails(updatedPayload));
      }
    } catch (err) {
      showApiErrors(err);
    } finally {
      toggleLoader(false);
    }
  };

  let sectionData =
    homePageData &&
    Array.isArray(homePageData.sections) &&
    homePageData.sections.length
      ? homePageData.sections
      : "";
  let bannerData =
    sectionData && sectionData.find((obj) => obj.sectionName === "Banner");

  const customComicProps = {
    heading1: t.wallet.comic_kaun,
    // heading3: t.wallet.comickaun_heading,
    heading2: t.wallet.bottle_your_jokes,
    btnText: t.wallet.submt_win,
    buttonVariant: "dark",
    imgSrc: "/assets/img/bgs/comic-banner.svg",
  };

  const jokeContentType = {
    image: "/assets/img/modal/clap.svg",
    heading: "The crowd's going crazy!",
    subHeading: "Your joke just got featured in the Sprite® Joke Box 🙌",
    cta: t.common.check_out,
    id: 23,
    jokeSubmit: true,
  };

  const jokeSubmitType = {
    image: "/assets/img/modal/clap.svg",
    heading: t.model_messages.your_joke_in,
    subHeading: t.model_messages.you_did_good,
    cta: t.header.explore_more,
    id: 23,
    jokeSubmit: true,
  };

  return (
    <div className="position-relative">
      {welcomeMe && <TourGuide isOpen={welcomeMe} />}
      {/* {true && <TourGuide isOpen={true} />} */}
      {/* {surpriseMe ? (
        <SupriseMe isSupriseMe={true} onClose={setSurpriseMe} />
      ) : (
        <></>
      )} */}
      {/* countdown clock component  */}
      {/* <CountDown /> */}
      <Container fluid className="px-0">
        <Link to="/contest">
          <HomeBanner
            data={bannerData && bannerData.sectionDetailResponses}
            isHeader={false}
            timer={true}
            sliderType={1}
            SliderContainerClass={`px-0  `}
            sectionClass={"mt-1 mt-md-4 "}
            cardType={"image"}
            id={"hero_banner"}
            imageType={"Banners"}
            gaClass={"CSBharatHomeBanner"}
          />
        </Link>
      </Container>

      {/* <Container fluid>
        <ComingSoonSection
          {...customHeadingProps}
          sectionClass={"mt-md-12 mt-6 s-comingsoon s-shareJokes"}
          showCountdown={true}
          headingClass="s-font"
          navigation={true}
        />
      </Container> */}
      <div className="tour-pick left-wrapper"></div>
      <header className="s-header ">
        <div className="container-fluid  pr-0" id="giggle_central">
          <h3 className="s-title text-uppercase">{t.home.pick_your_mood}</h3>
          <h4 className="s-sub-title">{t.home.pick_your_mood_subtitle}</h4>
        </div>
      </header>

      <Container>
        <div className="row " id="pick_mood">
          <div className={`col-12 p-0`}>
            <MoodPickerSlider
              data={genreData}
              cardType={"moodPicker"}
              SliderContainerClass={`px-4 px-md-4`}
              sectionClass={"mt-md-8 mb-md-8 mb-0"}
              sliderType={7}
              isHeader={false}
              setSurpriseMe={setSurpriseMe}

              // handleCategorySelect={false}
            />
          </div>
        </div>
      </Container>

      {gluedinData?.comedians?.itemList && (
        <>
          <header className="s-header ">
            <div className="container-fluid  pr-0" id="giggle_central">
              <h3 className="s-title text-uppercase">{t.home.jokers_bottle}</h3>
              <h4 className="s-sub-title">{t.home.tap_see_all}</h4>
              <Link
                className="view-more"
                to="artist"
                state={gluedinData}
                onClick={() => MetaPixel("ComedianSpotlight")}
              >
                <span>{t.common.view_all}</span>
                <span className="icon icon-Right icon-Right-arrow-01"></span>
              </Link>
            </div>
          </header>
        </>
      )}
      {/* <Container>
        <div className="row">
          {gluedinData?.comedians?.itemList
            .slice(0, window.innerWidth >= 992 ? 6 : 4)
            .map((comedian, index) => (
              <div
                key={index}
                className={`col-${window.innerWidth >= 992 ? 2 : 3} p-0`}
              >
                <ComedianCard
                  id={comedian.assetId}
                  profileImageUrl={comedian?.profile?.profileImageUrl}
                  fullName={comedian?.profile?.fullName}
                  followersCount={comedian?.profile?.followersCount}
                  isHomePage={true}
                />
              </div>
            ))}
        </div>
      </Container> */}

      <Container>
        <div className="row">
          <div className="tour-comedian left-wrapper"></div>
          <div className={`col-12 p-0`}>
            <ComedianSlider
              data={gluedinData?.comedians?.itemList}
              cardType={"comedian"}
              SliderContainerClass={`px-0 px-md-4`}
              sectionClass={"mt-md-8 mb-md-8 mb-0"}
              sliderType={7}
              isHeader={false}
            />
          </div>
        </div>
      </Container>
      <Container>
        <ComingSoonSection
          {...customComicProps}
          showButton={true}
          sectionClass={"mt-5 s-shareJokes s-comic"}
          route={"/ugc_upload"}
          submitBtnId={"SpriteSubmitJoke"}
        />
      </Container>
      {/* tabs section  */}
      {isLoggedIn && (
        <>
          <header className="s-header ">
            <div className="container-fluid  pr-0" id="giggle_central">
              <h3 className="s-title text-uppercase">{t.home.joke_box}</h3>
              <h4 className="s-sub-title">{t.home.jokes_for_you_subtitle}</h4>
              <Link
                className="view-more"
                to="ugc"
                state={gluedinData}
                onClick={() => {
                  CounterFloodLight("DC-12665261/sprit0/sprit01j+unique");
                }}
              >
                <span>{t.common.view_all}</span>
                <span className="icon icon-Right icon-Right-arrow-01"></span>
              </Link>
            </div>{" "}
          </header>
          <div className="tour-tab left-wrapper "></div>
          <div className="giggle-carsouel">
            {ugcVideoData && ugcVideoData.length > 0 ? (
              <>
                <Tabs
                  tabLabels={["Latest", "Trending"]}
                  wrapperClass="tab-home mb-3"
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  isHeader={false}
                />

                {activeTab === 1 && (
                  <GiggleCarousel
                    data={ugcVideoData}
                    sliderType={4}
                    isHeader={false}
                    cardType="giggle"
                    isUgc={true}
                  />
                )}
                {activeTab === 2 && (
                  <GiggleCarousel
                    data={ugcVideoData}
                    sliderType={4}
                    cardType="giggle"
                    isUgc={true}
                    isHeader={false}
                  />
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </>
      )}
      {/* follow sprite section  */}
      <Container className="d-lg-none d-block ">
        <section className="list-follow-sprite mt-3">
          <FollowSprite />
        </section>
      </Container>
      {animationShow && ( // Conditionally render animation based on showAnimation prop
        <img
          className="modal-animation"
          style={{
            zIndex: "999",
            position: "fixed",
            top: "59%",
            left: "52%",
            transform: "translate(-50%, -50%)",
          }}
          alt="animation"
          src="/assets/img/animation/coin-animation.gif"
        />
      )}

      {jokeSubmitPopup && (
        <SuccessModel
          show={jokeSubmitPopup}
          {...jokeSubmitType}
          onClose={() => {
            dispatch(setJokeSubmitSuccess({ state: false, message: "" }));
            setJokeSubmitPopup(false);
            if (
              isLoggedIn &&
              welcomeJourney &&
              !jokeFeaturedPopup &&
              !referralDone
            ) {
              setWelcomeMe(true);
            }
          }}
        />
      )}

      {jokeFeaturedState && (
        <SuccessModel
          show={jokeFeaturedState}
          {...jokeContentType}
          onClose={() => {
            dispatch(setJokeFeaturedPopup(false));
            setJokeFeaturedState(false);
            if (
              isLoggedIn &&
              welcomeJourney &&
              jokeSubmit?.state !== true &&
              !referralDone
            ) {
              setWelcomeMe(true);
            }
          }}
        />
      )}

      {referralDoneState && (
        <SuccessModel
          show={referralDoneState}
          {...referralType}
          jokeSubmit={false}
          onClose={() => {
            dispatch(setReferralDone(false));
            setRefferalDoneState(false);
            if (
              isLoggedIn &&
              welcomeJourney &&
              jokeSubmit?.state !== true &&
              !jokeFeaturedPopup
            ) {
              setWelcomeMe(true);
            }
          }}
        />
      )}
      {/* {claimDone && (
        <SuccessModel
          show={claimDone}
          {...claimType}
          jokeSubmit={false}
          onClose={() => dispatch(setClaimDone(false))}
        />
      )} */}
      {/* <button onClick={toggleRewardModal}>Open Reward Modal</button> */}

      {/* <RewardCardModal
        show={showRewardModal}
        onClose={toggleRewardModal}
        contestPoolPrize={contestPoolPrize}
      /> */}
    </div>
  );
};

export default Home;
