import { createSlice } from "@reduxjs/toolkit";

// Define the initial state for the home popup slice
const initialState = {
  jokeFeaturedPopup: false,
  referralDone: false,
  showRewardModal: false,
  claimDone: false,
  contestPoolPrize: {},
};

// Create the authentication slice using `createSlice`
export const authSlice = createSlice({
  name: "homepopup", // Name of the slice
  initialState,
  reducers: {
    // Reducer function for handling successful popup
    setJokeFeaturedPopup: (state, actions) => {
      state.jokeFeaturedPopup = actions.payload;
    },
    setReferralDone: (state, actions) => {
      state.referralDone = actions.payload;
    },
    setShowRewardModal: (state, actions) => {
      state.showRewardModal = actions.payload;
    },
    setClaimDone: (state, actions) => {
      state.claimDone = actions.payload;
    },
    setContestPoolPrize: (state, actions) => {
      state.contestPoolPrize = actions.payload;
    },
  },
});

// Extract the action creators generated by `createSlice`
export const {
  setJokeFeaturedPopup,
  setReferralDone,
  setShowRewardModal,
  setClaimDone,
  setContestPoolPrize,
} = authSlice.actions;

// Export the reducer function generated by `createSlice`
export default authSlice.reducer;
