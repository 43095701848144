import React, { useState } from "react";
import AppModal from "./index";
import useTranslation from "../../utils/hooks/use-translation";
import { useSelector, useDispatch } from "react-redux";
import { displayInviteCode } from "../../store/slices/invite-code.js";
import CommonFormikForm from "../../utils/form.js";
import { referCodeValidationSchema } from "../../utils/validations/index.js";
import { verifyRefCodeAPI } from "../../services/profile-service.js";
import { CounterFloodLight, getCDSPixelId } from "../../utils/helpers/index.js";
import { getAllRewards } from "../../services/wallet.js";
import {
  setAnimationShow,
  setHomePageDetails,
} from "../../store/slices/common-slice.js";

import toast from "react-hot-toast";
import useLoader from "../../utils/hooks/use-loader.js";
import { getNotificationCount } from "../../services/notification.js";
import { setNotificationCount } from "../../store/slices/common-slice.js";
import useAuth from "../../utils/hooks/use-auth.js";

const InviteCodeModal = () => {
  const { t } = useTranslation();
  const { toggleLoader } = useLoader();
  const { userDetails } = useAuth();

  const invite = useSelector((state) => state.inviteCode.inviteCode);
  const currentLanguageID = useSelector(
    (state) => state.common.currentLanguageID
  );
  const dispatch = useDispatch();
  let referCodeValidation = referCodeValidationSchema(t);
  const animationShow = useSelector((state) => state.common.animationShow);

  const [cheatCodePopup, setCheatCodepopup] = useState("Valid code");

  const triggerNotificationCount = () => {
    getNotificationCount({ params: { userId: userDetails?.id } })
      .then((res) => dispatch(setNotificationCount(res?.data?.data || 0)))
      .catch((err) => {});
  };

  const initialValues = {
    code: "",
  };

  const getAllRewardsData = async () => {
    const hfSessionKey = await getCDSPixelId();

    const rewardsRes = await getAllRewards({
      languageId: currentLanguageID || "1",
      hfSession: hfSessionKey,
    });

    dispatch(
      setHomePageDetails({
        coinCount: rewardsRes?.data?.data?.currentBalance ?? 0,
      })
    );
  };

  const handleSubmit = async (values, { setFieldError, resetForm }) => {
    try {
      let reqData = {
        refCode: values.code,
      };
      const res = await verifyRefCodeAPI(reqData);
      if (res.data && res.data.succeeded) {
        CounterFloodLight("DC-12665261/sprit0/sprit01n+unique");
        window.dataLayer.push({ event: "Sprite24_ReferralCode_Submit" });
        toast.success(t.messages.refer_code_success);
        resetForm();
        setCheatCodepopup("Valid code");
        dispatch(displayInviteCode(false));
        dispatch(setAnimationShow(true));
        setTimeout(() => {
          dispatch(setAnimationShow(false));
        }, 3000);
        getAllRewardsData();
        triggerNotificationCount();
        //   onClose();
      } else {
        let message = res.data && res.data.message;
        setFieldError("code", message);
      }
    } catch (err) {
      setCheatCodepopup(err?.response?.data?.message);
    } finally {
      toggleLoader(false);
    }
  };

  const renderInput = [
    {
      type: "text",
      name: "code",
      placeHolder: "XXXXXX",
      inputType: "text",
    },
  ];

  return (
    <>
      <AppModal
        show={invite}
        onClose={() => dispatch(displayInviteCode(!invite))}
        animationShow={animationShow}
        bodyClass="px-6 pt-2 pb-5"
        size=""
      >
        <div className="text-center">
          {cheatCodePopup ===
          "You've already referred this person. Please add someone else." ? (
            <>
              <h5 className="modal-title">Cheat codes not allowed! 🚫 </h5>
              <p className="mb-4">
                You've entered a used referral code.
                <br />
                Please enter a valid code to proceed!
              </p>
            </>
          ) : cheatCodePopup === "Valid code" ? (
            <>
              <h5 className="modal-title">{t.header.have_an_invite_code} </h5>
              <p className="mb-4">{t.header.enter_collect_comic_coins}</p>
            </>
          ) : (
            <>
              <h5 className="modal-title">{t.header.have_an_invite_code} </h5>
              <p className="mb-4">Enter & collect Comic Coins</p>
            </>
          )}
        </div>
        <CommonFormikForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          renderInputs={renderInput}
          ButtonText="Submit"
          validationSchema={referCodeValidation}
          referConditionsText=""
        />
      </AppModal>
      {animationShow && (
        <img
          className="modal-animation"
          style={{
            zIndex: "999",
            position: "fixed",
            top: "59%",
            left: "52%",
            transform: "translate(-50%, -50%)",
          }}
          src="/assets/img/animation/coin-animation.gif"
          alt="animation"
        />
      )}
    </>
  );
};

export default InviteCodeModal;
