import { createSlice } from "@reduxjs/toolkit";


// Define the initial state for the authentication slice
const initialState = {
  reportJoke : false,
  videoId:''
};

// Create the authentication slice using `createSlice`
export const popUpSlice = createSlice({
  name: "report", // Name of the slice
  initialState,
  reducers: {
    
    displayReportJoke: (state, action) => {
      const { payload } = action; // Payload contains the user details
      state.reportJoke = payload; // Set isLoggedIn to true if payload is not empty
    },
    reportedVideoId: (state, action) => {
      const { payload } = action; // Payload contains the user details
      state.videoId = payload; // Set isLoggedIn to true if payload is not empty
    }
  },
});

// Extract the action creators generated by `createSlice`
export const { displayReportJoke,reportedVideoId } = popUpSlice.actions;

// Export the reducer function generated by `createSlice`
export default popUpSlice.reducer;
