import React, { useState } from "react";
import Slider from "react-slick";

const CommonSlider = ({ children, ...props }) => {
  const {
    slideShowD,
    slideShowM,
    cPadding,
    slidesToScrollD,
    slidesToScrollM,
    handleSlideChange,
    rows,
    slidesPerRow,
    infinite_m,
    showSlider,
    centerModeM,
    centerModeD,
    infinite_d,
    loopD,
    dPadding,
    isAutoPlay = true,
    controls = true,
    muted = false,
  } = props;
  const extendedObject =
    rows !== undefined && slidesPerRow !== undefined
      ? { rows, slidesPerRow }
      : {};
  const [activeSlide, setActiveSlide] = useState(0);
  const settings = {
    // className: `${className}`,
    // centerMode: `${centerMode}`,
    // // className:centerMode,
    // centerMode:true,
    dots: true,
    infinite: infinite_d,
    arrows: false,
    slidesToShow: slideShowD,
    slidesToScroll: slidesToScrollD,
    centerPadding: dPadding,
    centerMode: centerModeD,
    adaptiveHeight: true,
    loop: loopD,
    isAutoPlay: isAutoPlay,
    controls: controls,
    muted: muted,
    beforeChange: (oldIndex, newIndex) => {
      handleSlideChange();
      setActiveSlide(newIndex);
    },

    responsive: [
      {
        breakpoint: 767,
        settings: {
          centerMode: centerModeM,
          slidesToShow: slideShowM,
          slidesToScroll: slidesToScrollM,
          infinite: infinite_m,
          centerPadding: cPadding,
        },
      },
    ],
  };

  return (
    <>
      {showSlider ? (
        // <Slider {...settings} {...extendedObject}>
        //   {children}
        // </Slider>
        <Slider {...settings} {...extendedObject}>
          {React.Children.map(children, (child, index) =>
            React.cloneElement(child, {
              isAutoPlay: index === activeSlide,
            })
          )}
        </Slider>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default CommonSlider;
