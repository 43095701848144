import { createSlice } from "@reduxjs/toolkit";


// Define the initial state for the authentication slice
const initialState = {
  inviteCode : false
};

// Create the authentication slice using `createSlice`
export const popUpSlice = createSlice({
  name: "invite", // Name of the slice
  initialState,
  reducers: {
    
    displayInviteCode: (state, action) => {
      const { payload } = action; // Payload contains the user details
      state.inviteCode = payload; // Set isLoggedIn to true if payload is not empty
    }
  },
});

// Extract the action creators generated by `createSlice`
export const { displayInviteCode } = popUpSlice.actions;

// Export the reducer function generated by `createSlice`
export default popUpSlice.reducer;
