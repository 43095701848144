import React, { useEffect, useState } from "react";
import { setReferFriend } from "../../store/slices/common-slice";
import { useDispatch, useSelector } from "react-redux";
import ReferAFriendModal from "../common/appForm/ReferFriendForm";
import SuccessModel from "../../components/modals/api_response_model";
import useTranslation from "../../utils/hooks/use-translation";

const ReferPopup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showReferPopup, setShowReferPopup] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [showThankYouModal, setShowThankYouModal] = useState(false);

  const refer = useSelector((state) => state.common.referFriend);

  useEffect(() => {
    if (refer === true) {
      setShowReferPopup(true);
    }
  }, [refer]);

  // const getReferralList = async (page) => {
  //     try {
  //       let reqData = {
  //         PageNumber: page ? page : 1,
  //         Pagesize: pageSize,
  //       };
  //       const res = await getAllreferralAPI(reqData);
  //       if (res.status === 200) {
  //         let data =
  //           res.data &&
  //             res.data.data &&
  //             Array.isArray(res.data.data.data) &&
  //             res.data.data.data.length
  //             ? res.data.data.data
  //             : [];
  //         setAllReferralData(res.data.data);
  //         setReferralList(data);
  //       }
  //     } catch (err) { }
  //   };

  const closeReferPopup = () => {
    setShowReferPopup(false);
    dispatch(setReferFriend(false));
  };

  const contentType = {
    image: "/assets/img/modal/right-modal-icon.svg",
    heading: t.model_messages.easy_peasy_lemon,
    subHeading: t.model_messages.successfully_referred_friend,
    referralCode: referralCode ? referralCode : "",
    timer: false,
    // description: t.common.successful_sign_up_using_invite_code,
    id: 19,
  };

  return (
    <>
      {showReferPopup && (
        <ReferAFriendModal
          show={showReferPopup}
          onClose={closeReferPopup}
          type={"refer_number"}
          // getReferralList={getReferralList}
          showThankYouModal={showThankYouModal}
          setShowThankYouModal={setShowThankYouModal}
          setReferralCode={setReferralCode}
        />
      )}
      {showThankYouModal && (
        <SuccessModel
          show={showThankYouModal}
          {...contentType}
          onClose={() => setShowThankYouModal(false)}
        />
      )}
    </>
  );
};

export default ReferPopup;
