import React, { useState, useRef, useEffect } from "react";
import { Card, Container } from "react-bootstrap";
import CommonCardFooter from "../footer/CommonCardFooter";
import VedioCard from "./vedioCards";
import ReactionFooter from "../footer/ReactionFooter";
import TitleShowCase from "../common/TitleShowCase";
import useAuth from "../../utils/hooks/use-auth";
import gluedin from "gluedin";
import { useSelector } from "react-redux";
import useTranslation from "../../utils/hooks/use-translation";

const GiggleCard = ({
  data,
  isLocked = false,
  showVoteButton = false,
  ugcUrl,
  unlockJoke,
  isUgc,
  onPlay = () => {},
  handleAudioPause,
  handleProfileReaction = () => {},
}) => {
  const { isLoggedIn } = useAuth();
  const audioRef = useRef(null);
  const { t } = useTranslation();
  const videoRef = useRef(null);
  const [activeEmoji, setActiveEmoji] = useState("");
  const [canReact, setCanReact] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [viewCounter, setViewCounter] = useState(0);
  const [contentDescription, setContentDescription] = useState("");
  const [reactionsState, setReactionsState] = useState({
    laugh: data?.reactions?.laugh || 0,
    neutral: data?.reactions?.neutral || 0,
    sad: data?.reactions?.sad || 0,
  });

  const currentLanguage = useSelector((state) => state.common.currentLanguage);

  let timer = null;
  const cardRef = useRef(null);

  useEffect(() => {
    setViewCounter(data?.viewsCount);
    if (data?.contentType !== "text") {
      setContentDescription(data?.description);
    } else {
      fetchContent(ugcUrl);
    }
    return () => clearTimeout(timer); // Cleanup function to clear timer if component unmounts or rerenders
  }, []);

  const viewCountHandler = () => {
    var activityTimelineModuleObj = new gluedin.GluedInActivityTimeline();
    activityTimelineModuleObj
      .activityTimelineView({ assetId: data?.videoId })
      .then((response) => {
        setViewCounter((prev) => prev + 1);
      });
  };

  const onCardInView = () => {
    if (data?.contentType !== "video" && data?.contentType !== "audio") {
      viewCountHandler();
    }
  };

  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          onCardInView();
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.3, // 30% of the card must be visible to trigger
    });

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, [data]);

  const handlePlay = () => {
    onPlay(audioRef.current);
  };

  const handleAudioEnd = () => {
    viewCountHandler();
  };

  const tagForImage = () => {
    if (data?.contentType === "video") {
      return (
        <img
          src={"/assets/img/icons/video-icon.svg"}
          alt="Icon"
          className="icon-type "
          loading="lazy"
        />
      );
    } else if (data?.contentType === "audio") {
      return (
        <img
          src={"/assets/img/icons/audio-icon.svg"}
          alt="Icon"
          className="icon-type "
          loading="lazy"
        />
      );
    } else if (data?.contentType === "text") {
      return (
        <img
          src={"/assets/img/icons/text-icon.svg"}
          alt="Icon"
          className="icon-type "
          loading="lazy"
        />
      );
    } else if (data?.contentType === "image") {
      return (
        <img
          src={"/assets/img/icons/image-icon.svg"}
          alt="Icon"
          className="icon-type "
          loading="lazy"
        />
      );
    }
  };

  const renderDescription = (description) => {
    return (
      <>
        {description.length > 100 && (
          <>
            <button
              className="text-b show-more-content"
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? "Show Less" : "Show More"}
            </button>
          </>
        )}
      </>
    );
  };

  const fetchContent = async (url) => {
    try {
      let res = await fetch(url);
      let text = await res.text();
      setContentDescription(text);
    } catch (error) {
      console.error("Error fetching content:", error);
    }
  };

  return (
    <Container className="px-0">
      <Card className="card-joke giggle " ref={cardRef}>
        <TitleShowCase
          showCardReport={true}
          jokeGenre={data?.labels?.[1]}
          jokeLang={data?.labels?.[0]}
          videoId={data?.videoId}
          jokeTitle={
            data?.localisedTitle?.[currentLanguage] ?? data?.localisedTitle?.["en"]
          }
          author={
            data?.user.localisedFullName?.[currentLanguage] ??
            data?.user.localisedFullName?.["en"]
          }
          isLoggedIn={isLoggedIn}
        />
        {isUgc && <p className="ugc_disclaimer">{t.common.disclaimer}® </p>}
        {isLocked ? (
          <Card.Body className="">
            <img
              src={"/assets/img/modal/surpriseme_mobile.png"}
              alt="Icon"
              className="w-100 img"
              loading="lazy"
            />
            <img
              onClick={() => {
                unlockJoke(data);
              }}
              src={"/assets/img/modal/lock.svg"}
              alt="Icon"
              className="icon-lock locked-joke"
              loading="lazy"
            />
            {tagForImage()}
          </Card.Body>
        ) : (
          <Card.Body className="">
            {data?.contentType === "video" && (
              <VedioCard
                key={data?.videoId}
                index={data?.videoId}
                image={data?.thumbnailUrl}
                altText={data?.title}
                views={data?.viewsCount}
                videoURL={
                  ugcUrl ||
                  (data?.contentUrls[0]?.urls
                    ? data?.contentUrls[0]?.urls[0]
                    : data.downloadUrl)
                }
                fullWidthVideo={true}
                imgClass={""}
                videoRef={videoRef}
                showVoteButton={showVoteButton}
                // setSelectedIndex={handleCardClick}
                // selectedIndex={selectedIndex}
                isCoinWinFeatureAllowed={false}
                id={"jokeCard"}
                blockClass={"img-block-overlay overlay"}
                SliderContainerClass={`pr-0 px-md-4 slider-gutter-20`}
                gaClass={"CSBharatMelodies"}
                videoLock={false}
                iconBlockTop={""}
                // reactions={data?.reactions}
                videoId={data?.videoId}
                activeEmoji={activeEmoji}
                setActiveEmoji={setActiveEmoji}
                viewsCount={viewCounter}
                isDetailPage={true}
                // isExclusive={el.isExclusive}
                vedioType={"comedian-detail"}
                setCanReact={setCanReact}
                viewCountHandler={viewCountHandler}
                data={data}
                reactionsState={reactionsState}
                setReactionsState={setReactionsState}
                handleAudioPause={handleAudioPause}
                handleProfileReaction={handleProfileReaction}
              />
            )}
            {data?.contentType === "image" && (
              <img
                src={ugcUrl ? ugcUrl : data?.joke}
                alt="Icon"
                loading="lazy"
                className="joke-img"
              />
            )}
            {data?.contentType === "text" && (
              <div
                className={`c-text ${
                  data?.contentType === "text" ? "text-post" : ""
                }`}
              >
                <p>
                  {data?.contentType === "text"
                    ? contentDescription?.length > 325
                      ? contentDescription?.substring(0, 325)
                      : contentDescription
                    : showMore && data?.contentType !== "text"
                    ? contentDescription
                    : `${contentDescription.substring(0, 100)}`}
                  {data?.contentType !== "text"
                    ? renderDescription(contentDescription)
                    : ""}
                </p>
              </div>
            )}
            {data?.contentType === "audio" ? (
              <div style={{ position: "relative" }} className="w-100">
                {tagForImage()}
                <img
                  src={"/assets/img/modal/surpriseme_mobile.png"}
                  alt="Icon"
                  className="w-100 img"
                  loading="lazy"
                />
                <audio
                  ref={audioRef}
                  onPlay={handlePlay}
                  onEnded={handleAudioEnd}
                  src={
                    ugcUrl ||
                    (data?.contentUrls[0]?.urls
                      ? data?.contentUrls[0]?.urls[0]
                      : data.downloadUrl)
                  }
                  controls
                  style={{
                    height: "20%",
                    width: "90%",
                    // zIndex: "999",
                    position: "absolute",
                    bottom: "10px",
                    left: "5%",
                    borderRadius: "0",
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </Card.Body>
        )}
        <Card.Footer className="">
          {!isLocked && canReact ? (
            <CommonCardFooter
              // reactions={data.reactions}
              videoId={data?.videoId}
              activeEmoji={activeEmoji}
              setActiveEmoji={setActiveEmoji}
              viewsCount={viewCounter}
              isDetailPage={true}
              showVoteButton={showVoteButton}
              reactionsState={reactionsState}
              setReactionsState={setReactionsState}
              isLoggedIn={isLoggedIn}
              contentType={data?.contentType}
              viewCountHandler={viewCountHandler}
              handleProfileReaction={handleProfileReaction}
            />
          ) : (
            <ReactionFooter
              isArtistDetail={true}
              reactions={reactionsState}
              viewsCount={viewCounter}
              showVoteButton={showVoteButton}
              videoId={data?.videoId}
              isLoggedIn={isLoggedIn}
            />
          )}
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default GiggleCard;
