import React from "react";
import { Link, useLocation } from "react-router-dom";
import usePopup from "../../utils/hooks/use-popup";
import useAuth from "../../utils/hooks/use-auth";
import { useSelector } from "react-redux";
import useTranslation from "../../utils/hooks/use-translation";
import { displayContactUs } from "../../store/slices/pop-up.js";
import { useDispatch } from "react-redux";
import { ClickButtonCDP } from "../../utils/cdp/cdpHelper.js";
import { CounterFloodLight, MetaPixel } from "../../utils/helpers/index.js";

function Footer() {
  const { t } = useTranslation();
  const { openUniqueCodePopup } = usePopup();
  const dispatch = useDispatch();
  const { isLoggedIn } = useAuth();
  const homePageData = useSelector((state) => state?.common?.homepageDetails);
  const { pathname } = useLocation();

  const openContactUsModal = () => {
    CounterFloodLight("DC-12665261/sprit0/sprit01p+unique");
    MetaPixel("ContactUs");
    dispatch(displayContactUs(true));
  };
  const linkClassname =
    "d-flex justify-content-center align-items-center flex-column";

  const whatsappBotFn = (e) => {
    e.preventDefault();
    ClickButtonCDP("Follow_JIAB_WA");
    window.open(
      `https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_WP_NUM}&text=Hi,+I+am+here+for+Sprite+Joke-In-A-Bottle!+By+pressing+Send+on+this,+I+give+my+consent+to+sharing+my+mobile+number+and+display+name+with+Sprite.`,
      "_blank"
    );
  };

  return (
    <footer className="footer">
      <div className="container-fluid px-0">
        <div className="d-block d-lg-none">
          <div className="row">
            <div className="col">
              <Link
                to="/"
                className={`link ${linkClassname} ${
                  pathname === "/" ? "active" : ""
                }`}
                onClick={() => MetaPixel("HomePage")}
              >
                <div className="d-flex justify-content-center align-items-center flex-column  home">
                  <img
                    src={
                      pathname === "/"
                        ? "/assets/img/footer/home-active.svg"
                        : "/assets/img/footer/home-icon.svg"
                    }
                    alt="Logo"
                    className="footer-icon"
                  />
                  <span>{t.header.footer_home}</span>
                  <div className="border-footer"></div>
                </div>
              </Link>
            </div>
            <div className="col">
              <Link
                to="/contest"
                onClick={() => {
                  MetaPixel("Contest");
                }}
                className={` Sprite_Contest_button link ${linkClassname} ${
                  pathname === "/contest" ? "active" : ""
                }`}
              >
                <div className="d-flex justify-content-center align-items-center flex-column pt-0 contest">
                  <img
                    src="/assets/img/footer/contest-icon.svg"
                    alt=" Logo"
                    className="footer-icon"
                  />
                  <span>{t.common.footer_contest}</span>
                  <div className="border-footer"></div>
                </div>
              </Link>
            </div>

            {/* enter unique code popup  */}
            {/* <div className="tour-unique-code"></div>
            <div className="col  d-flex align-items-center unique_code p-0">
              <div
                className="link d-flex justify-content-center align-items-center flex-column unique-code"
                onClick={() => openUniqueCodePopup()}
              >
                <img
                  src="/assets/img/footer/unique-code.svg"
                  alt=" Logo"
                  className="footer-img ml-1"
                />
                <span
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: t.common.unique_code,
                  }}
                ></span>
              </div>
            </div> */}
            <div className="col ">
              <Link
                to={isLoggedIn ? "/my_wallet" : "/login"}
                className={`link ${linkClassname} ${
                  pathname === "/my_wallet" ? "active" : ""
                }`}
              >
                <div className="d-flex justify-content-center align-items-center flex-column  wallet">
                  <img
                    src={
                      pathname === "/my_wallet"
                        ? "/assets/img/footer/comic-icon-active.svg"
                        : "/assets/img/footer/comic-icon.svg"
                    }
                    alt=" Logo"
                  />
                  <small className="count">
                    {homePageData && homePageData.coinCount}
                  </small>
                  <span className="">{t.wallet.total_coins}</span>
                  <div className="border-footer "></div>
                </div>
              </Link>
            </div>
            <div className="col">
              <Link
                // to={isLoggedIn ? '/my_profile' : '/auth_access/my_profile'}
                to={isLoggedIn ? "/my_profile" : "/login"}
                className={`link ${linkClassname} ${
                  pathname === "/my_profile" ? "active" : ""
                }`}
                onClick={() => {}}
              >
                <div className="d-flex justify-content-center align-items-center flex-column  profile profile-tour">
                  <img
                    src={
                      pathname === "/my_profile"
                        ? "/assets/img/footer/user-icon-active.svg"
                        : "/assets/img/footer/user-icon.svg"
                    }
                    alt=" Logo"
                    className="footer-icon"
                  />
                  <span>{t.common.footer_profile}</span>
                  <div className="border-footer "></div>
                </div>
              </Link>
            </div>
          </div>
        </div>

        {/* desktop footer part  */}
        <div className="desk-footer d-lg-block d-none ">
          <div className="row">
            <div className="col-12">
              <div className="border-bottom pb-7 mb-12">
                <img
                  src="./assets/img/icons/header-logo.svg"
                  alt="Logo"
                  className="logo"
                />
              </div>
            </div>
            <div className="col-12">
              <Link to="#" className="text-link" onClick={openContactUsModal}>
                {t.common.need_help}
              </Link>
            </div>

            <div className="col-3 col-lg-5 d-flex justify-content-between">
              <ul className="pl-0">
                <li
                  className="py-2"
                  onClick={() => {
                    CounterFloodLight("DC-12665261/sprit0/sprit01q+unique");
                    MetaPixel("FAQs");
                  }}
                >
                  <Link to="/faq" className="link text-capitalize">
                    {t.header.faq}
                  </Link>
                </li>

                <li className="py-2">
                  <Link to="/sitemap" className="link">
                    {t.common.sitemap}
                  </Link>
                </li>
              </ul>
              <ul className="pl-0">
                <li
                  className="py-2"
                  onClick={() => {
                    CounterFloodLight("DC-12665261/sprit0/sprit01r+unique");
                    MetaPixel("TnCs");
                  }}
                >
                  <Link to="/terms_conditions" className="link">
                    {t.header.terms_and_conditions}
                  </Link>
                </li>
                <li
                  className="py-2"
                  onClick={() => {
                    CounterFloodLight("DC-12665261/sprit0/sprit01s+unique");
                    MetaPixel("PrivacyPolicy");
                  }}
                >
                  <Link to="/privacy_policy" className="link">
                    {t.header.privacy_policy}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-9 col-lg-7 justify-content-end align-items-end d-flex  list-follow-sprite bg-dark">
              <ul className="list-social d-flex justify-content-center align-items-end">
                <li className="list-item">
                  <Link
                    to={"https://www.facebook.com/spritein/"}
                    target={"_blank"}
                    className="list-link"
                    onClick={() => {
                      MetaPixel("Facebook");
                      ClickButtonCDP("Follow_JIAB_FB");
                    }}
                  >
                    <span className="icon icon-FB-01"></span>
                  </Link>
                </li>
                <li className="list-item">
                  <Link
                    to={"https://www.instagram.com/sprite_india/"}
                    target={"_blank"}
                    className="list-link"
                    onClick={() => {
                      MetaPixel("Instagram");
                      ClickButtonCDP("Follow_JIAB_Insta");
                    }}
                  >
                    <span className="icon icon-insta-01"></span>
                  </Link>
                </li>
                {/* <li className="list-item">
                      <Link
                        to={
                          "https://x.com/sprite_india?t=Eb0BsU1Yy_JKW0uZWgKQhw&s=09"
                        }
                        className="list-link"
                        target={"_blank"}
                        onClick={() => ClickButtonCDP("Follow_JIAB_Twitter")}
                      >
                        <span className="icon icon-twitter-01"></span>
                      </Link>
                    </li> */}
                <li className="list-item">
                  <Link
                    to={"https://youtube.com/@Sprite?si=9SMfN0zKUk7Mxteb"}
                    className="list-link"
                    target={"_blank"}
                    onClick={() => {
                      MetaPixel("YouTube");
                      ClickButtonCDP("Follow_JIAB_YT");
                    }}
                  >
                    <span className="icon icon-Youtube-01"></span>
                  </Link>
                </li>
                <li className="list-item">
                  <Link
                    className="list-link"
                    onClick={(e) => {
                      MetaPixel("WhatsApp");
                      whatsappBotFn(e);
                    }}
                    target="_blank"
                  >
                    <span className="icon icon-whatsapp-01"></span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-12 ">
              <div className="d-flex justify-content-between align-items-center footer-border border-top pt-7 mt-12">
                <div className="col-12 justify-content-end align-items-end d-flex  px-md-0">
                  <div className="mt-md-4 ">
                    <p className="text-light text-right footer-subheading ">
                      &copy; {t.common.footer_subheading}
                    </p>
                    <p className="text-light text-right d-md-none ">
                      {t.common.footer_heading}
                      <img
                        className="dietary-icon"
                        src="/assets/img/icons/dietary_symbol.svg"
                        alt=""
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid footer-links d-lg-none">
          <div className="row align-items-center mt-2 ">
            <div className="col-4 mr-0 pr-0">
              <Link
                to="/terms_conditions"
                className="border-right pr-1 bootom-link"
                onClick={() =>
                  CounterFloodLight("DC-12665261/sprit0/sprit01r+unique")
                }
              >
                {t.header.terms_conditions}
              </Link>
              <Link
                to="/privacy_policy"
                className="pl-1 bootom-link"
                onClick={() =>
                  CounterFloodLight("DC-12665261/sprit0/sprit01s+unique")
                }
              >
                {t.header.privacy_policy}
              </Link>
            </div>
            <div className="col-8 text-right">
              <p className="mb-n1 text-nowrap">
                {t.common.footer_heading}
                <img src="/assets/img/icons/dietary_symbol.svg" alt="" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
