import React, { useRef, useEffect } from "react";
import AppModal from "./index";
import useTranslation from "../../utils/hooks/use-translation";
import { Button } from "react-bootstrap";

import RewardShareCard from "../cards/RewardShareCard";
import { useSelector } from "react-redux";
import { getShareAbleImage, shareFile } from "../../utils/common";
import styled from "styled-components";

const ShareBody = styled.div`
  text-align: center;
`;

const ShareWithFriends = ({ show, onClose }) => {
  const { t } = useTranslation();
  const shareWithFriendsDetails = useSelector(
    (state) => state?.popUp?.showShareWithFriends
  );
  const shareCardRef = useRef(null);
  const [loading, setLoading] = React.useState(true);
  const [file, setFile] = React.useState(null);

  const handleShare = async () => {
    shareFile({
      file,
      title: shareWithFriendsDetails.title,
      text: shareWithFriendsDetails.description,
      url: shareWithFriendsDetails.url,
    });
  };

  const getSetImage = async () => {
    if (shareCardRef.current) {
      const file = await getShareAbleImage(shareCardRef);
      setFile(file);
      setLoading(false);
    }
  };
  useEffect(() => {
    getSetImage();
  }, [shareCardRef]);

  return (
    <AppModal
      show={show}
      onClose={onClose}
      bodyClass=" modal-response"
      size="md"
    >
      <ShareBody>
        <div ref={shareCardRef}>
          <RewardShareCard {...shareWithFriendsDetails} />
        </div>
        <Button
          disabled={loading}
          variant="warning "
          className="btn-card mt-2"
          onClick={handleShare}
          type="button"
        >
          {loading ? "Loading..." : "Share with Friends"}
        </Button>
      </ShareBody>
    </AppModal>
  );
};

export default ShareWithFriends;
