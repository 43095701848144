import React, { useEffect, useState } from "react";
import useTranslation from "../../utils/hooks/use-translation";
import useAuth from "../../utils/hooks/use-auth.js";
import { useLocation, useNavigate } from "react-router-dom";
import { setNotificationCount } from "../../store/slices/common-slice.js";
import { useDispatch } from "react-redux";
import { getNotificationCount } from "../../services/notification.js";
import SuccessModel from "../../components/modals/api_response_model.js";

const ProfileExitPopup = () => {
  const { t } = useTranslation();
  const navigation = useLocation();
  const navigate = useNavigate();
  const { userDetails } = useAuth();
  const dispatch = useDispatch();


  const [previousLocation, setPreviousLocation] = useState(null);
  const [currLocation, setCurrLocation] = useState(null);

  const [profileExitPopup, setProfileExitPopup] = useState(false);

  useEffect(() => {
    setPreviousLocation(currLocation);
    setCurrLocation(navigation);
  }, [navigation]);

  useEffect(() => {
    if (
      userDetails?.profileCompletionPercentage < 100 &&
      previousLocation?.pathname === "/my_profile" &&
      currLocation?.pathname !== "/my_profile/1" &&
      currLocation?.pathname !== "/send-reminder"
    ) {
      setProfileExitPopup(true);
      if (userDetails?.id) {
        getNotificationCount({ params: { userId: userDetails?.id } })
          .then((res) => dispatch(setNotificationCount(res?.data?.data || 0)))
          .catch((err) => {});
      }
    }
  }, [userDetails]);

  const contentType = {
    image: "/assets/img/modal/mood-icon.svg",
    heading: t.model_messages.break_ice,
    subHeading: t.model_messages.earn_five_comic_coins,
    alertDescription: t.model_messages.want_to_exit,
    ctaYes: t.model_messages.yes,
    ctaNo: t.model_messages.no,
    id: 21,
  };

  const notExitPopup = () => {
    setProfileExitPopup(false);
    navigate("/my_profile");
  };

  return (
    <>
      {profileExitPopup && (
        <SuccessModel
          show={profileExitPopup}
          {...contentType}
          onClose={() => setProfileExitPopup(false)}
          notExitPopup={notExitPopup}
        />
      )}
    </>
  );
};

export default ProfileExitPopup;
