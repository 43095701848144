import React, { useState } from "react";
import { Nav, NavDropdown, Image } from "react-bootstrap";
import { setRedirectUrl } from "../../store/slices/redirection-slice";
import { useDispatch } from "react-redux";
import useAuth from "../../utils/hooks/use-auth";
import { CounterFloodLight, MetaPixel, TTDPixel } from "../../utils/helpers";

const NavLinks = (props) => {
  const { isLoggedIn } = useAuth();
  const dispatch = useDispatch();
  const [defaultDropdownOpen, setDefaultDropdownOpen] = useState(true); // State to manage default dropdown open
  const { data, navigateTo } = props;

  if (!data || !Array.isArray(data)) {
    console.error("Invalid or missing data array.");
    return null;
  }

  const navigationUrl = (url, navigationState) => {
    dispatch(setRedirectUrl(url));
    if (isLoggedIn || url === "/artist" || url === "contact-us" || url==="/contest" || url==="terms_conditions" || url==="faq" || url==="privacy_policy" ) {
      navigateTo(url, navigationState);
    } else {
      navigateTo("/login");
    }
  };

  const metaPixelFn = (title) => {
    if (title === "Home") {
      MetaPixel("HomePage");
    }
    // else if(title === "Login"){
    //   CounterFloodLight("DC-12665261/sprit0/sprit01a+unique")
    // }
    else if (title === "Contest") {
      MetaPixel("Contest");
      CounterFloodLight("DC-12665261/sprit0/sprit01e+unique");
    } else if (title === "Pick your mood") {
      MetaPixel("PickYourMood");
    } else if (title === "PJ Challenge") {
      MetaPixel("ComicKaun?");
      CounterFloodLight("DC-12665261/sprit0/sprit01h+unique");
    } else if (title === "Joke Box") {
      CounterFloodLight("DC-12665261/sprit0/sprit01j+unique");
      MetaPixel("JokeBox");
      TTDPixel("ehis9x4");
    } else if (title === "Comedians' Spotlight") {
      MetaPixel("ComedianSpotlight");
    } else if (title === "Refer A Friend") {
      CounterFloodLight("DC-12665261/sprit0/sprit01m+unique");
      MetaPixel("ReferAFreind");
      TTDPixel("5e8js6i");
    } else if (title === "HALL-OF-LAME 🤪") {
      CounterFloodLight("DC-12665261/sprit0/sprit01k+unique");
      MetaPixel("HallOfFame");
      TTDPixel("cfnr6co");
    } else if (title === "Have an Invite Code?") {
      CounterFloodLight("DC-12665261/sprit0/sprit01n+unique");
      MetaPixel("HaveACode");
    } else if (title === "Contact Us") {
      MetaPixel("ContactUs");
      CounterFloodLight("DC-12665261/sprit0/sprit01p+unique");
    } else if (title === "FAQs") {
      CounterFloodLight("DC-12665261/sprit0/sprit01q+unique");
      MetaPixel("FAQs");
    } else if (title === "Terms & Conditions") {
      CounterFloodLight("DC-12665261/sprit0/sprit01r+unique");
      MetaPixel("TnCs");
    } else if (title === "Privacy Policy") {
      CounterFloodLight("DC-12665261/sprit0/sprit01s+unique");
      MetaPixel("PrivacyPolicy");
    }
  };

  return (
    <>
      <Nav className="me-auto mt-5" as={"ul"}>
        {data.map((el, i) =>
          el && !el.subMenu ? (
            <Nav.Item as="li" key={i}>
              <Nav.Link
                onClick={() => {
                  metaPixelFn(el?.title);
                  navigationUrl(el?.url, el?.state);
                }}
              >
                <Image className="icon" src={el.image} alt={el.title} />
                <span>{el.title}</span>
              </Nav.Link>
            </Nav.Item>
          ) : (
            <NavDropdown
              as="li"
              key={i}
              title={
                <>
                  <Image className="icon" src={el.image} alt={el.title} />
                  <span>{el.title}</span>
                </>
              }
              show={defaultDropdownOpen}
              onClick={() => setDefaultDropdownOpen(!defaultDropdownOpen)}
            >
              {el.subMenu?.map((item, j) => {
                return item?.buttonComponent ? (
                  <NavDropdown.Item key={j}>
                    {item.buttonComponent}
                  </NavDropdown.Item>
                ) : (
                  <NavDropdown.Item
                    key={j}
                    onClick={() => {
                      metaPixelFn(item?.title);
                      navigationUrl(item?.url, item?.state);
                    }}
                  >
                    <span dangerouslySetInnerHTML={{ __html: item.title }} />
                  </NavDropdown.Item>
                );
              })}
            </NavDropdown>
          )
        )}
      </Nav>
    </>
  );
};

export default NavLinks;
