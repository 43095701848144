import React from "react";
import { Link } from "react-router-dom"; // Assuming you're using React Router
import useTranslation from "../utils/hooks/use-translation";
import { ClickButtonCDP } from "../utils/cdp/cdpHelper";
import { CounterFloodLight, MetaPixel } from "../utils/helpers";
const FollowSprite = () => {
  const { t } = useTranslation();

  return (
    <div className="">
      <h2 className="text-center text-light">
        {`${t.common.follow} Sprite`}
        <sup>&reg;</sup>
      </h2>
      <ul className="list-social d-flex justify-content-center">
        <li className="list-item">
          <Link
            to={"https://www.facebook.com/spritein/"}
            target={"_blank"}
            className="list-link"
            onClick={() => {
              MetaPixel("Facebook");
              CounterFloodLight("DC-12665261/sprit0/sprit01u+unique");
              ClickButtonCDP("Follow_JIAB_FB");
            }}
          >
            <span className="icon icon-FB-01"></span>
          </Link>
        </li>
        <li className="list-item">
          <Link
            to={"https://www.instagram.com/sprite_india/"}
            target={"_blank"}
            className="list-link"
            onClick={() => {
              CounterFloodLight("DC-12665261/sprit0/sprit01v+unique");
              MetaPixel("Instagram");
              ClickButtonCDP("Follow_JIAB_Insta");
            }}
          >
            <span className="icon icon-insta-01"></span>
          </Link>
        </li>
        {/* <li className="list-item">
          <Link
            to={"https://x.com/sprite_india?t=Eb0BsU1Yy_JKW0uZWgKQhw&s=09"}
            className="list-link"
            target={"_blank"}
            onClick={() => ClickButtonCDP("Follow_JIAB_Twitter")}
          >
            <span className="icon icon-twitter-01"></span>
          </Link>
        </li> */}
        <li className="list-item">
          <Link
            to={"https://youtube.com/@Sprite?si=9SMfN0zKUk7Mxteb"}
            className="list-link"
            target={"_blank"}
            onClick={() => {
              MetaPixel("YouTube");
              CounterFloodLight("DC-12665261/sprit0/sprit01w+unique");
              ClickButtonCDP("Follow_JIAB_YT");
            }}
          >
            <span className="icon icon-Youtube-01"></span>
          </Link>
        </li>
        <li className="list-item">
          <Link
            className="list-link"
            onClick={(e) => {
              MetaPixel("WhatsApp");
              e.preventDefault();
              ClickButtonCDP("Follow_JIAB_WA");
              CounterFloodLight("DC-12665261/sprit0/sprit01x+unique");
              window.open(
                `https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_WP_NUM}&text=Hi,+I+am+here+for+Sprite+Joke-In-A-Bottle!+By+pressing+Send+on+this,+I+give+my+consent+to+sharing+my+mobile+number+and+display+name+with+Sprite.`,
                "_blank" // Opens the link in a new tab
              );
            }}
          >
            <span className="icon icon-whatsapp-01"></span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default FollowSprite;
