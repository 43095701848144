import React, { useState } from "react";
import useTranslation from "../../utils/hooks/use-translation";

const Tabs = ({ tabLabels, wrapperClass, setActiveTab, activeTab }) => {
  const { t } = useTranslation();

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const translateLabel = (label) => {
    if (label === "Latest") {
      return t.common.latest;
    } else if (label === "Trending") {
      return t.common.trending;
    }
    return label;
  };
  
  return (
    <div className={`tabs container container-fluid ${wrapperClass}`}>
      <div className="tabs-wrapper">
        {tabLabels.map((label, index) => (
          <Tab
            key={index}
            tabNumber={index + 1}
            isActive={activeTab === index + 1}
            onClick={() => handleTabClick(index + 1)}
          >
           <h3>{translateLabel(label)}</h3>
            
          </Tab>
        ))}
      </div>
    </div>
  );
};

const Tab = ({ tabNumber, isActive, onClick, children }) => {
  return (
    <a
      href="#"
      className={`tab ${isActive ? "active" : ""}`}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      {children}
    </a>
  );
};

export default Tabs;
