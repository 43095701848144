import { combineReducers } from "redux";

import auth from "./auth-slice";
import common from "./common-slice";
import popUp from "./pop-up";
import redirectSlice from "./redirection-slice";
import jokedataSlice from "./jokedata-slice";
import inviteCode from './invite-code';
import reportJoke from './report-joke';
import supriseMe from './suprise-me';
import homePopup from "./home-popup";
export default combineReducers({
  auth,
  common,
  popUp,
  redirectSlice,
  jokedataSlice,
  inviteCode,
  reportJoke,
  supriseMe,
  homePopup
});
