import { AxiosInstance } from "../utils/axios-helper/axios-helper";
import { API_END_POINT } from "../utils/constants/api-end-points";
import { version } from "../config";


export const ugcUploadService = async (data) => {
  return await AxiosInstance.post(API_END_POINT.ugcUpload, data);
};

export const getUGCContent = async (data) => {
  return await AxiosInstance.post(API_END_POINT.ugcGetContent, data);
};

export const sendReacts = async (data) => {
  return await AxiosInstance.post(`${version}${API_END_POINT.sendReactPoints}`, data);
};

export const sendJokeReportCall = async (data) => {
  return await AxiosInstance.post(API_END_POINT.sendJokeReport, data);
};

export const getGenreByLangId = async (data) => {
  return await AxiosInstance.get(`${API_END_POINT.getGenres}?LanguageId=${data?.lang}`);
};
