import { Button } from "react-bootstrap";
import useTranslation from "../../utils/hooks/use-translation";
import { useState, useEffect } from "react";
import { shareFile } from "../../utils/common";
import { Link, useNavigate } from "react-router-dom";
import { ClickButtonCDP } from "../../utils/cdp/cdpHelper";

export const ShareButton = ({
  imgUrl,
  title,
  description,
  url,
  isLink = false,
  type=""
}) => {
  const { t } = useTranslation();
  const [sharefile, setShareFile] = useState(null);
  const fetchShareableImage = async () => {
    const response = await fetch(imgUrl);
    const blob = await response.blob();
    const file = new File([blob], "file.png", {
      type: blob.type,
    });
    setShareFile(file);
    return file;
  };

  useEffect(() => {
    if (imgUrl) {
      fetchShareableImage();
    }
  }, [imgUrl]);

  const handleShareWithFriends = () => {
    ClickButtonCDP(`Share_${type}`)
    shareFile({
      file: sharefile,
      title,
      text: description,
      url,
    });

    // dispatch(
    //   displayShareWithFriends({
    //     imgUrl: isPopReward
    //       ? "/assets/img/voucher-10.png"
    //       : `${imagebaseURL}${cardImage}`,
    //     description,
    //     title,
    //     show: true,
    //   })
    // );
  };

  return (
    <>
      {isLink ? (
        <>
          <Link
            to={"#"}
            className="text-primary font-weight-bold text-underline"
            onClick={handleShareWithFriends}
          >
            {t.common.share_with_friends}
          </Link>
        </>
      ) : (
        <div className="button-wrapper">
          <Button
            variant="warning "
            className="btn-card"
            onClick={handleShareWithFriends}
          >
            {t.common.share_with_friends}
          </Button>
        </div>
      )}
    </>
  );
};
