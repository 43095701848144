import { useContext } from "react";
import { PopupContext } from "../../components/common/context/popup-context";

function usePopup() {
  const {
    openUniqueCodePopup,
    openReferPopup,
    openReferFriendPopup,
    closeUniqueCodePopup,
  } = useContext(PopupContext);

  return {
    openUniqueCodePopup,
    openReferPopup,
    openReferFriendPopup,
    closeUniqueCodePopup,
  };
}

export default usePopup;
