import React, { useEffect } from "react";
import DOMPurify from "dompurify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import { useRef } from "react";
import useTranslation from "../../../utils/hooks/use-translation";

const UploadForm = ({
  activeTab,
  activeTabFormat,
  uploadedFiles,
  textValue,
  setTextValue,
  setUploadedFiles,
  isFileUploaded,
  setIsFileUploaded,
}) => {
  const { t } = useTranslation();
  const initialValues = {
    joke: "",
    title: "",
    tags: "",
    agreeTerms: false,
    file: "",
  };
  const fileInputRef = useRef();
  useEffect(() => {
    formik.resetForm();
  }, [activeTab]);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const validationSchema = Yup.object().shape({
    joke:
      activeTab === `${t.common.text}`
        ? Yup.string().required("Add your joke is required")
        : Yup.string(),
    title:
      activeTab === `${t.common.text}`
        ? Yup.string().required("Title is required")
        : Yup.string(),
    tags:
      activeTab === `${t.common.text}`
        ? Yup.string().required("Tags are required")
        : Yup.string(),
    agreeTerms:
      activeTab === `${t.common.text}`
        ? Yup.boolean().oneOf([true], "You must agree to terms and conditions")
        : Yup.boolean(),
    file:
      activeTab !== `${t.common.text}`
        ? Yup.mixed()
            .nullable()
            .test(
              "fileValidation",
              "File format or size is invalid",
              function (file) {
                if (!file) return true; // Allow empty file

                let allowedFormats;
                let allowedSize;
                if (activeTab === `${t.common.image}`) {
                  allowedFormats = ["image/jpeg", "image/png", "image/jpg"];
                  allowedSize = 1 * 1024 * 1024;
                } else if (activeTab === `${t.common.audio}`) {
                  allowedFormats = ["audio/mp3", "audio/wav"];
                  allowedSize = 50 * 1024 * 1024;
                } else if (activeTab === `${t.common.video}`) {
                  allowedFormats = ["video/mp4"];
                  allowedSize = 50 * 1024 * 1024;
                }

                const isValidFormat = allowedFormats?.includes(file?.type);
                const isValidSize = file?.size <= allowedSize;

                if (!isValidFormat) {
                  return false;
                }

                if (!isValidSize) {
                  return this.createError({
                    path: this.path,
                    message: `File size exceeds the maximum limit of ${
                      activeTab === `${t.common.image}` ? "1 MB" : "50 MB"
                    }. Please upload a smaller file.`,
                  });
                }

                return true;
              }
            )
        : Yup.mixed().nullable(),
  });

  const onSubmit = (values) => {
    // Handle form submission logic here
  };

  const getFileAcceptType = (activeTab, t) => {
    if (activeTab === `${t.common.image}`) {
      return "image/*";
    } else if (activeTab === `${t.common.audio}`) {
      return ".mp3,.wav";
    } else if (activeTab === `${t.common.video}`) {
      return "video/mp4";
    }
    return "";
  };

  const handleText = (event, tab) => {
    const sanitizedText = DOMPurify.sanitize(event.target.value, {
      ALLOWED_TAGS: [],
    });

    setTextValue(sanitizedText);
    const blob = new Blob([sanitizedText], { type: "text/plain" });
    const fileName = "text_file.txt";
    const file = new File([blob], fileName, { type: "text/plain" });

    setUploadedFiles({
      format: "Text",
      file: file,
    });
    setIsFileUploaded(false);
  };

  const handleFileChange = (event, activeTabFormat) => {
    const tab = activeTabFormat;
    const file = event?.target?.files?.[0];
    let allowedFormats;
    let allowedSize;
    let errorMessage = "";

    if (activeTabFormat === `${t.common.image}`) {
      allowedFormats = ["image/jpeg", "image/png", "image/jpg"];
      allowedSize = 1 * 1024 * 1024; // 1 MB
    } else if (activeTabFormat === `${t.common.audio}`) {
      allowedFormats = ["audio/mp3", "audio/wav", "audio/mpeg"];
      allowedSize = 50 * 1024 * 1024; // 50 MB
    } else if (activeTabFormat === `${t.common.video}`) {
      allowedFormats = ["video/mp4"];
      allowedSize = 50 * 1024 * 1024; // 50 MB
    }

    const isValidFormat = allowedFormats?.includes(file.type);
    const isValidSize = file.size <= allowedSize;

    if (!isValidFormat) {
      errorMessage = `File format not accepted. Please upload in ${
        activeTabFormat === `${t.common.image}`
          ? ".jpg, .jpeg, or .png"
          : activeTabFormat === `${t.common.audio}`
          ? ".mp3, .wav"
          : ".mp4"
      } formats.`;
    }

    if (!isValidSize) {
      errorMessage = `File size exceeds the maximum limit of ${
        activeTabFormat === `${t.common.image}` ? "1 MB" : "50 MB"
      }. Please upload a smaller file.`;
    }

    if (errorMessage) {
      // Display the error message (e.g., using a toast or an alert)
      setIsFileUploaded(false);
      setUploadedFiles({
        format: "",
        file: null,
      });
      alert(errorMessage);
      // Clear the file input
      event.target.value = "";
    } else {
      // Proceed with the valid file
      formik.setFieldValue("file", file);
      formik.setFieldTouched("file", true);
      setUploadedFiles({
        format: tab,
        file: file,
      });
      setIsFileUploaded(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      joke: "",
      title: "",
      tags: "",
      agreeTerms: false,
      file: null,
    },
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className="my-4 form-file ">
      <div>
        {activeTab === `${t.common.text}` && ( // Check if active tab is "text"
          <div className="mb-3 form-file-container">
            <label htmlFor="joke" className="form-label">
              {t.common.add_joke}
            </label>
            <textarea
              id="joke"
              name="joke"
              placeholder={t.common.type_here}
              className={`form-control ugc-text ${
                formik.touched.joke && formik.errors.joke ? "is-invalid" : ""
              }`}
              onChange={(e) => {
                formik.handleChange(e);
                handleText(e, activeTabFormat);
              }}
              onBlur={formik.handleBlur}
              value={textValue ? textValue : formik.values.joke}
              maxLength={200}
            />
            {formik.touched.joke && formik.errors.joke ? (
              <div className="invalid-feedback">{formik.errors.joke}</div>
            ) : null}
            <div className="text-muted">{t.common.max_limit_two_hundred}</div>
          </div>
        )}

        {activeTab !== `${t.common.text}` && (
          <div className="my-5 form-file-container">
            {isFileUploaded ? (
              <>
                <Button className="btn-block btn-format btn-light border">
                  {/* <img
                  className="bottle d-block"
                  src="/assets/img/animation/bottle-sprite-t.gif"
                  alt="Bottle Animation"
                /> */}
                  <div
                    onClick={() => {
                      setUploadedFiles({ format: "", file: null });
                      setIsFileUploaded(false);
                    }}
                  >
                    <button
                      type="button"
                      style={{ marginLeft: "80px" }}
                      className="close ml-5"
                      alt="close icon"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <img
                    src="/assets/img/modal/right-icon.svg"
                    alt="Icon"
                    className="modal-icon mb-md-4 mb-2"
                  />
                  <h4>
                    File Name :{" "}
                    <p className="text-muted file-name">
                      {uploadedFiles?.file?.name}
                    </p>
                  </h4>
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="button"
                  className="btn-block btn-format btn-light border"
                  onClick={handleUploadClick}
                >
                  <img
                    src="./assets/img/icons/upload-file.svg"
                    className="mb-md-4 mb-2"
                    alt="Upload Icon"
                  />
                  {`${t.common.upload_your} ${activeTab}`}

                  <div>
                    <span className="text-muted small">
                      {t.common.accepted_formats}
                      {activeTab === `${t.common.image}`
                        ? " .jpg, .jpeg & .png"
                        : activeTab === `${t.common.audio}`
                        ? " : .MP3, .WAV"
                        : activeTab === `${t.common.video}`
                        ? " : .MP4"
                        : ""}
                      <br />
                      {activeTab === `${t.common.image}` && (
                        <span className="text-muted small">
                          {t.model_messages.preferred_image_dimension}
                          {activeTab === `${t.common.image}` && " Square"}
                        </span>
                      )}
                    </span>
                  </div>
                </Button>
                <input
                  type="file"
                  name="file"
                  accept={getFileAcceptType(activeTab, t)}
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={(e) => {
                    const selectedFile = e.target.files[0];
                    handleFileChange(e, activeTabFormat);
                    formik.setFieldValue("file", selectedFile);
                    formik.setFieldTouched("file", true);
                  }}
                />
              </>
            )}
            {/* {formik.errors.file ? (
              <div className="text-danger small my-3">{formik.errors.file}</div>
            ) : null} */}
          </div>
        )}
      </div>
    </form>
  );
};

export default UploadForm;
