import React from 'react';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import useTranslation from '../../utils/hooks/use-translation';

const SectionHeader = (props) => {
  const { t } = useTranslation();
  const {
    title,
    subTitle,
    linkText,
    linkUrl = '',
    link,
    containerClass,
    headerClass,
    titleClass,
    subTitleClass,
    variant,
    btnClass,
    handleFilter,
    filter,
    as,
    isHeader,
    action = '',
    sectionId,
    modalTitle,
    modalDescription,
  } = props;

  const isHeaderAvailble = isHeader !== undefined ? isHeader : true;
  return (
    <>
      {isHeaderAvailble && (
        <header className={`s-header mt-4${headerClass || ""}`}>
          <div
            className={`container-fluid ${containerClass || ""} ${sectionId}`}
          >
            {title && (
              <h3 className={`s-title ${titleClass || ""}`}>{title}</h3>
            )}
              {modalTitle && (
              <h3 className={`modal-heading-title px-3 mb-2 ${titleClass || ""}`}>{modalTitle}</h3>
            )}
             {modalDescription && (
              <p className={`modal-subcontent px-4 ${titleClass || ""}`}>{modalDescription}</p>
            )}
            {subTitle && (
              <h4
                className={`s-sub-title ${subTitleClass || ""}`}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(subTitle),
                }}
              ></h4>
            )}
            {linkText &&
              (action ? (
                <Link
                  variant={variant}
                  as={as}
                  className={`btn-link ${btnClass || ""}`}
                  to={linkUrl}
                  onClick={() => action()}
                >
                  <span>{linkText}</span>
                </Link>
              ) : (
                <Link
                  variant={variant}
                  as={as}
                  className={`btn-link ${btnClass || ""}`}
                  to={link}
                >
                  <span>{linkText}</span>
                  <span className="icon icon-Right icon-Right-arrow-01"></span>
                </Link>
              ))}

            {handleFilter && (
              <select
                id="filter-dropdown"
                className="select mt-1 mt-md-7"
                value={filter}
                onChange={(e) => handleFilter(e.target.value)}
              >
                <option value="all">{t.common.all}</option>
                <option value="most_popular">{t.common.most_popular}</option>
                <option value="most_viewed">{t.common.most_viewed}</option>
                <option value="most_liked">{t.common.most_liked}</option>
              </select>
            )}
          </div>
        </header>
      )}
    </>
  );
};
export default SectionHeader;
