// TourGuide.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Tour from "reactour";
import { useViewport } from "../../utils/context/viewport-context";
import useTranslation from "../../utils/hooks/use-translation";
import { showTourGuide } from "../../store/slices/common-slice";
import { useDispatch } from "react-redux";
import { displayWelcome, displaySuprise } from "../../store/slices/suprise-me";

const TourGuide = ({ isOpen }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const { isMobile } = useViewport();
  const navigate = useNavigate();

  // const handleNextClick = () => {
  //   const steps = isMobile ? stepsM : stepsD;
  //   const nextStep = currentStep + 1;
  //   if (nextStep < steps.length) {
  //     setCurrentStep(nextStep);
  //   } else {
  //     dispatch(displayWelcome(false));
  //     onClose();
  //   }
  // };

  const handleSkipClick = () => {
    onClose();
  };

  const commonStepContent = (text, selector, heading, imageUrl) => (
    <div className={selector}>
      {imageUrl && (
        <img
          className="mb-2"
          alt={heading ? heading : "tour image"}
          src={imageUrl}
        />
      )}
      {heading && <h3 className="tour-heading">{heading}</h3>}
      <p className="tour-text text-left">{text}</p>

      {/* <div className="tour-btn-container">
      <div className="tour-btn-link" onClick={handleNextClick}>
        Next
      </div>
      <div className="tour-btn-link" onClick={handleSkipClick}>
        Skip
      </div>
    </div> */}
    </div>
  );

  const stepsD = [
    {
      selector: ".tour-explore",
      content: commonStepContent(
        t.tour_guide.explore,
        "tour-explore ",
        t.tour_guide.explore_head
      ),
    },
    {
      selector: ".tour-contest",
      content: commonStepContent(
        t.tour_guide.constest_desktop,
        "tour-contest",
        t.common.contest
      ),
    },
    {
      selector: ".change-lang-d",
      content: commonStepContent(
        t.tour_guide.change_language,
        "change-lang-d",
        "",
        "/assets/img/tour-guide/tour-lang.svg"
      ),
    },
    {
      selector: ".avatar_icon",
      content: commonStepContent(
        t.tour_guide.your_own_fun_space,
        "avatar_icon",
        "",
        "/assets/img/tour-guide/tour-avatar.svg"
      ),
    },
    {
      selector: ".tour-pick",
      content: commonStepContent(
        t.tour_guide.pick_mood_subheading,
        "tour-pick",
        t.home.pick_your_mood
      ),
    },
    {
      selector: ".tour-comedian",
      content: commonStepContent(
        t.home.tap_to_see_subheading,
        "tour-comedian",
        t.home.jokers_bottle
      ),
    },
    {
      selector: ".tour-tab",
      content: commonStepContent(
        t.tour_guide.bottle_here_subheading,
        "tour-tab",
        t.home.joke_box
      ),
    },
    // {
    //   selector: ".tour-comedian",
    //   content: commonStepContent(
    //     t.home.tap_to_see_subheading,
    //     "tour-comedian",
    //     "Comedians Spotlight"
    //   ),
    // },
    // {
    //   selector: ".coke_studio_treasures",
    //   content: commonStepContent(t.tour_guide.coke_treasures),
    //   // id: generateUniqueIds()[1],
    // },
    // {
    //   selector: ".giggle_central",
    //   content: commonStepContent(t.tour_guide.coke_heads),
    //   // id: generateUniqueIds()[3],
    // },
    // {
    //   selector: ".latest_tunes",
    //   content: commonStepContent(t.tour_guide.latest_tunes),
    // },
    // {
    //   selector: ".behind_the_scenes",
    //   content: commonStepContent(t.tour_guide.behind_the_scene),
    // },
    // {
    //   selector: ".seasonal_hits",
    //   content: commonStepContent(t.tour_guide.seasonal_hits),
    // },
  ];

  const stepsM = [
    {
      selector: ".navbar-toggler",
      content: commonStepContent(
        t.tour_guide.sidebar,
        "navbar-toggler",
        "",
        "/assets/img/tour-guide/tour-nav.svg"
      ),
    },
    // {
    //   selector: ".notification-m",
    //   content: commonStepContent(
    //     t.tour_guide.notification,
    //     "notification",
    //     "",
    //     "/assets/img/tour-guide/tour-notification.svg"
    //   ),
    // },
    {
      selector: ".change_language",
      content: commonStepContent(
        t.tour_guide.change_language,
        "change_language",
        "",
        "/assets/img/tour-guide/tour-lang.svg"
      ),
    },
    {
      selector: ".tour-pick",
      content: commonStepContent(
        t.tour_guide.pick_mood_subheading,
        "tour-pick",
        t.home.pick_your_mood,
        ""
      ),
    },
    {
      selector: ".tour-comedian",
      content: commonStepContent(
        t.home.tap_to_see_subheading,
        "tour-comedian",
        t.home.jokers_bottle,
        ""
      ),
    },
    {
      selector: ".tour-tab",
      content: commonStepContent(
        t.tour_guide.bottle_here_subheading,
        "tour-tab",
        t.home.joke_box,
        ""
      ),
    },
    {
      selector: ".home",
      content: commonStepContent(
        t.tour_guide.home,
        "home",
        "",
        "/assets/img/tour-guide/tour-home.svg"
      ),
    },
    {
      selector: ".contest",
      content: commonStepContent(
        t.tour_guide.contest,
        "contest",
        "",
        "/assets/img/tour-guide/tour-contest.svg"
      ),
    },
    // {
    //   selector: ".unique-code",
    //   content: commonStepContent(
    //     t.tour_guide.enter_unique_code_home,
    //     "unique-code",
    //     "",
    //     "/assets/img/tour-guide/tour-unique-code.svg"
    //   ),
    // },
    {
      selector: ".wallet",
      content: commonStepContent(
        t.tour_guide.wallet,
        "wallet",
        "",
        "/assets/img/tour-guide/tour-comic.svg"
      ),
    },
    {
      selector: ".profile-tour",
      content: commonStepContent(
        t.tour_guide.profile,
        "profile-tour",
        "",
        "/assets/img/tour-guide/tour-profile.svg"
      ),
    },
  ];

  const steps = isMobile ? stepsM : stepsD;
  useEffect(() => {
    let timeoutId = null;
    const intervalId = setInterval(() => {
      setCurrentStep((prevStep) => {
        const nextStep =
          prevStep < steps.length - 1 ? prevStep + 1 : handleSkipClick();

        if (nextStep === steps.length - 1) {
          // If the next step is the last step and on the home page, close the tour
          timeoutId = setTimeout(() => {
            dispatch(displayWelcome(false));
            onClose();
            dispatch(displaySuprise(true));

            // navigate("/contest")
          }, 2000);
        }
        return nextStep;
      });
    }, 2000); // Adjust the duration (in milliseconds) as needed

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [steps.length]);

  const onClose = () => {
    dispatch(showTourGuide({ showTourGuide: false }));
  };

  return (
    <Tour
      steps={steps}
      isOpen={isOpen}
      onRequestClose={onClose}
      goToStep={currentStep}
      showButtons={false}
      showCloseButton={false}
      closeWithMask={false}
      // rounded={3}
      // accentColor="#3498db"
      maskSpace={10}
      showNumber={false}
    ></Tour>
  );
};

export default TourGuide;
